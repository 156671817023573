import { DateRange } from "src/app/shared/models/dateRange.model"
import { TimeHelper } from "src/app/shared/helpers/timeHelper"

export class YearlyStudySettingsViewModel {

    studyId: number
    startDate: Date
    endDate: Date
    holidayWeek1: DateRange
    holidayWeek2: DateRange
    holidayWeek3: DateRange
    holidayWeek4: DateRange
    fellowShipWeek1: DateRange
    fellowShipWeek2: DateRange
    fellowShipWeek3: DateRange

    static toDo(settings: YearlyStudySettingsViewModel) {
        return {
            studyId: settings.studyId,
            startDate: this.getUtcString(settings.startDate),
            endDate: this.getUtcString(settings.endDate),
            holidayWeek1: {
                beginDate: this.getUtcString(settings.holidayWeek1.beginDate),
                endDate: this.getUtcString(settings.holidayWeek1.endDate),
            },
            holidayWeek2: {
                beginDate: this.getUtcString(settings.holidayWeek2.beginDate),
                endDate: this.getUtcString(settings.holidayWeek2.endDate),
            },
            holidayWeek3: {
                beginDate: this.getUtcString(settings.holidayWeek3.beginDate),
                endDate: this.getUtcString(settings.holidayWeek3.endDate),
            },
            holidayWeek4: {
                beginDate: this.getUtcString(settings.holidayWeek4.beginDate),
                endDate: this.getUtcString(settings.holidayWeek4.endDate),
            },
            fellowShipWeek1: {
                beginDate: this.getUtcString(settings.fellowShipWeek1.beginDate),
                endDate: this.getUtcString(settings.fellowShipWeek1.endDate),
            },
            fellowShipWeek2: {
                beginDate: this.getUtcString(settings.fellowShipWeek2.beginDate),
                endDate: this.getUtcString(settings.fellowShipWeek2.endDate),
            },
            fellowShipWeek3: {
                beginDate: this.getUtcString(settings.fellowShipWeek3.beginDate),
                endDate: this.getUtcString(settings.fellowShipWeek3.endDate),
            }
        }
    }

    private static getUtcString(date: Date | string) {
        return date instanceof Date ? TimeHelper.getUTCDateTime(date) : TimeHelper.getUTCDateTime(new Date(date));
    }
}
import { AuthService } from './../services/auth.service';


import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';


@Injectable()

export class AuthGuardService  {
  constructor(
    private _sessionService: SessionService,
    private authService: AuthService
    ) {
}

canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>((observer)=> {
        const isAuthenticated = this._sessionService.isAuthenticated();

        if (!isAuthenticated) {
            this.authService.b2cLogin();
        }

        observer.next(isAuthenticated);
        observer.complete();
    });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { DiscussionMeeting } from '../../models/discussionMeeting.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TimeHelper } from '../../helpers/timeHelper';

@Component({
  selector: 'app-request-leaders-meeting-modal',
  templateUrl: './request-leaders-meeting-modal.component.html',
  styleUrls: ['./request-leaders-meeting-modal.component.css']
})
export class RequestLeadersMeetingModalComponent implements OnInit {
  group: DiscussionMeeting;
  groupTimeZoneCode: string;
  selectedTimeZone: string;

  constructor(
    public dialogRef: MatDialogRef<RequestLeadersMeetingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.group = this.data.selectedMeeting;
    this.selectedTimeZone = this.data.selectedTimeZone;
    this.groupTimeZoneCode = TimeHelper.getZone(this.group.meetingTimeZone);
  }

  select(): void {
    this.dialogRef.close({ action: 'proceed' });
  }

  cancel(): void {
    this.dialogRef.close({ action: 'cancel' });
  }
}

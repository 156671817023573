
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Lesson } from '../../lessons/common/models/lesson.model';
import { Observable } from 'rxjs';
import { LessonSummary } from '../../lessons/common/models/lessonSummary.model';
import { LessonViewModel } from '../../lessons/common/models/lessonViewMode.model';
import { YearlyStudySettingsViewModel } from 'src/app/admin/common/models/YearlyStudySettingsViewModel.model';
import { NamedId } from '../models/namedId.model';
import { SaveLessonMaterial } from 'src/app/admin/common/models/saveLessonMaterial.model';
import { ConfigService } from './config.service';

@Injectable()
export class LessonService {

    private _lessonEndpoint;
    constructor(
      private _http: HttpClient,
      private configService:ConfigService
    ) {
      configService.configuration$.subscribe(config => {
        this._lessonEndpoint = `${config.apiEndpoint}lessons`;
      });
    }

    getLesson(id: number): Observable<LessonViewModel> {
        return this._http.get<LessonViewModel>(`${this._lessonEndpoint}/${id}`);
    }

    getLessonDetails(id: number) {
        return this._http.get<Lesson>(`${this._lessonEndpoint}/${id}/details`);
    }

    getCurrentWeekLesson(): Observable<LessonViewModel> {
        return this._http.get<LessonViewModel>(`${this._lessonEndpoint}/_currentweek`);//temporarily hardcoded to 1 (studyYear)
    }

    getLessons(includeHolidays: boolean = false): Observable<LessonSummary[]> {
        return this._http.get<LessonSummary[]>(`${this._lessonEndpoint}/summaries?includeHolidays=${includeHolidays}`);
    }

    getCurrentStudySettings(): Observable<YearlyStudySettingsViewModel> {
        return this._http.get<YearlyStudySettingsViewModel>(`${this._lessonEndpoint}/study_settings`)
    }

    updateStudySettings(model: YearlyStudySettingsViewModel) {
        return this._http.post(`${this._lessonEndpoint}/create_study`, YearlyStudySettingsViewModel.toDo(model));
    }
    
    createEvents(model: YearlyStudySettingsViewModel) {
        return this._http.post(`${this._lessonEndpoint}/create_study_events`, YearlyStudySettingsViewModel.toDo(model));
    }

    getStudies(): Observable<NamedId<number>[]> {
        return this._http.get<NamedId<number>[]>(`${this._lessonEndpoint}/studies`);
    }

    createLessonMaterial(model: SaveLessonMaterial): Observable<number> {
        return this._http.post<number>(`${this._lessonEndpoint}/lesson-material`, model);
    }

    deleteLessonMaterial(id) {
        return this._http.delete(`${this._lessonEndpoint}/lesson-material/${id}`);
    }

    loadLessonMaterialFile(id: number): Observable<Blob> {
      return this._http.get(`${this._lessonEndpoint}/lesson-material/loadfile/${id}`,{responseType:'blob'});
    }
}

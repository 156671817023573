import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { RoutingService } from '../services/routing.service';
import { RolePermissionService } from '../services/rolePermission.service';

@Injectable()
export class AdminGuardService  {

    constructor(
        private _rolePermission: RolePermissionService,
        private _sessionService: SessionService,
        private _routingService: RoutingService,
        private authService: AuthService) {

    }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        const isAuthenticated = this._sessionService.isAuthenticated();
        if (!isAuthenticated) {
          this.authService.b2cLogin();
        }
        const isAdmin = this._rolePermission.isHQAdmin();
        if (!isAdmin) {
            this._routingService.gotoHome();
        }

        return isAuthenticated && isAdmin;
    }
}

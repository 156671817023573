
import { Injectable } from '@angular/core';
import { AppSettings } from '../models/appSettings.model';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConfigService } from './config.service';
import { catchError, map } from 'rxjs/operators';
import { SystemSetting } from '../models/system-setting.model';

@Injectable()
export class AppSettingsService {
  public _appSettings: AppSettings;
  private appSettingsSubject: ReplaySubject<AppSettings> = new ReplaySubject<AppSettings>();
  public appSettings$ = this.appSettingsSubject.asObservable();
  private _appSettingsUrl;
  private _http: HttpClient;


  constructor(
    handler: HttpBackend,
    private configService: ConfigService) {

    configService.configuration$.subscribe(config => {
      this._appSettingsUrl = `${config.apiEndpoint}appsettings`;
    });
    this._http = new HttpClient(handler);
  }

  public updateAppSettings(appSettings: AppSettings) {
    this._appSettings = appSettings;
    this.appSettingsSubject.next(this._appSettings);
  }
  
  loadAppSettings(): Observable<boolean> {
    return this.getAppSettings().pipe(
        map((settings => {
          this.updateAppSettings(settings)
          return true;
        })),
        catchError((error, caught) => {
          const err = <HttpErrorResponse>error;
          console.error(err);
          if(err.status === 502) {  
              window.location.href = '/maintenance.html';            
          }
          else {
              return throwError(error.message);        
          }
        })
      );    
  }

  private getAppSettings(): Observable<AppSettings> {
    return this._http.get<AppSettings>(this._appSettingsUrl);
  }

  public validateTestEnvironmentPassword(password: string): Observable<SystemSetting> {
    return this._http.post<SystemSetting>(this._appSettingsUrl + "/validate-test-environment-password/" + password, null);
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ZoomMeetingViewModel } from '../models/zoomMeetingViewModel.model';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable()
export class ZoomService {
  private _zoomEndpoint;

  constructor(
    private _http: HttpClient,
    private configService:ConfigService
  ) {
      configService.configuration$.subscribe(config => {
        this._zoomEndpoint = `${config.apiEndpoint}zoom`;
      });
  };

  getZoomUserStatus(userId?:number) : Observable<any>{
    let userStatusEndPoint =`${this._zoomEndpoint}/user-status/${userId}`;
    return this._http.get(userStatusEndPoint,{responseType: 'text'});
  };

  requestZoomLicense(): Observable<boolean> {
    return this._http.get<boolean>(`${this._zoomEndpoint}/request_zoom_license`);
  }

  startOnlineMeeting(meetingUrl:string){
    var newWindow = window;
    newWindow.open(encodeURI(meetingUrl), "_blank");
  }
}


import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { RoutingService } from '../services/routing.service';
import { RolePermissionService } from '../services/rolePermission.service';

@Injectable()
export class RegionGuardService  {

    constructor(
      private _sessionService: SessionService,
      private _rolePermissions: RolePermissionService,
      private _routingService: RoutingService,
      private _authService:AuthService) {

    }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
      const isAuthenticated = this._sessionService.isAuthenticated();

      if (!isAuthenticated) {
        this._authService.b2cLogin();
      }

      if (!isAuthenticated || !(this._rolePermissions.isRegionalDirectorOrAbove())) {
          this._routingService.gotoHome()
      }

      return isAuthenticated && this._rolePermissions.isRegionalDirectorOrAbove();
    }
}

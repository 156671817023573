import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class TranslateLessonService {

    constructor(private _translateService: TranslateService) {
    }

    translateLessonName(eventType: string, lessonNumber: number, displayName: string): string {
        let lessonName = ""; 
        
        if(eventType === 'L' || eventType === 'FL') {
            lessonName = `${this._translateService.instant('Lesson #')}`;
            lessonName = lessonName.replace(' - ', ` ${lessonNumber} `);            
        }
        else {
            lessonName = this._translateService.instant(displayName);
        }            
        return lessonName;
    }

    translateScripture(eventType: string, lessonNumber: number, scripture: string): string {
        return this._translateService.instant(scripture);                    
    }

    translateMatthewScripture(eventType: string, lessonNumber: number, scripture: string): string {
        const scriptureTitle = 'Matthew';        
        if (eventType === 'L' ) {
            if (scripture.indexOf(scriptureTitle) < 0) {
                return scripture;
            }            
            if(lessonNumber === 0) {
                return `${this._translateService.instant(scriptureTitle)} ${this._translateService.instant('Scripture: Intro')}`;
            }
            const chapter = scripture.substring(scriptureTitle.length)
            return this._translateService.instant(scriptureTitle) + chapter;            
        }
        else if(eventType === 'FL') {            
            if (scripture.indexOf(scriptureTitle) < 0) {
                return scripture;
            }
            return `${this._translateService.instant(scriptureTitle)} ${this._translateService.instant('Scripture: Final')}`;
        }
        else {
            const translationKey = 'Scripture: ' + scripture;
            const translatedValue = this._translateService.instant(translationKey);
            return translatedValue === translationKey
                ? scripture
                : translatedValue;            
        }
    }
}




export enum Position {
    OnlineMember = 100,
    OnlineGroupMember = 200,
    OnlineGroupLeader = 300,
    AssistantOnlineTrainer = 400,
    OnlineTrainer = 500,
    OnlineAreaPersonel = 600,
    OnlineRegionalDirector = 700,
    HQAdminVO = 750,
    HQAdmin = 800,
}

import { Directive, ElementRef, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[bsf-trim-input]',
  host: {"(focusout)": 'onFocusOut($event)'}
})
export class BsfTrimInputDirective {

  @Input()
  autotrim: boolean = false;

  constructor(private el: ElementRef,
    private ngControl: NgControl) {
  }
  
  onFocusOut($event: any) {
    if (this.autotrim != true){
      return; // do nothing
    }
    const initialValue = $event.target?.value;
    const trimmed = (initialValue as string)?.trim() ?? initialValue;
    if (trimmed !== initialValue){
      this.ngControl?.control?.setValue(trimmed);
    }
  }
}

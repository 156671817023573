import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-interview-comment-reminder-modal',
  templateUrl: './interview-comment-reminder-modal.component.html',
  styleUrls: ['./interview-comment-reminder-modal.component.css']
})
export class InterviewCommentReminderModalComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<InterviewCommentReminderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {

  }

  select(): void {
    this.dialogRef.close({action: 'proceed'});
  }

}

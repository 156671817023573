<div class="user-item col-12 col-md-4 col-lg-2" id="{{member.id}}" *ngIf="member">
    <div class="user-item-inner">
        <div class="user-item-front">
            <div class="user-item-click" onclick="showInfo(1)">
                <app-image-placeholder [class]="'user-item-pic'" *ngIf="!member.photo; else showphoto"
                    [genderId]="member.genderId">
                </app-image-placeholder>
                <ng-template #showphoto>
                    <app-user-image [class]="'user-item-pic'" [base64string]="member.photo"></app-user-image>
                </ng-template><br>
                <button class="dropdown-toggle user-item-pic-arrow"></button>
            </div>
            <b>{{member.fullName}}</b><br>
            <div class="user-item-time">
                <app-country-flag [country]="member.country.countryName"></app-country-flag>
                <small>
                    <app-local-clock [timeZone]="member.timeZone"></app-local-clock>
                </small>
            </div>
        </div>
        <div class="user-item-back d-flex flex-column justify-content-around" onclick="hideInfo(1)">
            <b>{{member.fullName}}</b>
            <!--<a href="#"><button class="blue-btn user-item-btn"><i class="far fa-envelope"></i> &nbsp;
                    {{'Email' | translate}}</button></a>
            <a href="#"><button class="blue-btn user-item-btn"><i class="far fa-comment-alt"></i> &nbsp;
                    {{'Chat' | translate}}</button></a>-->
            <button class="blue-btn user-item-btn" data-toggle="modal" data-target="#AboutModal"><i
                    class="far fa-user"></i> &nbsp; {{'ViewProfile' | translate}}</button>
            <button class="blue-btn user-item-btn"><i class="fas fa-book-open"></i> &nbsp; {{'GoArea' | translate}}</button>
        </div>
    </div>
</div>

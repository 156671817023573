import { Pipe, PipeTransform } from "@angular/core";
import { UtilityHelper } from "../helpers/utils";
import { SessionService } from "../services/session.service";



@Pipe({
    name: 'userposition'
})
export class UserPositionPipe implements PipeTransform {

    constructor(private _sessionService: SessionService) {

    }

    transform(string) {
        return this._sessionService.getUserInfo().position
    }
}
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DateRange } from '../../models/dateRange.model';
import { MessageDispatchService } from '../../services/messageDispatch.service';
import { SiteAction } from '../../models/siteAction.enum';
import { DatePipe } from '@angular/common';
import { UtilityHelper } from '../../helpers/utils';
import { CultureService } from '../../services/culture.service';
import { TranslateService } from '@ngx-translate/core';
import { DaterangepickerComponent } from '../ng2-daterangepicker/ng2-daterangepicker.component';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit, OnChanges {

  @Input()
  labelText: string;

  @Input()
  singlePicker = true;

  @Input()
  disable = false;

  @Input()
  placeHolder: string = UtilityHelper.getDefaultDateformat();

  @Output()
  public onChange = new EventEmitter<DateRange>()

  @Output()
  public invalidDate = new EventEmitter<boolean>()

  @Input()
  public beginDate: string;

  @Input()
  public endDate: string;

  @Input()
  public canHaveRange = true;

  @Input()
  public CanValidateFriday = true;

  @Input()
  public timePicker;

  @Input()
  public dateFormat: string = UtilityHelper.getDefaultDateformat();

  @Input()
  angularDatePipeFormat: string = "dd-MMM, yyyy"; 
  
  @ViewChild(DaterangepickerComponent)
  private picker: DaterangepickerComponent;

  public randomclass = Math.random().toString(36).substring(7);
  public options: any;
  public canShowError = false;

  private _datePipe: DatePipe;

  constructor(
    private _messageDispatcher: MessageDispatchService,
    private _translateService: TranslateService,
    private _cultureService: CultureService
    ) {

    this._datePipe = new DatePipe(this._cultureService.getCurrentLanguage().code);
    this._messageDispatcher.subscribe(SiteAction.StudyDateRangeSet, (value) => {
      if (this.canHaveRange) {
        this.options["minDate"] = this._datePipe.transform(value.data.minDate, this.angularDatePipeFormat)
        this.options["maxDate"] = this._datePipe.transform(value.data.maxDate, this.angularDatePipeFormat)
      }
    })
  }


  ngOnChanges(changes: SimpleChanges): void {

    if (changes.beginDate.firstChange || (changes.endDate && changes.endDate.firstChange)) {
      return;
    }

    this.setPickerDisplayDate();

  }

  ngOnInit() {
    const language =  this._cultureService.getCurrentLanguage();
    this.options = {
      locale: { 
        format: this.dateFormat, 
        applyLabel: this._translateService.instant("Apply"),
        cancelLabel: this._translateService.instant("Cancel"),
        fromLabel: this._translateService.instant("From"),
        toLabel: this._translateService.instant("To"),
      },
      alwaysShowCalendars: false,
      parentEl: `.${this.randomclass}`,
      opens: "left",
      singleDatePicker: this.singlePicker,
      buttonClasses: "btn",
      applyButtonClasses: "blue-btn",
      cancelClass: "blue-border-btn",
      timePicker: ''
    };
    this.options.timePicker = this.timePicker;

    if (!this.singlePicker) {
      this.options["maxSpan"] = {
        "days": 6 //limits picker to 1 week or less
      };
    }
  }

  public selectedDate(value: any) {

    if (this.CanValidateFriday && !this.isFriday(value.start.weekday())) {
      this.canShowError = true;
      this.invalidDate.emit(true);
      return
    }
    const startDate = value.start.format();
    const endDate = value.end.format();

    var startUtcDate = new Date(startDate)
    var endUtcDate = new Date(endDate);

    this.canShowError = false;
    this.invalidDate.emit(false);
    this.onChange.emit(new DateRange(startUtcDate, endUtcDate));
  }

  isFriday(weekDay) {
    return weekDay == 5;
  }

  ngAfterViewInit(): void {
    this.setPickerDisplayDate();
  }

  setPickerDisplayDate() {
    if (this.beginDate && this.singlePicker) {
      this.picker.datePicker.setStartDate(this.beginDate);
      this.picker.datePicker.setEndDate(this.beginDate);

    } else if (this.beginDate && !this.singlePicker) {
      this.picker.datePicker.setStartDate(this.beginDate);
      this.picker.datePicker.setEndDate(this.endDate);
    }
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MsalConfigurationService } from '../shared/services/msalConfiguration.service';
import { FooterService } from '../shared/services/footer.service';
import { AppSettingsService } from '../shared/services/appSettings.service';
import { SystemSetting } from '../shared/models/system-setting.model';
import moment from 'moment';

@Component({
  selector: 'app-test-environment',
  templateUrl: './test-environment.component.html',
  styleUrls: ['./test-environment.component.css'],
})
export class TestEnvironmentComponent implements OnInit {
  form: FormGroup;
  prodRedirectUrl = '';
  isSubmitted = false
  passwordSetting: SystemSetting;

  constructor(
    private formBuilder: FormBuilder,
    private configService: MsalConfigurationService,
    private router: Router,
    private route: ActivatedRoute,
    private _footerService: FooterService,
    private _appSettingsService: AppSettingsService
  ) {
    _footerService.displayDefault();

    this.form = this.formBuilder.group({
      password: ['', [Validators.required]]
    })
  }

  ngOnInit() {
    this.prodRedirectUrl = this.configService.config.prodRedirectUrl;        
  }

  onPasswordSubmit() {
    this.isSubmitted = true
    this.form.controls.password.setErrors(null);
    if(this.form.value.password && this.form.value.password.length > 0) {
      this._appSettingsService.validateTestEnvironmentPassword(this.form.value.password).subscribe({ 
        next: (settings) => {
            this.passwordSetting = settings;
            sessionStorage.setItem('isCorrectPasswordForTestEnvironment', 'true')
            this.router.navigateByUrl(
              this.getRedirectUrl(this.route.snapshot.queryParams['fromUrl'])
            );
        }, 
        error: (error) => {
          this.passwordSetting = error;
          this.form.controls.password.setErrors({ 'noMatch': true});
          this.form.updateValueAndValidity();
        }
      });      
    }
    else {
      this.form.controls.password.setValue('');
      this.form.updateValueAndValidity()
    }

    
  }

  formatTime(time: Date) : string {
    return moment.utc(time).format("LLL z");
  }

  private getRedirectUrl(targetUrl: string) : string {
    return (!targetUrl || (targetUrl === "/" || targetUrl.endsWith("home") || targetUrl.endsWith("home/")))
      ? "/loading"
      : targetUrl;
  }


}

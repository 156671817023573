import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[digitOnly]'
})
export class DigitOnlyDirective {
  
  private trimLeadingZeroes: boolean = false;

  private inputElement: HTMLInputElement;

  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];

  constructor(private el: ElementRef) { 
    this.inputElement = el.nativeElement;
    this.trimLeadingZeroes = this.inputElement.hasAttribute("trimLeadingZeroes");    
  }  

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {        
    // Skip non digits and leading zeroes, pass control keys
    if (!this.isControlKey(e) && (!this.isDigit(e) || this.isLeadingZero(e))) {
      e.preventDefault();      
    }    
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    if(event.clipboardData) {
      const text = this.sanitizeText(event.clipboardData.getData('text/plain'));             
      document.execCommand('insertText', false, text);
    }    
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    if(event.dataTransfer) {
      const text = this.sanitizeText(event.dataTransfer.getData('text'));
      this.inputElement.focus();
      document.execCommand('insertText', false, text);
    }    
  }

  isLeadingZero(e: KeyboardEvent) : boolean {
    if(this.trimLeadingZeroes && e.key === '0') {
      const element = (<HTMLInputElement>e.target);
      return (!element.selectionStart);     
    }    
    return false;
  }

  isDigit(e: KeyboardEvent): boolean {
    return /^\d+$/.test(e.key)
  }

  isControlKey(e: KeyboardEvent): boolean {    
    return this.navigationKeys.indexOf(e.key) > -1 ||
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) // Cmd+X (Mac)
  }

  sanitizeText(text: string): string {
    let foramttedText = text.replace(/\D/g, '');       
    if(this.trimLeadingZeroes) {
      foramttedText = foramttedText.replace(/\b0+/g, '');
    }
    return foramttedText;
  }
}
<div class="bsf-store" *ngIf="showLink">
  <a  href="https://store.mybsf.org/" target="_blank">
    <div class="bsf-store--content">
        <i class="fas fa-star"></i>
        <div class="bsf-store--label">
            {{ 'BSFStore' | translate}}
            <br>
            <span *ngIf="showLessonMaterials">{{'OrderLessonMaterials' | translate}}</span>
        </div>
    </div>
  </a>  
</div>

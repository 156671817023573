<div class="modal-content d-flex justify-content-center text-center">
  <div class="modal-header flex-column justify-content-center align-items-center d-flex">
    <h3 class="modal-title">{{ user.firstName + ' ' + user.lastName }}</h3>
    <p class="gray-text">
      <app-country-flag [country]="user.country"></app-country-flag>
      {{user.country}}
      <app-local-clock [timeZone]="user.timeZone"></app-local-clock>
      • {{'Position' | translate}}: {{user.position}} • {{'Age' | translate}}: {{user.age}} • {{'Gender' | translate}} {{user.gender}}
    </p>
  </div>
  <div class="modal-body">
    <h3><b>{{'AssignUserTo' | translate}}</b></h3>
    <h3>{{ group.meetingNickName }}?</h3>
    <p class="gray-text">
      {{'MeetingType' | translate}}: {{group.meetingType}} • {{'Lang' | translate}}: {{group.spokenLanguage}} •<br>
      {{'AgeRange' | translate}}: {{group.ageRange}} 
    </p>
    <div class="d-flex align-items-center justify-content-center">
      <p class="switchgroup-time">{{'GroupDiscuss' | translate}}: {{group.meetingDayOfWeekConverted}}, {{group.meetingTimeConverted}} {{user.timeZone}}</p>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-center pt-0 flex-column">
    <div class="d-flex align-items-center justify-content-center">
      <p>{{'SelectGroup' | translate}}</p>
    </div>
    <div class="d-flex flex-sm-row flex-column justify-content-center">
      <button type="button" class="blue-btn modal-button mb-3 mr-sm-3" data-dismiss="modal" (click)="select()">{{'Select' | translate}}</button>
      
      <button type="button" class="blue-btn modal-button mb-3" data-dismiss="modal" (click)="cancel()">{{'Cancel' | translate}}</button>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';

export interface IMessage {
  text: string,
  category: string
}

@Injectable()
export class AlertMessageService {
  constructor() {

  }
  private alertStatus = new BehaviorSubject<any>(_);

  sendMessage(data: IMessage) {
    console.log("Sending message: ", data);
    this.alertStatus.next({ data: data });
  }

  getMessage(): Observable<any> {
    return this.alertStatus.asObservable();
  }

  resetMessage() {
    this.alertStatus.next({});
  }

}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'booltoText'
})
export class BoolToTextPipe implements PipeTransform {

    transform(bool?: boolean, options: { positiveText: string, negativeText: string } =
        { positiveText: "Agreed", negativeText: "Disagreed" }) {

        if (bool == null || bool == undefined) {
            return;
        }
        return bool ? options.positiveText : options.negativeText
    }
}
<div class="form-row row profile-photo">
  <div class="col-auto photo" [style.background-image]="'url(' + imagedisplay + ')'">
    <img class="photo nonphoto" *ngIf="showPlaceHolder" [src]="imagedisplay" />
  </div>
  <div class="col">
    <div class="form-group">
      <label for="profile-pic">{{'ProfilePhoto' | translate}}</label> <br />
      <input style='display: none;'class="form-control-file" type="file" name="profile-pic"
        id="profile-pic" (change)="upload($event.target.files)" accept="image/*" />
      <input accept="image/*" type="button" id="profile-pic-button" value="{{'ChooseFile' | translate}}" onclick="document.getElementById('profile-pic').click();" />
      <div class="invalid-feedback">
        {{"ProvideProfile" | translate}}.
      </div>
      <alert type="danger" *ngIf="showImgError">
        {{"errorImageSize" | translate}}
      </alert>
      <alert type="danger" *ngIf="showUploadError">
        {{"errorImageUpload" | translate}}
      </alert>
    </div>
  </div>
</div>

import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UserSearchCriteria } from "../models/usersSearchCriteria.model";
import { NamedId } from "../models/namedId.model";
import { ConfigService } from "./config.service";


@Injectable()
export class UserService {
    private _usersEndpoint;

    constructor(
      private _http: HttpClient,
      private configService:ConfigService
    ) {
      configService.configuration$.subscribe(config => {
        this._usersEndpoint = `${config.apiEndpoint}users`;
      });
    }

    searchUsers(searchCriteria: UserSearchCriteria) {
        return this._http.get<NamedId<number>[]>(`${this._usersEndpoint}`, { params: searchCriteria.toParams() });
    }

}

<div class="modal-content text-center">
    <div class="modal-header d-flex justify-content-center">
        <h2>{{'WithdrawApp' | translate}}</h2>
    </div>
    <div class="modal-body">
        <p class="gray-text">{{'WithdrawReason' | translate}}:</p><br>
        <textarea maxlength="1000" name="comment" [(ngModel)]="comment" class="form-control"></textarea>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="blue-btn modal-button" (click)="select()">{{'Submit' | translate}}</button>
        <button type="button" class="blue-border-btn modal-button" (click)="cancel()"> {{'Cancel' | translate}}</button>
    </div>
</div>

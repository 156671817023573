import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SpinnerService } from '../widgets/spinner/spinner.service';


@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  spinnerSubject$ = new ReplaySubject<boolean>();
  spinning$ = this.spinnerSubject$.asObservable();

  constructor(private spinnerService:SpinnerService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const noSpinner = 'no-spinner';
    if (request.headers.has(noSpinner)) {
      const headers = request.headers.delete(noSpinner);
      return next.handle(request.clone({ headers }));
    }
    this.spinnerService.show();
    return next.handle(request).pipe(
      map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          this.spinnerService.hide();
        }
        return evt;
      }),
      catchError((err) => {
        this.spinnerService.hide();
        return throwError(err);
      })
    );
  }
}

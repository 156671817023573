import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DataTranslationService {  
    constructor(
        private _translate: TranslateService
    ) {
        
    }  
    translate(items: any[]): any[] {
        for (const item of items) {
            if (!!item.displayName) {
                item.displayName = this._translate.instant(item.displayName);
            }
        }
        return items;
    }    
}
<div class="modal-content text-center">
    <div class="modal-header d-flex justify-content-center">

    </div>
    <div class="modal-body">
        <p>This change of position will make the group leaderless. Do you still want to proceed?</p>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="blue-btn modal-button" (click)="select()">{{'Submit' | translate}}</button>
        <button type="button" class="blue-border-btn modal-button" (click)="cancel()"> {{'Cancel' | translate}}</button>
    </div>
</div>
import { HttpBackend, HttpHandler } from '@angular/common/http';
import { ConfigService } from './../../../shared/services/config.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AnnounceMentBar } from '../models/announcement-bar.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface AnnouncementState {
  id: string;
  whenClosed: Date
}

const stateKey = 'BSFOnline:announcements';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementBarService {
  private _announcementsEndPoint;
  private _httpNoToken:HttpClient;
  constructor(
    private _http: HttpClient,
    private configService:ConfigService,
    handler: HttpBackend
  ) {
    configService.configuration$.subscribe(config => {
      this._announcementsEndPoint = `${config.apiEndpoint}announcements/`;
    });
    this._httpNoToken = new HttpClient(handler);

  }

  private getAnnouncements(announcementType: string) : Observable<AnnounceMentBar[]> {
    return this._http.get<AnnounceMentBar[]>(`${this._announcementsEndPoint}${announcementType}`)
      .pipe(map(x=> this.decodeAnnouncements(x)))
  }  

  public getActivatedSystemAnnouncements(onlyNotSeen: boolean = false): Observable<AnnounceMentBar[]> {
    const list$ = this._httpNoToken.get<AnnounceMentBar[]>(`${this._announcementsEndPoint}activated`);
    const result = onlyNotSeen ? this.excludeSeenAnnouncements(list$) : list$;    
    return result.pipe(map(x=> this.decodeAnnouncements(x)));
  }

  public getAllSystemAnnouncements(): Observable<AnnounceMentBar[]> {
    return this.getAnnouncements("all");
  }

  public getWeeklySystemAnnouncements(): Observable<AnnounceMentBar[]> {
    return this.getAnnouncements("weekly");
  }

  public saveSystemAnnouncements(announcements: AnnounceMentBar[]) {
    const encoded = this.encodeAnnouncements(announcements);
    return this._http.put<AnnounceMentBar[]>(`${this._announcementsEndPoint}update_announcements`, encoded);
  }

  public markAsSeen(id: string) {     
    const currentDate = this.getToday();    
    let state = this.loadAnnouncementState();
    let foundAnnouncementState  = null;
    if(state) {
      foundAnnouncementState = state.find(x=>x.id === id);
    }
    else {
      state = [];
    }    
    if(foundAnnouncementState) {
      foundAnnouncementState.whenClosed = currentDate;
    }
    else {
      state.push({ id: id, whenClosed: currentDate });
    }
    const twoDaysBefore =new Date(currentDate.getTime() - (2 * 24 * 60 * 60 * 1000));
    state = this.filterAfterDate(state, twoDaysBefore);    
    this.saveAnnouncementState(state);
  }

  getToday() {
    let now = (new Date());
    now.setHours(0,0,0,0);
    return now;
  }

  saveAnnouncementState(state: AnnouncementState[]) {
    localStorage.setItem(stateKey, JSON.stringify(state))
  } 

  loadAnnouncementState() : AnnouncementState[]  {
    const announcementStateJson = localStorage.getItem(stateKey);
    return JSON.parse(announcementStateJson);
  }

  excludeSeenAnnouncements(list$: Observable<AnnounceMentBar[]>): Observable<AnnounceMentBar[]> {
    const seenAnnouncementIds = this.getSeenAnnouncementIds(this.getToday());
    return list$.pipe(map(an => 
      an.filter(x => !seenAnnouncementIds || 
        seenAnnouncementIds.indexOf(x.systemMaintenanceId ) < 0)));
  }

  filterAfterDate(state: AnnouncementState[], afterDate): AnnouncementState[] {
    return state ? state.filter(x=> new Date(x.whenClosed) >= afterDate) : [];
  }

  getSeenAnnouncementIds(wereSeenOnDate: Date) {
    const state = this.loadAnnouncementState();    
    let seenAnnouncementIds = null;
    if(state) {
      seenAnnouncementIds = state.filter(x=> new Date(x.whenClosed) >= wereSeenOnDate).map(x=>x.id);
    }
    return seenAnnouncementIds;
  } 

  encodeAnnouncements(announcements: AnnounceMentBar[]) {
    for (const announcement of announcements) {
      announcement.announcementMessage = this.encode(announcement.announcementMessage);      
    }
    return announcements;
  }

  // Proper unicode base64 encoding: https://developer.mozilla.org/en-US/docs/Web/API/btoa
  toBinary(text: string): string {
    const codeUnits = new Uint16Array(text.length);
    for (let i = 0; i < codeUnits.length; i++) {
      codeUnits[i] = text.charCodeAt(i);
    }
    const charCodes = new Uint8Array(codeUnits.buffer);
    let result = '';
    for (let i = 0; i < charCodes.byteLength; i++) {
      result += String.fromCharCode(charCodes[i]);
    }
    return result;
  }

  fromBinary(binary: string): string {
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    const charCodes = new Uint16Array(bytes.buffer);
    let result = '';
    for (let i = 0; i < charCodes.length; i++) {
      result += String.fromCharCode(charCodes[i]);
    }
    return result;
  }

  encode(text: string): string {    
    const converted = this.toBinary(text);
    const encodedText = btoa(converted);
    return encodedText;
  }

  decodeAnnouncements(announcements: AnnounceMentBar[]) {
    for (const announcement of announcements) {
      announcement.announcementMessage = this.decode(announcement.announcementMessage);      
    }
    return announcements;
  }

  decode(encodedText: string) {
    const decoded = atob(encodedText);
    const original = this.fromBinary(decoded);
    return original;
  }

}


import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.css']
})
export class PagingComponent implements OnInit {

  @Output() pageChange = new EventEmitter<number>();

  pageChanged(e): void {
    const newValue = e.target.value;
    if (newValue > 0 && newValue <= this.pageCount) {
      this.pageNumber = newValue;
    } else {
      this.pageNumber = 1;
    }

    this.pageChange.emit(this.pageNumber);
  }


  @Input()
  public canGoNext: boolean

  @Input()
  public canGoPrevious: boolean

  @Input()
  public pageCount: number

  @Input()
  public pageNumber: number;

  @Input()
  public pageNumberReadOnly: boolean;

  @Input()
  public pageSize: number = 10;

  @Input()
  public showPageSize: boolean = false;

  @Output()
  public goNext = new EventEmitter();

  @Output()
  public goPrevious = new EventEmitter();

  @Output() pageSizeChange = new EventEmitter<number>();

  constructor() {
    this.pageNumberReadOnly = false;
   }

  ngOnInit() {
  }

  gotoPrevious() {
    this.goPrevious.emit();
  }

  gotoNext() {
    this.goNext.emit();
  }

  onPageSizeChanged(value: string) {
    this.pageSize = parseInt(value);
    this.pageNumber = 1;
    this.pageSizeChange.emit(this.pageSize);
  }
}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'validation',
  template: `
    <div class="feedback">
      <div *ngFor="let message of messages">{{message}}</div>
    </div>
  `
})
export class ValidationComponent {
  @Input() messages: Array<string>;
}
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-decline-candidate-leadership-application-modal',
  templateUrl: './decline-candidate-leadership-application-modal.component.html',
  styleUrls: ['./decline-candidate-leadership-application-modal.component.css']
})
export class DeclineCandidateLeadershipApplicationModalComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DeclineCandidateLeadershipApplicationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {

  }

  select(): void {
    this.dialogRef.close({action: 'proceed'});
  }

  cancel(): void {
    this.dialogRef.close({action: 'cancel'});
  }

}

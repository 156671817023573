import { Component, OnInit, Input } from '@angular/core';
import { Country } from '../../models/country.model';

@Component({
  selector: 'app-country-flag',
  templateUrl: './country-flag.component.html',
  styleUrls: ['./country-flag.component.css']
})
export class CountryFlagComponent implements OnInit {

  @Input()
  country: string

  constructor() { }

  ngOnInit() {
  }

  get countryObject() {
    return new Country(1, null, this.country, null)
  }

}

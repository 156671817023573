import { Component, OnInit, Input } from '@angular/core';
import { UtilityHelper } from '../../helpers/utils';

@Component({
  selector: 'app-user-image',
  templateUrl: './user-image.component.html',
  styleUrls: ['./user-image.component.css']
})
export class UserImageComponent implements OnInit {

  @Input()
  base64string

  @Input()
  class

  @Input()
  ngclass: {[k: string] : boolean}

  constructor() { }

  ngOnInit() {
    if(!this.class) {
      this.class = 'user-item-pic';
    }
  }

  getPhoto() {
    return UtilityHelper.convertToImage(this.base64string);
  }

}

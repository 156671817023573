import { Time } from "@angular/common";

export class AnnounceMentBar {
  systemMaintenanceId:number;
  announcementMessage: string;
  announcementStartTime:Date;
  announcementEndTime:Date;
  isWeeklyMaintenance:boolean;
  dayofWeek:number;
  startTime:string;
  endTime:string;
  isDisplayEnabled:boolean;
  creationTime:Date;
  displayName:string;



  static create(item: AnnounceMentBar) {
      const model = new AnnounceMentBar();
      model.systemMaintenanceId = item.systemMaintenanceId;
      model.announcementMessage = item.announcementMessage;
      model.announcementStartTime = item.announcementStartTime;
      model.announcementEndTime = item.announcementEndTime;
      model.isWeeklyMaintenance = item.isWeeklyMaintenance;
      model.dayofWeek = item.dayofWeek;
      model.startTime = item.startTime;
      model.endTime = item.endTime;
      model.isDisplayEnabled = item.isDisplayEnabled;
      model.creationTime = item.creationTime;
      model.displayName = item.displayName;
      return model;
  }

}




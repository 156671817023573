import { Component, OnInit, ViewChild, ElementRef, HostListener } from "@angular/core";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { filter } from "lodash";
import { Subject, forkJoin } from "rxjs";
import { PersonMaterialStatusEnum } from "src/app/shared/models/enums/person-material-status.enum";
import { NotificationModel } from "src/app/shared/models/notification.model";
import { NotificationService } from "src/app/shared/services/notification.service";
import { SessionService } from "src/app/shared/services/session.service";


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  isMenuExpanded: boolean;
  alerts: NotificationModel[];
  selectedIndex: number;
  alertsCount:number;
  _unsubscribeAll: Subject<any> = new Subject<any>();
  isLoading:boolean;
  faCaretDown = faCaretDown

  personMaterialStatusEnum = PersonMaterialStatusEnum

  @ViewChild('notifications', {static: false}) notification: ElementRef;

  constructor(
    private _notificationService: NotificationService,
    private _sessionService: SessionService
  ) {
  }

  public get count() {
    return this.alertsCount;
  }

  public get hasNext() {
    return this.selectedIndex < this.alerts?.length - 1;
  }

  public get hasPrevious() {
    return this.selectedIndex > 0;
  }

  public get hasNotifications() {
    return this.alertsCount > 0;
  }

  public get selectedAlert() {
    return (this.alerts || [])[this.selectedIndex];
  }

  ngOnInit(): void {
    console.log("NotificationsComponent init");
    this._notificationService.dismissNotificationPanel$.subscribe(() => {
      this.isMenuExpanded = false;
      this.selectedIndex = -1;
    });
    this.getNotificationsCount();
    //this.getNotifications();
    // setInterval(getNotifications, 300000);
  }

  getNotifications(){
    const user = this._sessionService.getUserInfo();
    const viewedNotifications =
      JSON.parse(localStorage.getItem('viewed-notifications') || '[]') ||
      [];
    this.isLoading = true;
    console.log("getting notifications");
    const joined$ = forkJoin({
      newAlerts: this._notificationService.getNotifications(user.displayLanguageId, false, [PersonMaterialStatusEnum.New]),
      readAlerts: this._notificationService.getNotifications(user.displayLanguageId, false, [PersonMaterialStatusEnum.Read]),
    })
    joined$.subscribe(
      ({ newAlerts, readAlerts }) =>{
        this.alerts = newAlerts.concat(readAlerts)

        this.alerts.forEach((alert) => {
          alert.isViewed = readAlerts.indexOf(alert) >= 0
        })

        this.isLoading = false;
      }
    );

  };

  getNotificationsCount(){
    const user = this._sessionService.getUserInfo();
    this._notificationService.getNotificationsCount(user.displayLanguageId, false, [PersonMaterialStatusEnum.New]).subscribe(
      (count) =>
        (this.alertsCount = count)
    );
  };

  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement: HTMLElement) {
    const clickedInside = this.notification.nativeElement.contains(targetElement)
      || targetElement.classList?.contains('download-link')
      || targetElement.classList?.contains('notification-action-button');

    if (!clickedInside) {
      this.isMenuExpanded = false;
      this.selectedIndex = -1;
    }
  }

  public previousAlert() {
    this.selectAlert(Math.max(this.selectedIndex - 1, 0));
  }

  public nextAlert() {
    this.selectAlert(
      Math.min(this.selectedIndex + 1, this.alerts?.length || 0)
    );
  }

  public selectAlert(index) {
    this.selectedIndex = index;

    if(!this.selectedAlert.isViewed)
      this.updateNotificationStatus(this.selectedAlert, PersonMaterialStatusEnum.Read)
  }

  updateNotificationStatus(alert: NotificationModel, personMaterialStatus: PersonMaterialStatusEnum) {
    this._notificationService.postNotificationStatus(alert.materialId, personMaterialStatus).subscribe(() => {
      if (personMaterialStatus == PersonMaterialStatusEnum.New)
        this.alertsCount += 1
      else if (personMaterialStatus == PersonMaterialStatusEnum.Read)
        this.alertsCount -= 1
      else {
        if(!alert.isViewed)
          this.alertsCount -= 1
        const index = this.alerts.indexOf(alert)
        this.alerts.splice(index, 1)
        if(this.selectedAlert && !this.selectedAlert.isViewed)
          this.updateNotificationStatus(this.selectedAlert, PersonMaterialStatusEnum.Read)
      }
      alert.isViewed = !alert.isViewed
    });
  }

  loadNotificationsDetails(){
    this.isMenuExpanded = !this.isMenuExpanded;
    if(this.isMenuExpanded)
    {
      if(!this.alerts){
        this.getNotifications();
      }
      this.selectedIndex = -1;
    }
  }
  isMobile():boolean{
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  }
  hideNotificationsItems(){
    if(this.isMobile()){
      return this.isMenuExpanded && this.selectedAlert;
    }
    return false;
  }
}

<div class="modal-content text-center">
    <div class="title modal-header d-flex align-items-center justify-content-center">
        {{ 'welcomeBack' | translate }} {{ userName }}
    </div>
    
    <div class="modal-body pt-4 px-4 pb-0">
        <h5 class="pt-2 font-weight-bold">{{ 'yourMobileNumberHidden' | translate }}
        </h5>
        <p class="small-text pt-2">
            {{'youCanShowYourNumber' | translate }} 
            <a [href]="(accountsBaseUrl + 'account/profile')">{{ 'EditProfile'| translate}}</a>            
            {{'andYourEmailAddressIn' | translate}} 
            <a [href]="(accountsBaseUrl + 'settings/email')">{{'accountSettings'| translate}}</a> 
            {{ 'anyTime' | translate }}
        </p>
        <p class="pt-2 small-text font-italic">
            {{ 'noteYourGroupLeader'| translate }}
        </p>
    </div>
    
    <div class="footer modal-footer d-flex justify-content-center">
        <button type="button" 
            class="blue-btn modal-button" (click)="bsModalRef.hide()">
            {{'Ok' | translate}}
        </button>
    </div>    
</div>
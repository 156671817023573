import { Injectable } from '@angular/core';
import { SiteAction } from '../models/siteAction.enum';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EventAction } from '../models/eventAction.model';

@Injectable()
export class MessageDispatchService {
  private subject = new Subject<EventAction>();

  subscribe(
    action: SiteAction,
    next: (value: EventAction) => void
  ): Subscription {
    return this.subject
      .asObservable()
      .pipe(filter(eventAction => eventAction.action === action))
      .subscribe(eventAction => next(eventAction));
  }

  dispatch(action: SiteAction, data?: any) {
    const eventAction = new EventAction(action, data);

    this.subject.next(eventAction);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './layout/home/home.component';
import { ComingsoonComponent } from './layout/comingsoon/comingsoon.component';
import { AuthGuardService } from './shared/authGuards/authGuard.service';
import { WelcomeAreaGuardService } from './shared/authGuards/welcomeAreaGuard.service';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { TestEnvironmentComponent } from './test-environment/test-environment.component';
import { MsalGuard } from '@azure/msal-angular';


const routes: Routes = [
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule)        
    },
    {
        path: 'users',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
        canActivate: [WelcomeAreaGuardService]
    },
    {
        path: 'mygroup',
        loadChildren: () => import('./online-group/online-group.module').then(x => x.OnlineGroupModule),
        canActivate: [WelcomeAreaGuardService]
    },
    {
        path: 'finda',
        loadChildren: () => import('./user-management/user-management.module').then(x => x.UserManagementModule),
        canActivate: [WelcomeAreaGuardService]
    },
    {
        path: 'myclass',
        loadChildren: () => import('./online-class/online-class.module').then(x => x.OnlineClassModule),
        canActivate: [WelcomeAreaGuardService]
    },
    {
        path: 'mytraining',
        loadChildren: () => import('./trainings/trainings.module').then(x => x.TrainingsModule),
        canActivate: [WelcomeAreaGuardService]
    },
    {
        path: 'user-management',
        loadChildren: () => import('./user-management/user-management.module').then(x => x.UserManagementModule),
        canActivate: [WelcomeAreaGuardService]
    },
    {
        path: 'mylesson',
        loadChildren: () => import('./lessons/lessons.module').then(x => x.LessonsModule),
        canActivate: [WelcomeAreaGuardService]
    },
    {
        path: 'area',
        loadChildren: () => import('./region/region.module').then(x => x.RegionModule),
        canActivate: [WelcomeAreaGuardService]
    },
    {
        path: 'region',
        loadChildren: () => import('./region/region.module').then(x => x.RegionModule),
        canActivate: [WelcomeAreaGuardService]
    },
    {
        path: 'calendar',
        loadChildren: () => import('./calender/calender.module').then(x => x.CalenderModule),
        canActivate: [WelcomeAreaGuardService]
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(x => x.AdminModule),
        canActivate: [WelcomeAreaGuardService]
    },
    {
        path: 'leadership-application',
        loadChildren: () => import('./leadership-application/leadership-application.module').then(x => x.LeadershipApplicationModule),
        canActivate: [WelcomeAreaGuardService]
    },
    {
        path: 'help-resource-materials',
        loadChildren: () => import('./help-resource-materials/help-resource-materials.module').then(x => x.HelpResourceMaterialsModule),
        canActivate: [WelcomeAreaGuardService]
    },
    {
      path: 'attendance-list',
      loadChildren: () => import('./attendance-list/attendance-list.module').then(x => x.AttendanceListModule),
      canActivate: [WelcomeAreaGuardService]
    },
    {
      path: 'cookie-policy',
      loadChildren: () => import('./cookie-policy/cookie-policy.module').then(x => x.CookiePolicyModule),
    },
    {
      path: 'home',
      component: HomeComponent,
      pathMatch: 'full',
      canActivate: [MsalGuard, WelcomeAreaGuardService]
    },
    {
        path: '',
        component: HomeComponent,
        pathMatch: 'full',
        canActivate: [WelcomeAreaGuardService]
    },
    {
        path: 'comingsoon',
        component: ComingsoonComponent
    },
    {
        path: 'loading',
        component: LoadingPageComponent
    },
    {
        path: 'test-environment',
        component: TestEnvironmentComponent,
    },
    { path: '**', redirectTo: '' },
];

const routerOptions: ExtraOptions = {
    useHash: false,
    anchorScrolling: 'enabled',
    preloadingStrategy: PreloadAllModules    
};


@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }

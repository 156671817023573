<div class="modal-content text-center">
    <ng-container *ngIf="data.canRecommend">
        <div class="modal-header d-flex justify-content-center">
            <h2>{{'WantRecommend' | translate}}:</h2>
        </div>
        <div class="modal-body">
            <p>{{data.user.fullName}}</p>
        </div>
        <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="blue-btn modal-button" (click)="data.confirm()">{{'Yes' | translate}}</button>
            <button type="button" class="blue-border-btn modal-button" data-dismiss="modal" (click)="closeMe()">
                {{'Cancel' | translate}}</button>
        </div>
    </ng-container>
    <ng-container *ngIf="!data.canRecommend">
        <div class="modal-body">
            <p>{{data.user.fullName}} {{'Only1Recommendation' | translate}}.

            </p>
        </div>
        <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="blue-btn modal-button" data-dismiss="modal" (click)="closeMe()">
                {{'Ok' | translate}}</button>
        </div>
    </ng-container>

</div>

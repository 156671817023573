import {
  Component,
  Optional,
  Inject,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';

import {
  NgModel,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  NG_ASYNC_VALIDATORS,
  NgForm,
} from '@angular/forms';
import { ElementBase } from './element-base';
import { NamedId } from '../../models/namedId.model';

@Component({
  selector: 'app-input-select',
  template: `
    <div>
      <label *ngIf="label" [attr.for]="identifier">{{label | translate}}{{required ? "*" : ""}}</label>
      <select
          [(ngModel)]="value"
          [ngClass]="{'ng-invalid-input': canValidate && (invalid | async)}"
          [id]="identifier"
          class="form-control"
          (change)='valueChanged($event)'
          [disabled]="disable"
          >
        <option value="" selected *ngIf="placeholder">{{placeholder}}</option>
        <option *ngFor="let e of entries | async" [ngValue]="e.id">{{e.displayName | translate}}</option>
        <!-- <ng-content></ng-content> -->
      </select>
        <validation
          *ngIf="!hideError && canValidate && invalid | async"
          [messages]="failures | async">
        </validation>
    </div>
  `,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputSelectComponent,
    multi: true,
  }],
})
export class InputSelectComponent extends ElementBase<string> {
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public form: NgForm;
  @Input() public entries: NamedId<number>[]
  @Input() public isEntriesAsync = true;
  @Input() public disable: boolean;
  @Input() public required = false;
  @Input() public hideError = false;
  @Output() public onChange = new EventEmitter();

  @ViewChild(NgModel) model: NgModel;

  public identifier = `form-select-${identifier++}`;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>
  ) {
    super(validators, asyncValidators);
  }

  valueChanged($event) {
    this.onChange.emit($event.target.value)
    console.log($event.target.value)
  }
}

let identifier = 0;

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import moment from 'moment-timezone';
import { getCode, getName, overwrite } from 'country-list';
import * as informal from 'spacetime-informal';

interface Country {
  countryName: string;
  timeZones: TimeZone[]
}

interface TimeZone {
  zoneName: string,
  displayName: string,
  country: string
}

@Component({
  selector: 'app-timezone-picker',
  templateUrl: './timezone-picker.component.html',
  styleUrls: ['./timezone-picker.component.css']
})
export class TimezonePickerComponent implements OnInit {

  private _initTimezone: string;
  @Input('initTimezone') set initTimezone(value: string) {
    this._initTimezone = value;
    this.setInitTz();
  }
  @Input() public required;

  public allTimezonesList: Country[];
  public timezonesPerCountry: TimeZone[];
  public currentTimeZone: string;
  public currentZoneName: string;
  public dropDown: any;
  public searchElement: any;
  public resultsList: any;
  public errorMessage = false;
  public eventBlur: string;

  @Output() currentZoneNameEvent = new EventEmitter<string>();


  constructor() {

    this.allTimezonesList = new Array();

    overwrite([{
      code: 'TW',
      name: 'Taiwan'
    }]);

    let countries = moment.tz.countries();

    for (let i in countries) {
      this.timezonesPerCountry = new Array();
      let zonesInCountry = moment.tz.zonesForCountry(countries[i]);
      for (let entry of zonesInCountry) {
        let timezoneLongName = '';
        let matchingTimezone = informal.find(entry);

        let timezoneLongDisplay = informal.display(matchingTimezone);
        if (timezoneLongDisplay != null) {
          if (moment.tz(entry).isDST()) {
            if (timezoneLongDisplay.daylight) {
              timezoneLongName = `(${timezoneLongDisplay.daylight.name})`;
            }
          }
          else {
            if (timezoneLongDisplay.standard) {
              timezoneLongName = `(${timezoneLongDisplay.standard.name})`;
            }
          }
        }

        let displayName = `${entry} (${countries[i]}) ${moment.tz(entry).zoneAbbr()} UTC ${moment.tz(entry).format('Z')} ${timezoneLongName}`;

        this.timezonesPerCountry.push({ zoneName: entry, displayName: displayName, country: countries[i] });

      }

      this.allTimezonesList.push({ countryName: getName(countries[i]), timeZones: this.timezonesPerCountry });
    }
  }

  ngOnInit() {
    this.setInitTz();
    this.dropDown = document.getElementById("TZDropdown");
    this.searchElement = this.dropDown.getElementsByTagName("span");
    this.resultsList = document.getElementById("tzResultsList");
  }


  setInitTz() {
    let countryArr = this.allTimezonesList;
    if (this._initTimezone && this._initTimezone.length) {
      countryArr.find(x => {
        x.timeZones.find(y => {
          if (y.zoneName == this._initTimezone) {
            const displayTz = `${x.countryName} - ${y.displayName}`;
            this._initTimezone = displayTz;
            this.currentTimeZone = this._initTimezone;
          }
        });
      });
    } else {
      this.currentTimeZone = "Select Time Zone";
      this.currentZoneName = null;
    }
  }

  getEventBlur(event) {
    let searchBox = document.getElementById('TZInput') as any;
    let eventBlur = event.target.value;

    if (eventBlur == "") {
      searchBox.value = this.currentTimeZone;
    }
  }

  filterTimeZone(event) {
    if (event.target.value.length > 20) {
      event.target.value = "";
      event.preventDefault();
    }
    const content: string = event.target.value.toUpperCase();
    for (let i = 0; i < this.searchElement.length; i++) {
      let txtValue = this.searchElement[i].textContent || this.searchElement[i].innerText;
      if (txtValue.toUpperCase().indexOf(content) > -1) {
        this.searchElement[i].style.display = "";
      } else {
        this.searchElement[i].style.display = "none";
      }
    }
  }

  selectTimeZone(zoneName, countryName, displayName) {
    this.currentZoneName = zoneName;
    this.currentTimeZone = `${countryName} - ${displayName}`;
    this.currentZoneNameEvent.emit(this.currentZoneName);
    this.resultsList.style.display = "none";
    return false;
  }


  showResults(e) {
    if (this.dropDown.contains(e.target)) {
      this.resultsList.style.display = "block";
    } else {
      this.resultsList.style.display = "none";
    }
  }

  hideResults(e) {
    if (e.target != document.getElementById('TZInput')) {
      this.resultsList.style.display = "none";
    }
  }

}

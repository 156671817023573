<form class="form-inline">
    
    <div class="paginator-container form-group d-flex flex-row align-items-center flex-wrap justify-content-center">
        <div class="paging--page-of">
            <label for="page-bottom"><small>{{'Page' | translate}}</small></label>&nbsp;
            <input type="text" class="form-control" id="page-bottom" [readOnly]="pageNumberReadOnly" 
                [(ngModel)]="pageNumber" size="1" maxlength="4" style="min-width: 3rem;"
                (change)="pageChanged($event)" [ngModelOptions]="{standalone: true}" />
            &nbsp;<small>{{'Of' | translate}} {{pageCount}}</small> 
        </div>    
        
        <select *ngIf="showPageSize" class="form-control" id="pageSize" 
            name="pageSize"
            style="min-width: 4.5rem; width: 4.5rem;"
            [(ngModel)]="pageSize"
            (change)="onPageSizeChanged($event.target.value)">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>            
        </select>
        <span style="padding: 0 0.5rem;" *ngIf="showPageSize"><small>{{ 'perPage' | translate }}</small></span>
        <div>
            <button type="button" [disabled]="!canGoPrevious" (click)="gotoPrevious()" class="blue-border-btn btn" style="margin-top: 5px !important;">&lt;</button>&nbsp;
            <button type="button" [disabled]="!canGoNext" (click)="gotoNext()" class="blue-border-btn btn" style="margin-top: 5px !important;">&gt;</button>
        </div>
    </div>       
    
</form>

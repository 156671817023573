<nav id="navMenu" class="navbar navbar-light header-bar" [hidden]="hideNavMenu()">
  <a *ngIf="!isAuthenticated()" class="navbar-brand" [routerLink]="['/']"><img src="assets/images/bsf-logo.png" alt="Bible Study Fellowship Online" /></a>
  <div *ngIf="!isAuthenticated() && !shouldEnterPasswordToAccess()" class="justify-content-end">
    <button *ngIf="!isAuthenticated()" (click)="findGroup()" class="btn btn-link" [routerLink]="['/mygroup/group']" routerLinkActive="active"
      id="findGroup">{{'FindOnlineGroup' | translate}}</button>
    <button (click)="b2clogin()" class="blue-border-btn btn" id="yourAccount">{{'YourAccount' | translate}}</button>
  </div>
  
  <a *ngIf="canShownavMenu()" class="navbar-brand"><img src="assets/images/bsf-logo.png" alt="Bible Study Fellowship Online" /></a>

  <div *ngIf="canShownavMenu() && !shouldEnterPasswordToAccess()" class="justify-content-end">
    <a *ngIf="isLeader() || isGroupMember() || isOnlineMember()" [routerLink]="['/mygroup']">
      <button class="header-btn white-btn" [routerLinkActive]="['header-btn-active']" type="button">{{'MyGroup' | translate}}</button>
    </a>
    <a *ngIf="isAdmin() || isAdminViewOnly() || isRegionalDirector()" [routerLink]="['/region/my-region']">
      <button [routerLinkActive]="['header-btn-active']" class="header-btn white-btn" type="button">{{'MyRegion' | translate}}</button>
    </a>
    <a [routerLink]="['/mylesson']">
      <button class="header-btn white-btn" [routerLinkActive]="['header-btn-active']" type="button">{{'MyLessons' | translate}}</button>
    </a>
    <a *ngIf="isLeader() || isTrainer() || isAssistantTrainer()" [routerLink]="['/mytraining']">
      <button [routerLinkActive]="['header-btn-active']" class="header-btn white-btn" type="button">{{'MyTraining' | translate}}</button>
    </a>
    <a *ngIf="isAreaPersonnel()" [routerLink]="['/area/my-area']">
      <button [routerLinkActive]="['header-btn-active']" class="header-btn white-btn" type="button">{{'MyArea' | translate}}</button>
    </a>
    <a *ngIf="isAdmin()" [routerLink]="['/admin/study']">
      <button [routerLinkActive]="['header-btn-active']" class="header-btn white-btn" type="button">{{'Admin' | translate}}</button>
    </a>
    <a [routerLink]="['/calendar']">
      <button [routerLinkActive]="['header-btn-active']" class="header-btn white-btn" type="button">{{'MyCalendar' | translate}}</button>
    </a>
    <a [href]="internationalUrl + '/support/'" target="_blank">
      <button class="header-btn white-btn" type="button">{{'Give' | translate}}</button>
    </a>
    <div class="nav-btn-menu">
      <app-notifications *ngIf="!isOnlineMember()"></app-notifications>
      <div class="btn-group">
        <button type="button" class="btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="navbar-toggler-icon "></span>
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          <div class="user-menu-header dropdown-item">
            <div class="row">
              <div class="col-3">
                <div class="user-menu-header-pic mt-3" [style.background-image]="'url(' + userPhoto() + ')'"></div>
              </div>
              <div class="col-9">
                <p class="mt-3 ml-1">
                  {{userFullname()}}
                  <br>
                  <span class="user-menu-header-type">{{userPostion()}}</span>
                </p>
              </div>
            </div>
          </div>
          <p class="dropdown-header">{{'Account' | translate}}</p>
          <a (click)="goToProfile()" >
            <button class="dropdown-item pl-5" type="button">{{"EditProfile" | translate}}</button>
          </a>
          <a *ngIf="enableAccounts" (click)="goToSettings()">
            <button class="dropdown-item pl-5" type="button">{{"Account" | translate}} {{"Settings" | translate}}</button>
          </a>
          <a *ngIf="!enableAccounts" [routerLink]="['/account/passwordchange']">
            <button class="dropdown-item pl-5" type="button">{{'ChangePassword' | translate}}</button>
          </a>
          <a *ngIf="isTrainer() || isAssistantTrainer() || isLeader() || isRegionalDirector() || isAreaPersonnel() || isAdmin() || isAdminViewOnly()" [routerLink]="['/leadership-application/apply-recommend']">
            <button class="dropdown-item pl-5" type="button">{{'ApplyRecommend' | translate}}</button>
          </a>
          <a [routerLink]="['/leadership-application/application-status']" *ngIf="(isGroupMember() || isOnlineMember()) && canShowApplicationStatusStatus()">
            <button class="dropdown-item pl-5" type="button">{{"Application" | translate}}&amp; {{"Status" | translate}}</button>
          </a>
          <a *ngIf="enableMybsf" (click)="goToMybsf()">
            <button class="dropdown-item pl-5" type="button">{{"MybsfAccount" | translate}}</button>
          </a>
          <ng-container *ngIf="isLeader()">
            <div class="dropdown-divider"></div>
            <p class="dropdown-header">{{"MyGroup" | translate}}</p>
            <a *ngIf="isLeader()" [routerLink]="['/mygroup/edit']">
              <button class="dropdown-item pl-5" type="button">{{"MyGroupSettings" | translate}}</button>
            </a>
            <a *ngIf="isLeader()" [routerLink]="['attendance-list']">
              <button class="dropdown-item pl-5" type="button">{{"MyGroupAttendance" | translate}}</button>
            </a>
          </ng-container>

          <ng-container *ngIf="isLeader()">
            <!--<div class="dropdown-divider"></div>
            <p class="dropdown-header">{{"MyTraining" | translate}}</p>
            <a href="#"><button class="dropdown-item pl-5"
                            type="button">{{"TrainingAttendance" | translate}}</button></a>-->
          </ng-container>

          <ng-container *ngIf="isTrainer() || isAssistantTrainer()">
            <div class="dropdown-divider"></div>
            <p class="dropdown-header">{{"MyTraining" | translate}}</p>
            <a *ngIf="hasOnlineClass()" [routerLink]="['/mytraining/manage_group']">
              <button class="dropdown-item pl-5" type="button">{{"LGManage" | translate}}</button>
            </a>
            <a [routerLink]="['/myclass/leaders_meeting']" *ngIf="isTrainer() || hasOnlineClass()">
              <button class="dropdown-item pl-5" type="button">{{"LMSettings" | translate}}</button>
            </a>

            <a [routerLink]="['attendance-list']">
              <button class="dropdown-item pl-5" type="button">{{"MyTrainingAttendance" | translate}}</button>
            </a>
          </ng-container>

          <ng-container *ngIf="isAreaPersonnel()">
            <div class="dropdown-divider"></div>
            <p class="dropdown-header">{{"MyArea" | translate}}</p>
            <a [routerLink]="['/area/edit-area']" routerLinkActive="active">
              <button class="dropdown-item pl-5" type="button">{{"AreaSettings" | translate}}</button>
            </a>
          </ng-container>

          <ng-container *ngIf="isRegionalDirector()">
            <div class="dropdown-divider"></div>
            <p class="dropdown-header">{{"MyRegion" | translate}}</p>
            <a [routerLink]="['/region/edit-region']" routerLinkActive="active">
              <button class="dropdown-item pl-5" type="button">{{"RegionSettings" | translate}}</button>
            </a>
          </ng-container>

          <ng-container *ngIf="isTrainer() || isAssistantTrainer() || isRegionalDirector() || isAreaPersonnel() || isAdmin() || isAdminViewOnly()">
            <div class="dropdown-divider"></div>
            <p class="dropdown-header">{{"Manage" | translate}}</p>
            <a (click)="usersManagement()">
              <button class="dropdown-item pl-5" type="button" id="nav-userManage">{{"UserManage" | translate}}</button>
            </a>
            <a *ngIf="isTrainer()" [routerLink]="['/leadership-application/candidate-applications']">
              <button class="dropdown-item pl-5" type="button">{{"LeaderManage" | translate}}</button>
            </a>
          </ng-container>

          <div class="dropdown-divider"></div>
          <p class="dropdown-header">{{"Community" | translate}}</p>
          <a (click)="findAgroup()" *ngIf="isGroupMember() || isOnlineMember()" [routerLink]="['/mygroup/group']" routerLinkActive="active">
            <button class="dropdown-item pl-5" type="button" id="nav-findGroup">{{"FindSwitchGroup" | translate}}</button>
          </a>
          <a (click)="findOLM()" *ngIf="isLeader()" [routerLink]="['/leadership-application/findOLM']" routerLinkActive="active">
            <button class="dropdown-item pl-5" type="button">{{"FindOLM" | translate}}</button>
          </a>
          <a *ngIf="canUseFindOLM()" [routerLink]="['/mygroup/search-olm']" routerLinkActive="active">
            <button class="dropdown-item pl-5" type="button">{{"findAnOLM" | translate}}</button>
          </a>
          <a (click)="give()" [href]="internationalUrl + '/support/give-to-bsf'" target="_blank">
            <button class="dropdown-item pl-5" type="button" id="nav-give">{{"Give" | translate}}</button>
          </a>

          <div class="dropdown-divider"></div>
          <p class="dropdown-header">{{"Support" | translate}}</p>
          <a (click)="goToHelpAndResources()">
            <button class="dropdown-item pl-5" type="button" id="nav-helpRes">{{"Helps" | translate}} &amp; {{"Resources" | translate}}</button>
          </a>
          <a href="https://support.bsfonline.org/en/support/tickets/new" target="_blank">
            <button class="dropdown-item pl-5" type="button">{{"Contact" | translate}}</button>
          </a>
          <div class="dropdown-divider"></div>
            <button (click)="b2cLogout()" class="dropdown-item pl-5" type="button">{{"SignOut" | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</nav>

import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-hide-phone-email-dialog',
  templateUrl: './hide-phone-email-dialog.component.html',
  styleUrls: ['./hide-phone-email-dialog.component.css']
})
export class HidePhoneEmailDialogComponent {

  userName: string;
  accountsBaseUrl: string;

  constructor(public bsModalRef: BsModalRef) {
  }
}

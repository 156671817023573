<div>
  <label *ngIf="label" [attr.for]="name">
    {{label | translate}}{{required ? "*" : ""}}
    <ng-content select="[labelHtml]"></ng-content>
  </label>
  <input *ngIf="directive == 'phoneMask'"
         [type]="type"
         [placeholder]="placeholder"
         [(ngModel)]="value"
         [ngClass]="{'ng-invalid-input': canValidate && model && (invalid | async)}"
         [id]="id? id:placeHolderIdentifier"
         [maxlength]="maxlength"
         class="form-control"
         [readOnly]="readonly"
         [name]="name"
         phoneMask
         />
  <input *ngIf="directive == 'digitOnly'"
         [type]="type"
         [placeholder]="placeholder"
         [(ngModel)]="value"
         [ngClass]="{'ng-invalid-input': canValidate && model && (invalid | async)}"
         [id]="id? id:placeHolderIdentifier"
         [maxlength]="maxlength"
         class="form-control"
         [readOnly]="readonly"
         [name]="name"
         digitOnly
         />

  <input *ngIf="!directive"
         [type]="type"
         [placeholder]="placeholder"
         [(ngModel)]="value"
         [ngClass]="{'ng-invalid-input': canValidate && model && (invalid | async)}"
         [id]="id? id:placeHolderIdentifier"
         [maxlength]="maxlength"
         class="form-control"
         [readOnly]="readonly"
         [name]="name"
         />

  <validation *ngIf="canValidate && model && invalid | async" [messages]="failures | async"></validation>
</div>

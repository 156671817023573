import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { Router } from '@angular/router';
import { UtilityHelper } from '../helpers/utils';
import { Position } from '../models/position.enum';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  enableAccounts:boolean;
  accountsBaseUrl:string;
  mfaOptionsUrl: string;

  constructor(
    private _sessionService: SessionService,
    private _router: Router,
    private configService:ConfigService
  ) {
    this.configService.configuration$.subscribe(config => {
      this.enableAccounts = config.enableAccountModule;
      this.accountsBaseUrl = config.accountsModuleBaseUrl;
      this.mfaOptionsUrl = config.mfaOptionsUrl;
    });
  }

  getUserDefaultPage() {

    if (this._sessionService.isUserMember() || this._sessionService.isOnlineMember()) {
      const state = this._sessionService.getSessionData();

      console.log('getUserDefaultPage - this._sessionService.sessionData', state)

      if (state && state.groupToJoin) {
        return '/mygroup/group';
      }
      return '/mygroup';
    }
    if (this._sessionService.isUserLeader()){
      return '/mygroup';
    }
    if (this._sessionService.isUserTrainer() || this._sessionService.isAssistantTrainer()) {
      if (!this._sessionService.UserHasClass()) {
        return '/myclass';
      }
      return '/mytraining';
    }

    if (this._sessionService.isRegionalDirector() || this._sessionService.isAdminViewOnly()) {
      return '/region/my-region';
    }

    if (this._sessionService.isArealPersonel()) {
      return '/area/my-area';
    }

    if (this._sessionService.isAdmin()) {
      return '/admin/study';
    }
  }

  gotoUserDefaultPage() {
    console.log("gotoUserDefaultPage");
    this._router.navigateByUrl(this.getUserDefaultPage(), { state: this._sessionService.getSessionData()});
  }

  gotoHome() {
    console.log("gotoHome");
    this._router.navigateByUrl("/");
  }

  gotoCreateAccount(){
    console.log("gotoCreateAccount");
    this._router.navigateByUrl("account/create");
  }

  gotoAccountsProfile(memberId){
    console.log("gotoAccountsProfile");
    const loggedUser = this._sessionService.getUserInfo();
    if(loggedUser.positionId == Position.HQAdmin){
      window.location.href =`${this.accountsBaseUrl}user-management/user/${memberId}`;
    }else{
      window.location.href = `${this.accountsBaseUrl}account/profile/${memberId}`;
    }
  }

  gotoStudySchedule() {
    console.log("gotoStudySchedule");
    this._router.navigateByUrl("calendar/mystudy-schedule");
  }

  gotoEventsSchedule() {
    console.log("gotoEventsSchedule");
    this._router.navigateByUrl("calendar/myevents-schedule");
  }


}

<div class="modal-content text-center">
    <div class="modal-header d-flex justify-content-center">
        <h2>{{'RemoveFromGroup' | translate}}</h2>
    </div>
    <div class="modal-body">
        <p>{{'RemoveOGMConfirm' | translate}}</p>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="red-btn modal-button" data-dismiss="modal" (click)="confirm()"> {{'YesRemove' | translate}}</button>
        <button type="button" class="blue-border-btn modal-button" (click)="closeMe()">{{'Cancel' | translate}}</button>
    </div>
</div>

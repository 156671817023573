import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import trimEnd from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { NotificationModel } from '../models/notification.model';
import { ConfigService } from './config.service';
import { MsalConfigurationService } from './msalConfiguration.service';
import { PersonMaterialStatusEnum } from '../models/enums/person-material-status.enum';
import { MaterialFileType } from '../models/enums/materialFileType.enum';
import { DownloadModel } from '../models/download.model';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private dismissNotificationPanelSubject = new Subject<void>()
  public dismissNotificationPanel$ = this.dismissNotificationPanelSubject.asObservable();

  testNotifications: NotificationModel[] =
    [{
      'materialId': 1258,
      'languageId': 1,
      'name': 'Alert with files',
      'description': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum',
      'tags': null,
      'isActive': true,
      'startDate': '2021-07-26T14:00:00',
      'creationTime': '2021-07-27T05:43:44.9026923',
      'creatorPersonId': 80057,
      'lastModificationTime': null,
      'lastModifierPersonId': null,
      'deletionTime': null,
      'deleterPersonId': null,
      'isDeleted': false,
      'links': [
        {
          name: 'P111_Basic Signs_SC_062014.pdf',
          materialId: 1258,
          materialFileTypeId: MaterialFileType.Text
        },
        {
          name: 'ChristmasHTL.2013.EN.pdf',
          materialId: 1258,
          materialFileTypeId: MaterialFileType.Text
        },
        {
          name: 'Google link',
          fileLocationURL: 'https://www.google.com',
          materialId: 1258,
          materialFileTypeId: MaterialFileType.Link
        }
      ]
    },
      {
        'materialId': 1258,
        'languageId': 2,
        'name': 'alert 11',
        'description': '<p><a href="www.captainamerica.com" target="_blank" title="">alert 11 for super powers </a></p>',
        'tags': null,
        'isActive': true,
        'startDate': '2021-07-26T14:00:00',
        'creationTime': '2021-07-27T05:43:44.9026923',
        'creatorPersonId': 80057,
        'lastModificationTime': null,
        'lastModifierPersonId': null,
        'deletionTime': null,
        'deleterPersonId': null,
        'isDeleted': false
      }, {
      'materialId': 1258,
      'languageId': 3,
      'name': 'alert 11',
      'description': '<p><a href="www.captainamerica.com" target="_blank" title="">alert 11 for super powers </a></p>',
      'tags': null,
      'isActive': true,
      'startDate': '2021-07-26T14:00:00',
      'creationTime': '2021-07-27T05:43:44.9026923',
      'creatorPersonId': 80057,
      'lastModificationTime': null,
      'lastModifierPersonId': null,
      'deletionTime': null,
      'deleterPersonId': null,
      'isDeleted': false
    }, {
      'materialId': 1258,
      'languageId': 4,
      'name': 'alert 11',
      'description': '<p><a href="www.captainamerica.com" target="_blank" title="">alert 11 for super powers </a></p>',
      'tags': null,
      'isActive': true,
      'startDate': '2021-07-26T14:00:00',
      'creationTime': '2021-07-27T05:43:44.9026923',
      'creatorPersonId': 80057,
      'lastModificationTime': null,
      'lastModifierPersonId': null,
      'deletionTime': null,
      'deleterPersonId': null,
      'isDeleted': false
    }, {
      'materialId': 1261,
      'languageId': 1,
      'name': 'alert 15',
      'description': '<p>alert 15</p>',
      'tags': null,
      'isActive': true,
      'startDate': '2021-07-26T14:00:00',
      'creationTime': '2021-07-27T05:46:24.6604189',
      'creatorPersonId': 80057,
      'lastModificationTime': null,
      'lastModifierPersonId': null,
      'deletionTime': null,
      'deleterPersonId': null,
      'isDeleted': false
    }, {
      'materialId': 1261,
      'languageId': 2,
      'name': 'alert 15',
      'description': '<p>alert 15</p>',
      'tags': null,
      'isActive': true,
      'startDate': '2021-07-26T14:00:00',
      'creationTime': '2021-07-27T05:46:24.6604189',
      'creatorPersonId': 80057,
      'lastModificationTime': null,
      'lastModifierPersonId': null,
      'deletionTime': null,
      'deleterPersonId': null,
      'isDeleted': false
    }, {
      'materialId': 1261,
      'languageId': 3,
      'name': 'alert 15',
      'description': '<p>alert 15</p>',
      'tags': null,
      'isActive': true,
      'startDate': '2021-07-26T14:00:00',
      'creationTime': '2021-07-27T05:46:24.6604189',
      'creatorPersonId': 80057,
      'lastModificationTime': null,
      'lastModifierPersonId': null,
      'deletionTime': null,
      'deleterPersonId': null,
      'isDeleted': false
    }, {
      'materialId': 1261,
      'languageId': 4,
      'name': 'alert 15',
      'description': '<p>alert 15</p>',
      'tags': null,
      'isActive': true,
      'startDate': '2021-07-26T14:00:00',
      'creationTime': '2021-07-27T05:46:24.6604189',
      'creatorPersonId': 80057,
      'lastModificationTime': null,
      'lastModifierPersonId': null,
      'deletionTime': null,
      'deleterPersonId': null,
      'isDeleted': false
    }, {
      'materialId': 1262,
      'languageId': 1,
      'name': 'alert 14',
      'description': '<p><a href="www.supercarblondie.com" title="">alert 14</a></p>',
      'tags': null,
      'isActive': true,
      'startDate': '2021-07-26T14:00:00',
      'creationTime': '2021-07-27T05:47:07.8725791',
      'creatorPersonId': 80057,
      'lastModificationTime': null,
      'lastModifierPersonId': null,
      'deletionTime': null,
      'deleterPersonId': null,
      'isDeleted': false
    }, {
      'materialId': 1262,
      'languageId': 2,
      'name': 'alert 14',
      'description': '<p><a href="www.supercarblondie.com" title="">alert 14</a></p>',
      'tags': null,
      'isActive': true,
      'startDate': '2021-07-26T14:00:00',
      'creationTime': '2021-07-27T05:47:07.8725791',
      'creatorPersonId': 80057,
      'lastModificationTime': null,
      'lastModifierPersonId': null,
      'deletionTime': null,
      'deleterPersonId': null,
      'isDeleted': false
    }, {
      'materialId': 1262,
      'languageId': 3,
      'name': 'alert 14',
      'description': '<p><a href="www.supercarblondie.com" title="">alert 14</a></p>',
      'tags': null,
      'isActive': true,
      'startDate': '2021-07-26T14:00:00',
      'creationTime': '2021-07-27T05:47:07.8725791',
      'creatorPersonId': 80057,
      'lastModificationTime': null,
      'lastModifierPersonId': null,
      'deletionTime': null,
      'deleterPersonId': null,
      'isDeleted': false
    }, {
      'materialId': 1262,
      'languageId': 4,
      'name': 'alert 14',
      'description': '<p><a href="www.supercarblondie.com" title="">alert 14</a></p>',
      'tags': null,
      'isActive': true,
      'startDate': '2021-07-26T14:00:00',
      'creationTime': '2021-07-27T05:47:07.8725791',
      'creatorPersonId': 80057,
      'lastModificationTime': null,
      'lastModifierPersonId': null,
      'deletionTime': null,
      'deleterPersonId': null,
      'isDeleted': false
    }, {
      'materialId': 1246,
      'languageId': 1,
      'name': 'hi',
      'description': '<p>sdfsfsdf</p>',
      'tags': null,
      'isActive': true,
      'startDate': '2021-07-15T14:00:00',
      'creationTime': '2021-07-15T20:40:44.7280614',
      'creatorPersonId': 80057,
      'lastModificationTime': null,
      'lastModifierPersonId': null,
      'deletionTime': null,
      'deleterPersonId': null,
      'isDeleted': false
    }, {
      'materialId': 1246,
      'languageId': 2,
      'name': 'hi',
      'description': '<p>sdfsfsdf</p>',
      'tags': null,
      'isActive': true,
      'startDate': '2021-07-15T14:00:00',
      'creationTime': '2021-07-15T20:40:44.7280614',
      'creatorPersonId': 80057,
      'lastModificationTime': null,
      'lastModifierPersonId': null,
      'deletionTime': null,
      'deleterPersonId': null,
      'isDeleted': false
    }, {
      'materialId': 1246,
      'languageId': 3,
      'name': 'hi',
      'description': '<p>sdfsfsdf</p>',
      'tags': null,
      'isActive': true,
      'startDate': '2021-07-15T14:00:00',
      'creationTime': '2021-07-15T20:40:44.7280614',
      'creatorPersonId': 80057,
      'lastModificationTime': null,
      'lastModifierPersonId': null,
      'deletionTime': null,
      'deleterPersonId': null,
      'isDeleted': false
    }, {
      'materialId': 1246,
      'languageId': 4,
      'name': 'hi',
      'description': '<p>sdfsfsdf</p>',
      'tags': null,
      'isActive': true,
      'startDate': '2021-07-15T14:00:00',
      'creationTime': '2021-07-15T20:40:44.7280614',
      'creatorPersonId': 80057,
      'lastModificationTime': null,
      'lastModifierPersonId': null,
      'deletionTime': null,
      'deleterPersonId': null,
      'isDeleted': false
    }];

  private downloadEndPoint = '';
  private _notificationEndpoint;


  constructor(
    private _http: HttpClient,
    private configService: ConfigService
  ) {
    this.configService.configuration$.subscribe(config => {
      this._notificationEndpoint = `${config.apiEndpoint}notification`;
      this.downloadEndPoint = config.resourceUploaderApiEndpoint;
    });
  }

  getNotifications(languageId, mock = true, personMaterialStatus:PersonMaterialStatusEnum[] = []): Observable<NotificationModel[]> {
    if (mock) {
      return of(this.testNotifications);
    }
    let joinedStatuses = personMaterialStatus.map(status => `personMaterialStatusEnum=${status}`).join('&')
    let personMaterialStatusQuery = personMaterialStatus.length ? `&${joinedStatuses}` : ''
    return this._http.get<NotificationModel[]>(`${this._notificationEndpoint}?languageId=${languageId}${personMaterialStatusQuery}`,
      {
        headers: {
          'no-spinner': '1'
        }
      }
    );
  }

  getNotificationsCount(languageId, mock = true, personMaterialStatus:PersonMaterialStatusEnum[] = []): Observable<number>{
    if (mock) {
      return of(this.testNotifications.length);
    }
    let joinedStatuses = personMaterialStatus.map(status => `personMaterialStatusEnum=${status}`).join('&')
    let personMaterialStatusQuery = personMaterialStatus.length ? `&${joinedStatuses}` : ''
    return this._http.get<number>(`${this._notificationEndpoint}/count?languageId=${languageId}${personMaterialStatusQuery}`,
      {
        headers: {
          'no-spinner': '1'
        }
      }
    );
  }

  downloadLinkFile(materialId: number, fileLocationURL: string) {
    return this._http.post(
      `${this.downloadEndPoint}/api/material/download-link`,
      {
        materialId: materialId,
        fileLocationURL: fileLocationURL
      },
      {
        reportProgress: true,
        observe: 'events',
        headers: {
          'no-spinner': '1'
        }
      });
  }

  downloadFilesZip(downloadModels: DownloadModel[]) {
		return this._http.post(
			`${this.downloadEndPoint}/api/material/downloadall`, downloadModels,  {
				reportProgress: true,
				observe: 'events',
				responseType: 'blob',
        headers: {
          'no-spinner': '1'
        }
			});
	}

  dismissNotificationPanel() {
    this.dismissNotificationPanelSubject.next()
  }

  postNotificationStatus(materialId: number, personMaterialStatus:PersonMaterialStatusEnum = null) {
    return this._http.post(`${this._notificationEndpoint}/status`, {
      materialId,
      status: personMaterialStatus,
    })
  }
}

import { Inject, Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { Router } from '@angular/router';
import { RoutingService } from './routing.service';
import { ConfigService } from './config.service';
import { RedirectRequest, PopupRequest, InteractionType, AuthenticationResult } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AccountService } from './account.service';
import { User } from '../models/user.model';

@Injectable()
export class AuthService {

  accountsBaseUrl: string;
  enableAccounts:boolean;
  constructor(
    private _sessionService: SessionService,
    private _router: Router,
    private _routingService: RoutingService,
    private configService: ConfigService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalauthService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private accountService: AccountService) {



      this.configService.configuration$.subscribe(config => {
        this.enableAccounts = config.enableAccountModule;
        this.accountsBaseUrl = config.accountsModuleBaseUrl;
      });
    }

  redirectUserToDefaulPage() { // async
    const user = this._sessionService.getUserInfo();
    console.log("completeAuthentication: ",user);
    if(!user){
      if(!this._sessionService.isLoadingUser()){
        this._routingService.gotoCreateAccount();
        return;
      }
    }
    else {

      if (this.needToRedirectToCreateAccountPageForExistingPerson(user))
      {
        this._routingService.gotoCreateAccount();
        return;
      }

      if (user.isProfileCompleted) {
        this._routingService.gotoUserDefaultPage();
      } else {
        this.redirectToProfileThenToDefault();
      }
    }
  }

  needToRedirectToCreateAccountPageForExistingPerson(person : User)
  {
    // email consent feature launched, person has never login, and person is created by another user (CA)
    return person.isMarketingEmailConsentFeatureLaunched && !person.firstLoginTime && person.creatorPersonId > 0;
  }


  redirectToProfileThenToDefault(): void {
    const redirect = this._routingService.getUserDefaultPage();
    this._router.navigate(['/account/profile'], { queryParams: { redirect: redirect }, state: this._sessionService.getSessionData() });
  }

  logOut() {
    this.triggerAuthentication();
    //this._routingService.gotoHome();
  }

  idleLogout() {
    this.b2cLogout();
  }

  triggerAuthentication(): void {
    this._sessionService.clearSession();
  }

  b2cLogin(userFlowRequest?: RedirectRequest | PopupRequest) {
    console.log("b2cLogin:",this.msalGuardConfig);
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalauthService.loginPopup({...this.msalGuardConfig.authRequest, ...userFlowRequest} as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalauthService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalauthService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalauthService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest){
        console.log("msalauthService.loginRedirect:",this.msalGuardConfig.authRequest);
        this.msalauthService.loginRedirect({...this.msalGuardConfig.authRequest, ...userFlowRequest} as RedirectRequest);
      } else {
        this.msalauthService.loginRedirect(userFlowRequest);
      }
    }
  }

  b2cLogout(){
      this._router.navigateByUrl('account/logout');
  }
}

import { Injectable } from '@angular/core';
import { English, SimplifiedChinese, TraditionChinese, Spanish } from 'src/app/shared/constants/languageConstants';
import * as labelsModule from "./help.freshDeskLabels.json";

function getWindow(): any {
    return window;
}

const scriptId:string = "helpwidgetscript";
const widgetId:string = "60000002840";
const scriptUrl = `https://widget.freshworks.com/widgets/${widgetId}.js`;

@Injectable()
export class HelpService {

    private window: any;

    labels: any = (labelsModule as any).default;

    constructor() {
        this.window = getWindow();
    }

    load(language: string) {
        let hasToAddScript = false;

        let script = <HTMLScriptElement> document.getElementById(scriptId)
        if(!script) {
            hasToAddScript = true;
            script = document.createElement('script');
        }

        const locale = this.getFreshDeskLocale(language);

        this.updateScript(script, locale);

        if(hasToAddScript) {
            document.body.append(script);
        }

        this.FreshworksWidget("setLabels", this.labels);
    }


    private updateScript(script: HTMLScriptElement, locale: string) {
        const window = this.window;
        script.id = scriptId;
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = scriptUrl;
        window.fwSettings = {
            'widget_id': widgetId,
            'locale': locale,
        };

       window.FreshworksWidget || function() {
            if ("function" != typeof window.FreshworksWidget) {
              let n = function() {
                n['q'].push(arguments)
              };
              n['q'] = [], window.FreshworksWidget = n
            }
          }();

        script.onerror = function (event) {
            console.log("error Onerror.: ", event)
        };
        return script;
    }

    private getFreshDeskLocale(languageCulture: string) {
        switch(languageCulture) {
            case SimplifiedChinese:
                return "zh-CN";
            case TraditionChinese:
                return "zh-TW";
            case Spanish:
              return "es"
        }
        return languageCulture? languageCulture: "en";
    }

    get FreshworksWidget() {
        const window = getWindow();
        return window.FreshworksWidget;
    }
}

export class FileUploadResult {
    state: FileUploadState
    filePath: string
    error: string

}

export enum FileUploadState {
    Unknown,
    Success,
    Failed
}
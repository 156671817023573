<div class=""  tabindex="-1" >
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content text-center">
      <div class="modal-header d-flex justify-content-center bacground">
        <h2>{{member.fullName}}</h2>
      </div>
      <div class="modal-body bacground">
        <img src="../../../assets/images/flags/{{member.country | countryFlag}}.png" alt="{{member.country.countryName}}" class="flag">  {{member.country.countryName}}<br><br>
        <p>{{member.aboutMe}}</p>
      </div>
      <div class="modal-footer d-flex justify-content-center bacground">
        <button type="button" class="blue-btn modal-button" (click)="closeMe()">x &nbsp; {{'Close' | translate}}</button>
      </div>
    </div>
  </div>
</div>

export const OnlineMember = "Online Member";
export const GroupMember = "Online Group Member";
export const GroupLeader = "Online Group Leader";
export const Trainer = "Online Trainer";
export const AssistantTrainer = "Assistant Online Trainer";
export const AreaPersonnel = "Online Area Personnel";
export const RegionalDirector = "Online Regional Director";
export const LeaderDesignate = "Online Group Leader Designate";
export const LeaderCandidate = "Online Group Leader Candidate";
export const Admin = "HQ Admin";

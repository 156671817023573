<div class="modal-content d-flex justify-content-center text-center">
    <div class="modal-header flex-column justify-content-center align-items-center d-flex">
        <h3 class="modal-title">{{group.meetingNickName}}:</h3>
    </div>
    <div class="modal-body">
        <p class="gray-text">
            {{'GL' | translate}}: {{group.leader}} &bull;
            {{'MeetingType' | translate}}: {{group.meetingType}} &bull;
            {{'Lang' | translate}}: {{group.spokenLanguage}} &bull;
            <span *ngIf="group.ageRange">{{'AgeRange' | translate}}: {{group.ageRange}}</span> &bull;</p>
        <div class="d-flex align-items-center justify-content-center">
            <p class="switchgroup-time">{{'LeadersMeeting' | translate}}: {{group.meetingDayOfWeekConverted}}, {{group.meetingTimeConverted}}
                {{selectedTimeZone}}</p>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="blue-btn modal-button" (click)="select()" data-dismiss="modal">{{'RequestJoin' | translate}}</button>
        <button type="button" class="blue-btn modal-button" (click)="cancel()" data-dismiss="modal">{{'Cancel' | translate}}</button>
    </div>
</div>

import { Injectable } from "@angular/core";
import countries, { CountryTimezone } from './country-lookup-data';

@Injectable({
    providedIn: 'root'
  })
export class CountryLookupService {

    getCountries(): CountryTimezone[] {
        return countries;
    }

    getPhoneCodeByCountryCode(countryCode: string) : string {
        for (let country of countries) {
            if(country.timezones.find(tz => tz.displayName.indexOf('(' + countryCode + ')') > 0 )) {
                return country.phoneCode.toString();
            }
        }
        return null;
    }

}
  
<nav class="footer-bar row">
    <div class="col-12 col-md-1 footer-links d-flex justify-content-md-start justify-content-center align-items-center">
      <a class="navbar-brand footer-brand mr-0 mr-md" href="https://www.bsfinternational.org/" target="_blank"><img src="../../../../assets/images/bsf-icon.png" alt="Bible Study Fellowship Online"/></a>
    </div> 
    <div class="col-12 col-md-9 pt-2 d-flex justify-content-md-start justify-content-center align-items-center">
      <ul class="links-list pl-0 pl-md">
        <li class="ml-md-3 noWrap"><a href="https://www.bsfinternational.org/terms-of-use" target="_blank">{{'TermsConditions' | translate}}</a></li>
        <li class="ml-md-3 noWrap"><a href="https://www.bsfinternational.org/privacy-policy" target="_blank">{{'PrivacyPolicy' | translate}}</a></li>
      </ul>
     </div> 
    <div class="col-12 col-md-2 d-flex justify-content-md-end justify-content-center align-items-center">
      <a href="https://www.instagram.com/wearebsf/" target="_blank"><img class="social" src="../../../../assets/images/social/instagram.png" alt="instagram"></a>&nbsp;&nbsp;
      <a href="https://www.facebook.com/BibleStudyFellowship/" target="_blank"><img class="social" src="../../../../assets/images/social/facebook.png" alt="facebook"></a>
    </div>
    <div class="col-12">
      <div id="footer" class="noWrap">
        <small style="color:#ffffff;">Build:#__APPVERSION__</small>
      </div>
      &nbsp;<br />
      &nbsp;
    </div>
  </nav>

<div class="row p-5 header-image">
    <div class="blank"></div>
  </div>
  
  <div class="text-center block-1">
    <div class="row text-center py-5">
      <div class="col-12 row">
        <div class="col-2"></div>
        <div class="col-8">
          <h1>Coming Soon</h1>
          <hr><br>
          <p>This is the message content. Aliquam eu dignissim nunc, a tempus tortor. Morbi lobortis, lorem vitae pulvinar laoreet, lacus eros tempus sem, at vulputate turpis tortor eget justo. Duis pharetra quam in nisi fermentum eleifend. Mauris dictum quam nec tempus congue. Phasellus tempus felis libero, at consequat sem convallis id. Nullam id interdum nulla.</p><br>
          <p class="mb-0">Days before launch:</p><br>
          <h1>13 days</h1>
          <hr>
          <p>from September 12, 2020</p>
        </div>
        <div class="col-2"></div>
      </div>
    </div>
    <div class="row">
     
    </div>
  </div>
  
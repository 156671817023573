<nav class="footer-bar-light row">

  <div *ngIf="!shouldEnterPasswordToAccess()" class="col-12 col-md-4 footer-links d-flex justify-content-md-start justify-content-center align-items-center">
    <div class="row">
      <div class="col-12 d-flex justify-content-center justify-content-md-start">
        <a class="navbar-brand footer-brand mr-0 mr-md" href="https://www.bsfinternational.org/" target="_blank"><img src="assets/images/bsf-icon.png" alt="Bible Study Fellowship" /></a>
      </div>
      <div class="col-12 d-flex justify-content-center justify-content-md-start pb-3 pb-md-0 align-items-center">
        <small class="gray-text text-nowrap">&copy; 2024 Bible Study Fellowship</small>
      </div>
    </div>
  </div>

  <div *ngIf="!shouldEnterPasswordToAccess()" class="col-12 col-md-4 pt-2 d-flex justify-content-center align-items-center text-nowrap">
    <ul class="d-sm-inline-flex language-list pl-0">
      <li class="ml-0 ml-sm-3"><i class="fas fa-globe"></i>&nbsp;{{'Lang' | translate}}:</li>
      <li class="ml-0 ml-sm-3"><a [routerLink]="[]" (click)="changeLang('en')">English</a></li>
      <li class="ml-0 ml-sm-3"><a [routerLink]="[]" (click)="changeLang('sp')">Español</a></li>
      <li class="ml-0 ml-sm-3"><a [routerLink]="[]" (click)="changeLang('sch')">简体中文</a></li>
      <li class="ml-0 ml-sm-3"><a [routerLink]="[]" (click)="changeLang('tch')">繁體中文</a></li>
    </ul>
  </div>

  <div [ngClass]="!shouldEnterPasswordToAccess() ? 'col-md-4' : ''" class="col-12 d-flex justify-content-center justify-content-md-end align-items-end">
    <div class="row">
      <div class="col-12 d-flex justify-content-center justify-content-md-end pb-5 pb-md-0">
        <a href="https://www.instagram.com/wearebsf/" target="_blank"><img class="social" src="assets/images/social/instagram-dark.png" alt="instagram"></a>&nbsp;&nbsp;
        <a href="https://www.facebook.com/BibleStudyFellowship/" target="_blank"><img class="social" src="assets/images/social/facebook-dark.png" alt="facebook"></a>
      </div>
      <div class="col-12 d-flex justify-content-center justify-content-md-end align-items-center text-right">
        <a href="https://www.bsfinternational.org/terms-of-use" target="_blank" style="text-decoration: none"><small class="gray-text text-nowrap">{{'TermsConditions' | translate}}</small></a> &nbsp;&nbsp;&nbsp;
        <a href="https://www.bsfinternational.org/privacy-policy" target="_blank" style="text-decoration: none"><small class="gray-text text-nowrap">{{'PrivacyPolicy' | translate}}</small></a>
      </div>
    </div>
  </div>

  <div *ngIf="!shouldEnterPasswordToAccess()" class="row">
    <div class="col-12 d-flex justify-content-center text-center text-md-left">
      <div id="footer" class="noWrap">
        <small class="gray-text" style="padding-left:15px">Build:#__APPVERSION__</small>
      </div>
      <span style="padding-bottom:50px">&nbsp;</span>
    </div>
  </div>

</nav>


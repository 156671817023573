import { Injectable } from '@angular/core';

@Injectable()
export class FooterService {
    public showDefault = false;

    displayDefault() {
        this.showDefault = true;
    }

    hideDefault() {
        this.showDefault = false;
    }
}
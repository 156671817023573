import { PipeTransform, Pipe } from "@angular/core";
import { Country } from '../models/country.model';

@Pipe({
  name: 'countryFlag'
})
export class CountryFlagPipe implements PipeTransform {
  transform(country: Country) {
    if (country && country.countryName) {
      return country.countryName.toLocaleUpperCase();
    }
    return '_DEFAULT';
  }
}

import { Injectable} from "@angular/core";
import { AnnouncementBarService } from 'src/app/admin/common/services/announcement.service';
import { AnnounceMentBar } from 'src/app/admin/common/models/announcement-bar.model';
import moment from 'moment';
import { TimeHelper } from "src/app/shared/helpers/timeHelper";

@Injectable()
export class SystemMaintenanceService {

    meetingDurationMinutes = 90;
    weeklySystemAnnouncements: AnnounceMentBar[];

    constructor(
        private _announcementService: AnnouncementBarService
    ) { }

    loadWeeklySystemAnnouncements() {
        this._announcementService.getWeeklySystemAnnouncements().subscribe(x => {
            this.weeklySystemAnnouncements = x;
        });
    }

    public checkDateTimeConflict(dayofWeek: number,
        startTime: string, timeZone: string) : string {

        const meetingStartTime = TimeHelper.getDateFromDayOfWeekAndTime(dayofWeek,
            startTime, timeZone);

        const systemEventTimeZone = "UTC";

        for(let systemEvent  of this.weeklySystemAnnouncements) {

            let eventStartTime = TimeHelper.getDateFromDayOfWeekAndTime(systemEvent.dayofWeek,
                systemEvent.startTime, systemEventTimeZone);
            const eventEndTime = TimeHelper.getDateFromDayOfWeekAndTime(systemEvent.dayofWeek,
                    systemEvent.endTime, systemEventTimeZone);

            // we only check meeting start time here, [] - inclusive 
            if(meetingStartTime.isBetween(eventStartTime, eventEndTime, undefined, '[]')) {
                return this.getSystemMaintenanceOverlapMessage(eventStartTime, eventEndTime,
                    timeZone);
            }
        }
    }

    private getSystemMaintenanceOverlapMessage(
        startTime: moment.Moment,
        endTime: moment.Moment, timezone: string) {
          return `System is scheduled for weekly maintenance from
            ${startTime.format("dddd")} ${startTime.tz(timezone).format("LT")} to ${endTime.tz(timezone).format("LT zz")}.
            Please re-select Meeting Time to avoid the scheduled maintenance period.`
    }

}

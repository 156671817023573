<audio controls
  [src]="audioLink"
  (ended)="audioEnded()"
  (pause)="audioPaused()"
  (play)="audioStarted()"
  class="pb-3" id="audio-section" preload="none" #audiosection>
  <source src="audioLink" type="audio/wav">
    {{'NoAudioSupport' | translate}}
</audio>
  <p *ngIf="isiOS()"><a href="javascript:void(0);" (click)="loadFile(audio)" >{{'Download' | translate}}</a></p>



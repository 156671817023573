import Compressor from 'compressorjs';

export class ImageCompressionOptions {
    quality: number;
    maxWidth: number;
    maxHeight: number;
}

export class ImageHelper {

    static compress(file: File, options: ImageCompressionOptions ) {
        return new Promise ((resolve, reject) => {
            new Compressor(file, {
                quality: options.quality,
                maxWidth: options.maxWidth,
                maxHeight: options.maxWidth,
                success(blob: Blob) {
                    resolve(blob);                    
                },
                error(err: Error) {
                    reject(err);
                },
              });
          })
    }

}


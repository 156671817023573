import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Clipboard } from "@angular/cdk/clipboard"

@Component({
  selector: 'app-copy-link-modal',
  templateUrl: './copy-link-modal.component.html',
  styleUrls: ['./copy-link-modal.component.css']
})
export class CopyLinkModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CopyLinkModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private clipboard: Clipboard) {

    if (!!data?.link)
      this.clipboard.copy(data.link);

  }

  ngOnInit(): void {
  }

  closeMe(): void {
    this.dialogRef.close();
  }

}

<div class="modal-content text-center px-3">
    <div class="modal-header d-flex justify-content-center">
      <h2>{{'InviteFriend' | translate}}</h2>
    </div>
    <div class="modal-body">
      <p>{{'SendInvite' | translate}}:</p>
      <form>
        <input
          type="text"
          name="name"
          [(ngModel)]="model.name"
          class="form-control"
          placeholder="{{'Name' | translate}}"
        >
        <input
          type="email"
          name="email"
          [(ngModel)]="model.email"
          class="form-control mt-2"
          placeholder="{{'Email' | translate}}"
        >
      </form>
    </div>
    <div class="modal-footer d-flex justify-content-center">
      <button
        type="button"
        class="blue-border-btn modal-button"
        (click)="closeMe()"
      >
        {{'Cancel' | translate}}
      </button>
      <button
        type="button"
        class="blue-btn modal-button"
        (click)="confirm()"
      >
        {{'Ok' | translate}}
      </button>
    </div>
  </div>
  
import { Component, OnInit, Input } from '@angular/core';
import { Gender } from '../../models/gender.enum';

@Component({
  selector: 'app-image-placeholder',
  templateUrl: './image-placeholder.component.html',
  styleUrls: ['./image-placeholder.component.css']
})
export class ImagePlaceholderComponent implements OnInit {

  @Input()
  genderId: number

  @Input()
  class: string;

  @Input()
  ngclass: {[x: string]: boolean}
  
  constructor() { }

  ngOnInit() {
  }

  get genders() {
    return Gender;
  }

}

import { Position } from 'src/app/shared/models/position.enum';
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { RoutingService } from "../services/routing.service";
import { SessionService } from "../services/session.service";

@Injectable()
export class StudyScheduleGuardService  {

    constructor(
        private _sessionService: SessionService,
        private _routingService: RoutingService,
        private authService: AuthService) {

    }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
      const isAuthenticated = this._sessionService.isAuthenticated();
      if (!isAuthenticated) {
        this.authService.b2cLogin();
      }
      const loggedUser = this._sessionService.getUserInfo();
      let canViewLessonSchedule = false;
      if (!loggedUser) {
        this._routingService.gotoHome();
      }else{
        canViewLessonSchedule = (!loggedUser.hasOnlineClass && !loggedUser.hasOnlineGroup);
      }


      return isAuthenticated && canViewLessonSchedule;
    }
}

import { Validator, NG_VALIDATORS, FormControl } from "@angular/forms";
import { Directive, OnInit, Input } from "@angular/core";

@Directive({
  selector: "[gteValidator]",
  providers: [
    { provide: NG_VALIDATORS, useExisting: GteValidatorDirective, multi: true },
  ],
})
export class GteValidatorDirective implements Validator {
  @Input("minValue") minValue: number = 1;

  validate(control: FormControl) {
    return (control.value && +control.value < +this.minValue)
        ? { minValue: true }
        : null
  }
}

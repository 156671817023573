
<div class="row align-items-baseline" *ngFor="let selectedItem of selectedItems; let i = index">
    <div class="col form-group">            
        <div class="row">
            <div class="col">
                <div class="item-label float-left" style="width: 7rem;">
                    {{ itemLabel + ' ' + (i + 1) + ':'}}
                </div>
                <div class="form-control float-left" 
                    style="width: 20rem;" name="item{{selectedItem.id}}">
                    {{ selectedItem.displayName }}
                    <span *ngIf="!selectedItem.flag" 
                        class="k-icon k-font-icon k-clear-value k-i-close k-i-x float-right remove-item-icon" 
                        title="clear"
                        (click)="onRemoveItemClick(selectedItem)">
                    </span>
                </div>  
            </div>                    
        </div>            
        
        <div *ngIf="selectedItem.flag && selectedItem.showReadOnlyMessage && readOnlyItemMessage" class="row warning-text pt-2">            
            <div class="col">
                {{ readOnlyItemMessage }}            
            </div>                    
        </div>            
    </div>
</div>

<div class="row" *ngIf="!reachedMaxNumberOfItems">
    <div class="col form-group">
        
            <label for="assistant_trainer" class="item-label" style="width: 7rem;">
                {{newItemLabel + ' ' + (selectedItems.length + 1) + ' :'}}
            </label>
            <kendo-combobox [data]="filteredItems" [valueField]="'id'" [textField]="'displayName'"
                placeholder="{{ addNewInputPlaceholder }}" 
                class="item-list-editor-new-combobox" 
                style="width: 20rem; font-size: 1rem;"
                name="assistant_trainer" 
                [fillMode]="flat"
                [filterable]="true"
                (filterChange)="handleFilter($event)"            
                [(ngModel)]="newItem" >
            </kendo-combobox>
            <div class="invalid-feedback">
                {{'NameAOT' | translate}}
            </div>
        
    </div>
</div>

<div class="row" >
    <div class="col">
        <button type="button" *ngIf="!reachedMaxNumberOfItems"
            class="blue-border-btn btn mr-1" 
            (click)="onAddItemClick()" [disabled]="!newItem">
            {{addButtonCaption}}
        </button>
        <span *ngIf="reachedMaxNumberOfItems" class="warning-text">
            {{maxNumberWarningText}}
        </span>
    </div>
</div>
import { RoutingService } from './../services/routing.service';
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';
import { User } from '../models/user.model';

@Injectable()
export class CreateAccountGuardService  {
  constructor(
    private _sessionService: SessionService,
    private authService: AuthService,
    private _routingService: RoutingService    
  ) {    
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const isAuthenticated = this._sessionService.isAuthenticated();

    if (!isAuthenticated) {
      this.authService.b2cLogin();
    }

    const userInfo = this._sessionService.getUserInfo();
    
    if (userInfo) {
      if (this.needToRedirectToCreateAccountPageForExistingPerson(userInfo))
      {
        return true;  
      }   
      else
      {
        this._routingService.gotoHome();   
      }
    }

    return isAuthenticated && !userInfo;
  }

  needToRedirectToCreateAccountPageForExistingPerson(person : User)
  {
    // email consent feature launched, person has never login, and person is created by another user (CA)
    return person.isMarketingEmailConsentFeatureLaunched && !person.firstLoginTime && person.creatorPersonId > 0;
  }

}

<div class="modal-content text-center">    
    <div class="modal-header d-flex align-items-center justify-content-center">
        <i class="fas fa-check-circle pr-3 check-circle-icon"></i>
        <h3>{{'LinkCopied' | translate}}</h3>
    </div>
    <div class="modal-body">
        <a [href]="data.link" target="_blank">
            {{data.link}}
        </a>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button type="button" 
            class="blue-btn modal-button" 
            data-dismiss="modal" (click)="closeMe()">
            {{'Done' | translate}}
        </button>
    </div>    
</div>
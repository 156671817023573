<select class="form-control" id="meeting-time" [(ngModel)]='time' [disabled]="disable" (change)="change($event)">
    <option *ngIf="placeHolder" value="">{{placeHolder}}</option>
    <option [value]="0">12:00 {{'AM' | translate}} - {{'AM' | translate}}</option>
    <option [value]="1">1:00 {{'AM' | translate}} - 2:00 {{'AM' | translate}}</option>
    <option [value]="2">2:00 {{'AM' | translate}} - 3:00 {{'AM' | translate}}</option>
    <option [value]="3">3:00 {{'AM' | translate}} - 4:00 {{'AM' | translate}}</option>
    <option [value]="4">4:00 {{'AM' | translate}} - 5:00 {{'AM' | translate}}</option>
    <option [value]="5">5:00 {{'AM' | translate}} - 6:00 {{'AM' | translate}}</option>
    <option [value]="6">6:00 {{'AM' | translate}} - 7:00 {{'AM' | translate}}</option>
    <option [value]="7">7:00 {{'AM' | translate}} - 8:00 {{'AM' | translate}}</option>
    <option [value]="8">8:00 {{'AM' | translate}} - 9:00 {{'AM' | translate}}</option>
    <option [value]="9">9:00 {{'AM' | translate}} - 10:00 {{'AM' | translate}}</option>
    <option [value]="10">10:00 {{'AM' | translate}} - 11:00 {{'AM' | translate}}</option>
    <option [value]="11">11:00 {{'AM' | translate}} - 12:00 {{'PM' | translate}}</option>
    <option [value]="12">12:00 {{'PM' | translate}} - 1:00 {{'PM' | translate}}</option>
    <option [value]="13">1:00 {{'PM' | translate}} - 2:00 {{'PM' | translate}}</option>
    <option [value]="14">2:00 {{'PM' | translate}} - 3:00 {{'PM' | translate}}</option>
    <option [value]="15">3:00 {{'PM' | translate}} - 4:00 {{'PM' | translate}}</option>
    <option [value]="16">4:00 {{'PM' | translate}} - 5:00 {{'PM' | translate}}</option>
    <option [value]="17">5:00 {{'PM' | translate}} - 6:00 {{'PM' | translate}}</option>
    <option [value]="18">6:00 {{'PM' | translate}} - 7:00 {{'PM' | translate}}</option>
    <option [value]="19">7:00 {{'PM' | translate}} - 8:00 {{'PM' | translate}}</option>
    <option [value]="20">8:00 {{'PM' | translate}} - 9:00 {{'PM' | translate}}</option>
    <option [value]="21">9:00 {{'PM' | translate}} - 10:00 {{'PM' | translate}}</option>
    <option [value]="22">10:00 {{'PM' | translate}} - 11:00 {{'PM' | translate}}</option>
    <option [value]="23">11:00 {{'PM' | translate}} - 12:00 {{'AM' | translate}}</option>
</select>

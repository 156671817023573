import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DiscussionMeeting } from "../../../../shared/models/discussionMeeting.model";
import { User } from "../../../../user-management/common/models/user.model";
import { TimeHelper } from 'src/app/shared/helpers/timeHelper';

@Component({
  selector: 'regroup-user-dialog',
  templateUrl: './regroup.user.dialog.component.html',
  styleUrls: ['./regroup.user.dialog.component.css']
})
export class RegroupUserDialogComponent implements OnInit {
  group: DiscussionMeeting;
  user: User;
  groupTimeZoneCode: string;

  constructor(
    public dialogRef: MatDialogRef<RegroupUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.group = this.data.selectedMeeting;
    this.groupTimeZoneCode = TimeHelper.getZone(this.group.meetingTimeZone);
    this.user = this.data.user;
  }

  select(): void {
    this.dialogRef.close({ action: 'proceed' });
  }

  cancel(): void {
    this.dialogRef.close({ action: 'cancel' });
  }
}

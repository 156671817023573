import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-olmswitch-notification-modal',
  templateUrl: './olmswitch-notification-modal.component.html',
  styleUrls: ['./olmswitch-notification-modal.component.css']
})
export class OLMSwitchNotificationModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<OLMSwitchNotificationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {

  }

  select(): void {
    this.dialogRef.close({action: 'proceed'});
  }
}

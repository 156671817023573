import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class ApiVersionInterceptor implements HttpInterceptor {

  apiVersion: string = "__APPVERSION__";

  constructor(
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
        setHeaders: {
            "X-Api-Version": this.apiVersion
        }
    });      
      
    return next.handle(request);
  }
}
import { CultureService } from 'src/app/shared/services/culture.service';
import { Configuration } from './../shared/models/configuration.model';
import { ConfigService } from './../shared/services/config.service';
import { SpinnerService } from './../shared/widgets/spinner/spinner.service';
import { AccountService } from './../shared/services/account.service';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Languages, English, getLanguageCulture } from '../shared/constants/languageConstants';
import { Title } from '@angular/platform-browser';
import { SessionService } from '../shared/services/session.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AppSettingsService } from '../shared/services/appSettings.service';
import { Router, NavigationEnd } from '@angular/router';
import { HelpService } from 'src/app/shared/services/help.service';
import { IdleTimeoutService } from 'src/app/shared/services/idleTimeout.service';
import { Observable, Subject, Subscription, combineLatest, of } from 'rxjs';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus, EventMessage, EventType, AuthenticationResult, AuthError } from '@azure/msal-browser';
import { takeUntil,filter } from 'rxjs/operators';
import { MfaConfigurationModel } from '../shared/models/mfa-configuration.model';
import { User } from '../shared/models/user.model';
import { forIn } from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';

declare var gtag: Function;
interface IdTokenClaims extends AuthenticationResult {
  idTokenClaims: {
    acr?: string
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  title = 'angular-idle-timeout';
  configuration:Configuration;
  public isSafari = window.navigator.userAgent.toLowerCase().indexOf('safari/') > -1 && window.navigator.userAgent.toLowerCase().indexOf('chrome') < 0;
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private _titleService: Title,
    private sessionService: SessionService,
    private idleTimeoutService: IdleTimeoutService,
    private bsfAuthService: AuthService,
    private appsettingsService: AppSettingsService,
    public router: Router,
    private accountService: AccountService,
    private configService:ConfigService,
    private cultureService:CultureService,
    private msalAuthService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private modalService: BsModalService
    ) {
  }

  ngOnInit(): void {
    this.initB2c();
    this.configService.configuration$.subscribe(config => {
      this.configuration = config;
    });

    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
          gtag('config', 'UA-167814048-1',
          {
            'page_path': event.urlAfterRedirects
          });
       }
    });

    this.appsettingsService.loadAppSettings()
      .subscribe(resolve => {
        const settings = this.appsettingsService._appSettings;
        this.idleInit(settings.startTimeoutCountAfter, settings.idleTimeout);
      });

    this._titleService.setTitle("Bible Study Fellowship Online");

    this.loadLanguagues(false);

    if(window && !this.configuration.showConsoleLog){
      window.console.log=function(){};
    }

    this.registerScrollToTheTop(['/group']);

  }

  private registerScrollToTheTop(urls: string[]) {    

    // navigate to the top of the page. 
    // if this page was opened from the bottom link on previous (home) page then 
    // this page will be scrolled to the bottom as well. this doesn't work for iOS
    // looks similar to the bug: https://bugs.webkit.org/show_bug.cgi?id=238497       
    
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        const match = urls.some(suffix => event.url.endsWith(suffix));
        if(match) {
          window.scrollTo(0, 0);
          console.log('scroll to the top', event.url);
        }        
      }
    });
  }

  ngOnDestroy():void{
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  // handleForgotPassword(){
  //   const msalErrorDescription = this.sessionService.getMsalErrorDescription();
  //   if (msalErrorDescription) {
  //     if (msalErrorDescription.indexOf("AADB2C90118") > -1) {
  //       this.spinnerService.show();
  //       this.msalService.loginRedirect({
  //         scopes: [...this.configuration.b2cConfiguration.apiConfig.scopes],
  //         authority: this.configuration.b2cConfiguration.b2cPolicies.authorities.forgotPassword.authority,
  //         extraQueryParameters: { ui_locales: this.cultureService.getCurrentLanguage().code }
  //       });
  //       return;
  //     }
  //   }
  // }

  initB2c(){
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.checkAccount();

    });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_SUCCESS ||
          msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          msg.eventType === EventType.LOGOUT_START
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {

        let payload: IdTokenClaims = <AuthenticationResult>result.payload;

        // We need to reject id tokens that were not issued with the default sign-in policy.
        // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr")
        // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview

        if (payload.idTokenClaims?.acr === this.configuration.b2cConfiguration.b2cPolicies.names.forgotPassword) {
          window.alert('Password has been reset successfully. \nPlease sign-in with your new password.');
          return this.msalAuthService.logout();
        } else if (payload.idTokenClaims['acr'] === this.configuration.b2cConfiguration.b2cPolicies.names.editProfile) {
          window.alert('Profile has been updated successfully. \nPlease sign-in again.');
          return this.msalAuthService.logout();
        }

        if (result.eventType === EventType.LOGIN_SUCCESS) {
          return this.accountService.onLogin(true).subscribe();
        }

        if (result.eventType === EventType.LOGOUT_START) {
          sessionStorage.clear();
          this.removeB2cItemsFromLocalStorage();
        }

        return result;
      });

      this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (result.error instanceof AuthError) {
          // Check for forgot password error
          // Learn more about AAD error codes at https://docs.microsoft.com/azure/active-directory/develop/reference-aadsts-error-codes
          if (result.error.message.includes('AADB2C90118')) {

            // login request with reset authority
            let resetPasswordFlowRequest = {
              scopes: ["openid"],
              authority: this.configuration.b2cConfiguration.b2cPolicies.authorities.forgotPassword.authority,
            };
            this.bsfAuthService.b2cLogin(resetPasswordFlowRequest);
          }
        }
      });
  }

  checkAccount() {
    const hasLoggedAccount = this.sessionService.isAuthenticated();
    console.log("checkingAccount: ", hasLoggedAccount);
    const isLogin = !this.sessionService.getUserInfo();
    console.log("isLogin: ", isLogin);
    if(hasLoggedAccount){
      this.sessionService.loadingUser(true);

      combineLatest([
        this.accountService.getPersonInfo(),
      ]).subscribe(([user]) => {
        this.cultureService.setCurrent(getLanguageCulture(user.displayLanguageId),true);
        this.loadLanguagues(true);
        this.sessionService.updateUserInfo(user);

      if(isLogin){
          this.bsfAuthService.redirectUserToDefaulPage();
        }
        this.sessionService.loadingUser(false);
        return true;
      }, error  => {
        console.log("getPersonInfo error:",error);
        if(error && error.status == 404){
          this.sessionService.loadingUser(false);
          this.bsfAuthService.redirectUserToDefaulPage();
          return true;
        }
      });
    }else{
      this.sessionService.clearSession();
      this.loadLanguagues(true);
    }
  }

  private idleInit(startTimeoutCountAfter:number,idleTimeout:number ){

    this.idleTimeoutService.init(startTimeoutCountAfter,idleTimeout);

    this.idleTimeoutService.onTimeout.subscribe(() => {
      this.closeOpenModals();
      this.logout();
    });

    this.idleTimeoutService.onTimeoutWarning.subscribe((count) => {
      console.log("TimeoutWarning:",count, new Date());
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!event.urlAfterRedirects.includes('/logout')) {
          this.idleTimeoutService.start();
        } else {
          this.idleTimeoutService.stop();
        }
      }
    });

  }

  private closeOpenModals() {
    const modalCount = this.modalService.getModalsCount();
    if (modalCount > 0) {
      console.log('closing modal windows');
      this.modalService.hide();      
    } else {
      console.log('No open modals.');
    }
  }

  loadLanguagues(loadHelpWidget:boolean){
    this.translate.addLangs(Languages);
    let currentLanguage = this.cultureService.getCurrent() ? this.cultureService.getCurrent() :English;
    this.cultureService.setCurrent(currentLanguage,loadHelpWidget);
    this.translate.setDefaultLang(currentLanguage);
  }

  logout() {
    this.router.navigateByUrl('account/logout');
  }

  private removeB2cItemsFromLocalStorage() {
    for (var i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      if (key && key.includes('b2clogin')) {
        localStorage.removeItem(key);
      }
    }
  }
}

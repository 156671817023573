import { AuthService } from 'src/app/shared/services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigService } from './../../shared/services/config.service';
import { Component } from '@angular/core';
import { SessionService } from 'src/app/shared/services/session.service';
import { MessageDispatchService } from 'src/app/shared/services/messageDispatch.service';
import { SiteAction } from 'src/app/shared/models/siteAction.enum';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { FindaUserContextType } from 'src/app/user-management/find-a-user/findaUserContextType';


@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isTest: boolean = false;
  isCorrectPasswordForTestEnvironment: string | undefined;
  internationalUrl: string;

  constructor(private _sessionService: SessionService,
    private _router: Router,
    private _messageDispatcher: MessageDispatchService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private configService:ConfigService,
    private authService:AuthService
    ) {

    this.configService.configuration$.subscribe(config => {
      this.enableAccounts = config.enableAccountModule;
      this.accountsBaseUrl = config.accountsModuleBaseUrl;
      this.resourceLibraryBaseUrl = config.resourceLibraryModuleBaseUrl;
      this.enableMybsf = config.enableMybsf;
      this.mybsfBaseUrl = config.mybsfBaseUrl;
      this.enableResourceLibrary = config.enableResourceLibrary;
      this.isTest = config.isTest;
      this.internationalUrl = config.links.international;
    });

    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isCorrectPasswordForTestEnvironment = sessionStorage.getItem('isCorrectPasswordForTestEnvironment')
        console.log(this.isCorrectPasswordForTestEnvironment)
      }
    });  
  }

  public enableAccounts:boolean = false;
  public accountsBaseUrl:string;
  public resourceLibraryBaseUrl:string;
  public enableMybsf:boolean = false;
  public mybsfBaseUrl:string;
  public enableResourceLibrary:boolean = false;

  getUserManagementContextType() {
    return FindaUserContextType[FindaUserContextType.UserManagement];
  }

  canShownavMenu(){
    const user = this._sessionService.getUserInfo();
    return this.isAuthenticated() && user;
  }

  hideNavMenu(){
    const user = this._sessionService.getUserInfo();
    return this.isAuthenticated() && !user;
  }

  isAuthenticated() {
    return this._sessionService.isAuthenticated();
  }

  hasOnlineClass() {
    const user = this._sessionService.getUserInfo();
    return this.isAuthenticated() && !!user && user.hasOnlineClass;
  }

  userFullname() {
    const user = this._sessionService.getUserInfo();
    return user?`${user.firstName} ${user.lastName}`:"";
  }

  isLeader() {
    return this._sessionService.isUserLeader();
  }

  isTrainer() {
    return this._sessionService.isUserTrainer();
  }

  isAssistantTrainer() {
    return this._sessionService.isUserAssistantTrainer();
  }

  isAreaPersonnel() {
    return this._sessionService.isArealPersonel();
  }

  isRegionalDirector() {
    return this._sessionService.isRegionalDirector();
  }

  isAdmin() {
    return this._sessionService.isAdminFullAccess();
  }

  isAdminViewOnly() {
    return this._sessionService.isAdminViewOnly();
  }

  userPostion() {
    const user = this._sessionService.getUserInfo();
    return user?user.position:"";
  }

  isGroupMember() {
    return this._sessionService.isUserMember();
  }

  isOnlineMember() {
    return this._sessionService.isOnlineMember();
  }

  allowCreateOrEditLeadersMeeting() {
    return this._sessionService.allowCreateOrEditLeadersMeeting();
  }

  canShowApplicationStatusStatus() {
    const user = this._sessionService.getUserInfo();
    return user && user.canShowApplicationStatus;
  }

  canUseFindOLM() {
    return this.isAdmin() || this.isRegionalDirector() || this.isAreaPersonnel() || this.isTrainer() || this.isAssistantTrainer();
  }

  userPhoto() {
    const user = this._sessionService.getUserInfo();
    if(!user){
      return;
    }
    const gender = user.genderId;
    const photo = user.photo;
    if (!photo) {
      if (gender == 2) {
        return "../../../assets/images/profile-pic/female_placeholder.png";
      } else {
        return "../../../assets/images/profile-pic/leader-m.png";
      }
    }

    return photo
  }

  findAgroup() {
    this.googleAnalyticsService.eventEmitter("find_a_group_btn_hamburger_menu", "button", "click", "find_a_group_btn_hamburger_menu");
  }

  findOLM() {
    this.googleAnalyticsService.eventEmitter("find_a_OLM_btn_hamburger_menu", "button", "click", "find_a_OLM_btn_hamburger_menu");
  }

  usersManagement() {
    this.googleAnalyticsService.eventEmitter("user_management_btn_hamburger_menu", "button", "click", "user_management_btn_hamburger_menu");
    if(this.enableAccounts){
      window.location.href = `${this.accountsBaseUrl}find-a-user`;
    }else{
      this._router.navigate(['/finda/user'],  { queryParams: {'contextType': this.getUserManagementContextType() }});
    }

  }

  leadersInformation() {
    this.googleAnalyticsService.eventEmitter("leaders_information_btn_hamburger_menu", "button", "click", "leaders_information_btn_hamburger_menu");
  }

  give() {
    this.googleAnalyticsService.eventEmitter("give_btn_hamburger_menu", "button", "click", "give_btn_hamburger_menu");
  }

  b2cLogout(){
    this.authService.b2cLogout();
  }

  goToProfile(){
    if(this.enableAccounts){
      window.location.href = `${this.accountsBaseUrl}account/profile`;
    }else{
      this._router.navigateByUrl('account/profile');
    }
  }

  goToSettings(){
    window.location.href = `${this.accountsBaseUrl}settings`;
  }

  goToMybsf(){
    window.location.href = this.mybsfBaseUrl;
  }

  goToHelpAndResources(){
    if(this.enableResourceLibrary){
      window.location.href = `${this.resourceLibraryBaseUrl}`;
    }else{
      this._router.navigateByUrl('help-resource-materials');
    }
  }

  b2clogin(){
    this.googleAnalyticsService.eventEmitter("login_btn_home", "button", "click", "login_btn_home");
    this.authService.b2cLogin();
  }

  findGroup() {
    this.googleAnalyticsService.eventEmitter("find_a_group_btn_home", "button", "click", "find_a_group_btn_home");
  }

  shouldEnterPasswordToAccess() {
    return this.isTest && this.isCorrectPasswordForTestEnvironment != 'true'
  }
}

import { NamedId } from "../models/namedId.model";
export class UtilityHelper {

    static convertToImage(base64String: string) {
        return `data:image/png;base64,${base64String}`;
    }

    static generateHunderYears(): NamedId<number>[] {
        const now = new Date().getFullYear();
        const years = []
        for (let index = 1; index < 100; index++) {
            const diff = now - index
            years.push(new NamedId<number>(diff, diff.toString()))
        }

        return years;
    }

    static getMonthsDays(): NamedId<number>[] {
        const days = [];
        for (let index = 1; index < 32; index++) {
            days.push(new NamedId<number>(index, index.toString()));
        }

        return days
    }

    static getMonths(): NamedId<number>[] {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const months = [];
        monthNames.forEach((month, index) => {
            months.push(new NamedId<number>(index, month))
        })

        return months;
    }

    static getDayOfWeek(day: number) {
        return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][day];
    }

    static getBirthInformationFromAge(age: number): [number, number, number] {
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth(); // 0 index
        const currentDate = now.getDate();
        let birthYear: number;

        if (currentMonth < 5 && currentDate < 30) { // june 30
            birthYear = (currentYear - age) + 1
        } else {
            birthYear = (currentYear - age)
        }

        return [birthYear, 5, 30]; // [year, month, day]
    }

    static getBirthInformation(birthdate: Date): [number, number, number] {
        return [birthdate.getFullYear(), birthdate.getMonth(), birthdate.getDate()];
    }

    static executeAndSetInterval(fn, t, zone) {
        fn();

        zone.runOutsideAngular(() => {
          setInterval(() => {
            zone.run(() => {
              fn();
            });
          }, t);
        });
    }

    static getFileNameFromFilePath(filePath: string) {
        const paths = filePath.split('/');
        return paths.pop();
    }

    static getFileExtensionFromFilePath(filePath: string) {
        const fileName = this.getFileNameFromFilePath(filePath);
        return fileName.split('.').pop();
    }

    static getNavigationState() {
        return window.history.state;
    }

    static getClockTime(): string[] {
        var arr = [], i, j;
        for (i = 0; i < 24; i++) {
            for (j = 0; j < 4; j++) {
                arr.push((i.toString().length < 2 ? `0${i}` : i.toString()) + ":" + (j === 0 ? "00" : 15 * j));
            }
        }
        return arr;
    }


    static getDefaultDateformat(): string{
      return this.getGetDateformat(1);
    }

    static getGetDateformat(languageId: number): string {
      const dateFormats: { [key: number]: string } = {
        1: 'MMM DD, YYYY',
        2: 'DD-MMM, YYYY',
      };
    
      return dateFormats[languageId] || 'YYYY年M月D日';
    }

    static filterOnEnter(e): void {
      if (e.keyCode === 13){
        e.preventDefault();
        document.getElementById('btn-filter').click();
      }
    }


    static testPasswordFormat(password) {
      if(password != null) {
        const regexp: RegExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/;
        const testPassword =  regexp.test(password);
        return testPassword;
      }else{
        return;
      }
    }


    static scrollToTopPageMobile(element) {
    let top = document.getElementById(element);
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        if (top !== null) {
          top.scrollIntoView();
          top = null;
        }
      } else{
        return;
      }
    }

    static isNumber(text: string): boolean {
        return /^\d+$/.test(text);
    }

    static b64EncodeUnicode(str: string) {
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
          return String.fromCharCode(parseInt(p1, 16))
      }))
    }

    static b64DecodeUnicode(str: string) {
        return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
    }

    static getEnumKeyByValue(enumType: any, enumValue: number): string | undefined {
      const keys = Object.keys(enumType).filter(x => enumType[x] === enumValue);
      return keys.length > 0 ? keys[0] : undefined;
  }

}

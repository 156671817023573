import { Component, OnInit } from '@angular/core';
import { AnnounceMentBar } from 'src/app/admin/common/models/announcement-bar.model';
import { AnnouncementBarService } from 'src/app/admin/common/services/announcement.service';

@Component({
  selector: 'app-system-announcement',
  templateUrl: './system-announcement.component.html',
  styleUrls: ['./system-announcement.component.css']
})
export class SystemAnnouncementComponent implements OnInit {
  announcements: AnnounceMentBar[];

  constructor(
    private _announcementService: AnnouncementBarService
    ) { }


  ngOnInit() {
    this.getActivatedSystemAnnouncements();
  }

  onClose(announcement) {    
    this._announcementService.markAsSeen(announcement.systemMaintenanceId)    
  }

  getActivatedSystemAnnouncements() {
    this._announcementService.getActivatedSystemAnnouncements(true).subscribe( data => {
      this.announcements = data.map(item => AnnounceMentBar.create(item));
      return this.announcements;
    })

  }

}

<div class="modal-content text-center">
    <div class="modal-header d-flex justify-content-center">
        <h2></h2>
    </div>
    <div class="modal-body">
        <p>{{'AreYouSureAdd' | translate}} {{data.user.fullName}} {{'ToThisGroup' | translate}}?</p>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="blue-btn modal-button" (click)="data.confirm()"
            data-dismiss="modal">{{'Confirm' | translate}}</button>
        <button type="button" class="blue-border-btn modal-button" data-dismiss="modal"
            (click)="closeMe()">{{'Cancel' | translate}}</button>
    </div>
</div>

<label>{{labelText}}</label><br>
<div class="input-group">
    <div class="input-group-prepend">
        <button class="calendar-display" ><i class="far fa-calendar-alt"></i></button>
    </div>
    <input autocomplete="off" type="text" class="form-control" [disabled]="disable" placeholder="{{placeHolder}}"
        name="daterangeInput" daterangepicker [options]="options" (selected)="selectedDate($event)" />
    <span class="{{randomclass}}"></span>
</div>
<div class="feedback" *ngIf="canShowError">
    {{'SelectFriday' | translate}}
</div>

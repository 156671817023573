import { ConfigService } from './config.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FilesService {

  private _filesEndpoint;

  constructor(
    private _http: HttpClient,
    private configService:ConfigService
  ) {
    configService.configuration$.subscribe(config => {
      this._filesEndpoint = `${config.apiEndpoint}files`;
    });
  }

  uploadLessonMaterailFile(form: FormData) {
    return this._http.post(`${this._filesEndpoint}/_upload/lesson-material`, form, { reportProgress: true, observe: 'events' })
  }

  deleteLessonMAterailFile(filePath: string) {
    return this._http.delete(`${this._filesEndpoint}/_delete/lesson-material?filePath=${encodeURIComponent(filePath)}`);
  }
}

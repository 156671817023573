<div class="modal-content text-center">
    <div class="modal-header d-flex justify-content-center">
        <h4>{{'switchOLM' | translate}}.</h4>
    </div>
    <div class="modal-body">
        <p>{{'switchOLMExp' | translate}}.</p><br>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="blue-btn modal-button" (click)="select()">{{'Agree' | translate}}</button>
    </div>
</div>

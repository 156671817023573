export enum DisplayLanguage {
    English = 1,
    Spanish = 2,
    SimpleChinese = 3,
    TradChinese = 4
}

export enum BsfoFeatureNames
{
  marketingEmailConsent = "MarketingEmailConsent"
}


import { Injectable, NgZone } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { IdleStatus } from '../models/enums/idle-status.enum';


@Injectable()
export class IdleTimeoutService {

    private StartAfterSeconds = 30;
    private TimeOutSeconds = 1800;
    idleState = IdleStatus.NotStarted;
    timedOut = false;

    idleTimeWhenInActive: Date | undefined = new Date();
    inActiveCheckingInterval = 5000;


    @Output() onTimeout: EventEmitter<any> = new EventEmitter();
    @Output() onTimeoutWarning: EventEmitter<number> = new EventEmitter();
    constructor(
        private idle: Idle,
        private zone: NgZone
    ) {

            zone.runOutsideAngular(() => {
              const inactiveTabCheckingTimer = setInterval(() => {

                if (this.timedOut) {
                  clearInterval(inactiveTabCheckingTimer);
                  return;
                }

                const isIdle = this.idle.isIdling();

                if (isIdle && this.idleTimeWhenInActive == undefined) {
                  this.idleTimeWhenInActive = new Date();
                }


                if (!isIdle && this.idleTimeWhenInActive != undefined) {
                  this.idleTimeWhenInActive = undefined;
                }

                if (isIdle && this.idleTimeWhenInActive != undefined) {
                  const now = new Date();
                  const diff = now.getTime() - this.idleTimeWhenInActive.getTime();
                  const diffSeconds = Math.round(diff / 1000);

                  console.log("inactive diffSeconds: " + diffSeconds);

                  if (diffSeconds >= this.TimeOutSeconds) {

                    console.log("Timeout triggered by inactive logic")

                    this.idleState = IdleStatus.Timedout;
                    this.timedOut = true;
                    idle.stop();
                    this.onTimeout.emit();
                    this.logState();
                  } else {
                    this.onTimeoutWarning.emit(this.TimeOutSeconds - diffSeconds);
                  }
                }

              }, this.inActiveCheckingInterval);
            })

        idle.onIdleEnd.subscribe(() => {
            this.idleState = IdleStatus.NotOnIdle;
            this.logState();
            this.reset();
        });

        idle.onTimeout.subscribe(() => {
            this.idleState = IdleStatus.Timedout;
            this.timedOut = true;
            this.logState();
            this.onTimeout.emit();
        });

        idle.onIdleStart.subscribe(() => {
            this.idleState = IdleStatus.OnIdle;
            this.logState();
        });

        idle.onTimeoutWarning.subscribe((countdown: number) => {
                this.idleState = IdleStatus.TimeoutWarning;
                this.logState(countdown);
                this.onTimeoutWarning.emit(countdown);
        });
    }
    init(startAfterSeconds: number, timeOutSeconds: number) {
      console.log("startAfterSeconds:", startAfterSeconds, "timeOutSeconds:", timeOutSeconds);
      this.StartAfterSeconds = Number(startAfterSeconds),
      this.TimeOutSeconds = Number(timeOutSeconds),
      this.idle.setIdle(this.StartAfterSeconds);
      this.idle.setTimeout(this.TimeOutSeconds);
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    }

    start() {
        //this.keepalive.interval(keepAliveSeconds);
        if(this.idleState != IdleStatus.Started) {
            this.idle.watch();
            this.idleState = IdleStatus.Started;
            this.timedOut = false;
            this.logState();
        }
    }

    stop() {
        this.idle.stop();
        this.idleState = IdleStatus.Stopped;
        this.timedOut = false;
        this.idleTimeWhenInActive = undefined;
        this.logState();
    }

    resume() {
        if(this.idleState != IdleStatus.Started) {
            this.idle.watch();
            this.idleState = IdleStatus.Started;
            this.timedOut = false;
            this.logState();
        }
    }

    private reset() {
        if(this.idleState != IdleStatus.Started) {
            this.idle.watch();
            this.idleState = IdleStatus.Started;
            this.timedOut = false;
            this.idleTimeWhenInActive = undefined;
            this.logState();
        }
    }

    private logState(countdown:number|undefined = undefined) {
        console.log(this.idleState + !!countdown?countdown:'');
    }
}

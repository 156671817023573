export class Country {
    id: number;
    countryCode: string;
    countryName: string;
    cannotParticipate: boolean;
    phoneCodes: string;

  constructor(id: number, countryCode: string, countryName: string, cannotParticipate: boolean) {
    this.id = id;
    this.countryCode = countryCode;
    this.countryName = countryName;
    this.cannotParticipate = cannotParticipate;    
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ConfigService } from "./config.service";

interface IMaintenancePage {
    title: string;
    content: IMaintenancePageContent
}

interface IMaintenancePageContent {
    en: string;
    sp: string;
    tch: string;
    sch: string;
}

@Injectable()
export class MaintenancePageService {

    maintenancePageContentUrl: string;
    page: IMaintenancePage;

    constructor(
        private _configService: ConfigService,
        private _http: HttpClient,
    ) {
        this._configService.configuration$.subscribe(
            config => {
                this.maintenancePageContentUrl = config.maintenancePageContentUrl;                
            }
        );        
    }

    storeLocalizedPageContent(locale: string | undefined = undefined) {

        if(!locale) {
            locale = localStorage.getItem('locale');        
        }        

        this.loadPageContentFromUrl().subscribe((x)=> {
            this.page = x;                    
            const localizedContent = this.page.content[locale as keyof IMaintenancePageContent];
            sessionStorage.setItem("bsfonline.maintenance.content", localizedContent);
            return localizedContent;
        });        
    }

    loadPageContentFromUrl(): Observable<IMaintenancePage> {
        return this.page 
            ? of(this.page)
            : this._http.get<IMaintenancePage>(this.maintenancePageContentUrl);
    }
}
import { PipeTransform, Pipe } from "@angular/core";
import moment from 'moment-timezone';

@Pipe({
    name: 'formatLongDate'
})
export class FormatLongDatePipe implements PipeTransform {

    transform(date: Date, timezone: string) {

        if (!timezone || !date) {
            return
        }

        const momentDate = moment(date).tz(timezone);
        return momentDate.format("LLLL") + " " + momentDate.format("z");
    }
}

<div *ngIf="isAuthenticated()" class="row px-5">
  <nav aria-label="breadcrumb">

    <!--HQ Admin-->
    <ol *ngIf="isAdmin()" id="breadcrumb" class="breadcrumb">

      <li class="breadcrumb-item">
        <a [routerLink]="['/admin/study']">{{'Admin' | translate}}</a>
      </li>
      <li *ngIf="checkCurrentPage('region') || checkCurrentPage('mygroup') || checkCurrentPage('area') || checkCurrentPage('training')" class="breadcrumb-item">
        <a [routerLink]="['/region/my-region']">{{'MyRegion' | translate}}</a>
      </li>
      <li *ngIf="checkCurrentPage('area') || checkCurrentPage('training') || checkCurrentPage('mygroup') " class="breadcrumb-item">
        <a [routerLink]="['/area/my-area', areaId]">{{'MyArea' | translate}} {{areaLeaderName}}</a>
      </li>
      <li *ngIf="checkCurrentPage('training') || checkCurrentPage('mygroup')" class="breadcrumb-item">
        <a (click)="gotoTraining()" href="javascript:void(0)">{{'MyTraining' | translate}}</a>
      </li>
      <li *ngIf="checkCurrentPage('mygroup')" class="breadcrumb-item">
        <a (click)="gotoGroup()" href="javascript:void(0)">{{'MyGroup' | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/mylesson'" class="breadcrumb-item">
        <a [routerLink]="['/mylesson']">{{'MyLessons' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage().startsWith('/calendar')" class="breadcrumb-item">
        <a [routerLink]="['/calendar']">{{'MyCalendar' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage() == '/calendar/mylessons-schedule'" class="breadcrumb-item">
        <a [routerLink]="['/calendar/mylessons-schedule']">{{'MyLessonSchedule' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage() == '/account/profile'" class="breadcrumb-item">
        <a [routerLink]="[ '/account/profile' ]">{{"EditProfile" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/region/edit-region'" class="breadcrumb-item">
        <a [routerLink]="['/region/edit-region']">{{"RegionSettings" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/finda/user'" class="breadcrumb-item">
        <a [routerLink]="['/finda/user']">{{"UserManage" | translate}}</a>
      </li>
    </ol>



    <!--Regional Director ORD-->
    <ol *ngIf="isRegionalDirector()" id="breadcrumb" class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/region/my-region']">{{'MyRegion' | translate}}</a>
      </li>
      <li *ngIf="checkCurrentPage('area') || checkCurrentPage('training') || checkCurrentPage('mygroup') " class="breadcrumb-item">
        <a [routerLink]="['/area/my-area', areaId]">{{'MyArea' | translate}} {{areaLeaderName}}</a>
      </li>
      <li *ngIf="checkCurrentPage('training') || checkCurrentPage('mygroup') " class="breadcrumb-item">
        <a (click)="gotoTraining()" href="javascript:void(0)">{{'MyTraining' | translate}}</a>
      </li>
      <li *ngIf="checkCurrentPage('mygroup')" class="breadcrumb-item">
        <a (click)="gotoGroup()" href="javascript:void(0)">{{'MyGroup' | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/mylesson'" class="breadcrumb-item">
        <a [routerLink]="['/mylesson']">{{'MyLessons' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage().startsWith('/calendar')" class="breadcrumb-item">
        <a [routerLink]="['/calendar']">{{'MyCalendar' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage() == '/calendar/mylessons-schedule'" class="breadcrumb-item">
        <a [routerLink]="['/calendar/mylessons-schedule']">{{'MyLessonSchedule' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage() == '/account/profile'" class="breadcrumb-item">
        <a [routerLink]="[ '/account/profile' ]">{{"EditProfile" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/region/edit-region'" class="breadcrumb-item">
        <a [routerLink]="['/region/edit-region']">{{"RegionSettings" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/finda/user'" class="breadcrumb-item">
        <a [routerLink]="['/finda/user']">{{"UserManage" | translate}}</a>
      </li>
    </ol>


    <!--Online Area Personel OAP-->
    <ol *ngIf="isAreaPersonnel()" id="breadcrumb" class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/area/my-area']">{{'MyArea' | translate}} {{areaLeaderName}}</a>
      </li>
      <li *ngIf="checkCurrentPage('training') || checkCurrentPage('mygroup') " class="breadcrumb-item">
        <a (click)="gotoTraining()" href="javascript:void(0)">{{'MyTraining' | translate}}</a>
      </li>
      <li *ngIf="checkCurrentPage('mygroup')" class="breadcrumb-item">
        <a (click)="gotoGroup()" href="javascript:void(0)">{{'MyGroup' | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/mylesson'" class="breadcrumb-item">
        <a [routerLink]="['/mylesson']">{{'MyLessons' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage().startsWith('/calendar')" class="breadcrumb-item">
        <a [routerLink]="['/calendar']">{{'MyCalendar' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage() == '/calendar/mylessons-schedule'" class="breadcrumb-item">
        <a [routerLink]="['/calendar/mylessons-schedule']">{{'MyLessonSchedule' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage() == '/account/profile'" class="breadcrumb-item">
        <a [routerLink]="[ '/account/profile' ]">{{"EditProfile" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/region/edit-region'" class="breadcrumb-item">
        <a [routerLink]="['/region/edit-region']">{{"RegionSettings" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/finda/user'" class="breadcrumb-item">
        <a [routerLink]="['/finda/user']">{{"UserManage" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/mygroup/group'" class="breadcrumb-item">
        <a [routerLink]="['/mygroup/group']">{{"FindOnlineGroup" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/area/edit-area'" class="breadcrumb-item">
        <a [routerLink]="['/area/edit-area']">{{"AreaSettings" | translate}}</a>
      </li>
    </ol>



    <!--Online Trainer OT or OAT-->
    <ol *ngIf="isTrainer() || isAssistantTrainer()" id="breadcrumb" class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/mytraining']">{{'MyTraining' | translate}}</a>
      </li>
      <li *ngIf="checkCurrentPage('mygroup') && ! checkCurrentPage('/mygroup/group')" class="breadcrumb-item">
        <a (click)="gotoGroup()" href="javascript:void(0)">{{'MyGroup' | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/mylesson'" class="breadcrumb-item">
        <a [routerLink]="['/mylesson']">{{'MyLessons' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage().startsWith('/calendar')" class="breadcrumb-item">
        <a [routerLink]="['/calendar']">{{'MyCalendar' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage() == '/calendar/mylessons-schedule'" class="breadcrumb-item">
        <a [routerLink]="['/calendar/mylessons-schedule']">{{'MyLessonSchedule' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage() == '/calendar/mystudy-schedule'" class="breadcrumb-item">
        <a [routerLink]="['/calendar/mystudy-schedule']">{{'MyStudySchedule' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage() == '/account/profile'" class="breadcrumb-item">
        <a [routerLink]="[ '/account/profile' ]">{{"EditProfile" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/region/edit-region'" class="breadcrumb-item">
        <a [routerLink]="['/region/edit-region']">{{"RegionSettings" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/finda/user'" class="breadcrumb-item">
        <a [routerLink]="['/finda/user']">{{"UserManage" | translate}}</a>
      </li>
      <li *ngIf="checkCurrentPage('/mygroup/group') " class="breadcrumb-item">
        <a [routerLink]="['/mygroup/group']">{{"FindOnlineGroup" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/myclass/leaders_meeting'" class="breadcrumb-item">
        <a [routerLink]="['/myclass/leaders_meeting']">{{"LMSettings" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/mytraining/manage_group'" class="breadcrumb-item">
        <a [routerLink]="['/mytraining/manage_group']">{{"LGManage" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/attendance-list'" class="breadcrumb-item">
        <a [routerLink]="['/attendance-list']">{{"MyTrainingAttendance" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/mytraining/manage-leader-group'" class="breadcrumb-item">
        <a [routerLink]="['/mytraining/manage-leader-group']">{{"ManagePeopleInLeaderGroup" | translate}}</a>
      </li>
    </ol>



    <!--Online Group Leader OGL-->
    <ol *ngIf="isLeader()" id="breadcrumb" class="breadcrumb">
      <li *ngIf="getCurrentPage()" class="breadcrumb-item">
        <a [routerLink]="['/mygroup']">{{'MyGroup' | translate}}</a>
      </li>
      <li *ngIf="checkCurrentPage('training')" class="breadcrumb-item">
        <a [routerLink]="['/mytraining']">{{'MyTraining' | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/mylesson'" class="breadcrumb-item">
        <a [routerLink]="['/mylesson']">{{'MyLessons' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage().startsWith('/calendar')" class="breadcrumb-item">
        <a [routerLink]="['/calendar']">{{'MyCalendar' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage() == '/calendar/mystudy-schedule'" class="breadcrumb-item">
        <a [routerLink]="['/calendar/mystudy-schedule']">{{'MyStudySchedule' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage() == '/account/profile'" class="breadcrumb-item">
        <a [routerLink]="[ '/account/profile' ]">{{"EditProfile" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/region/edit-region'" class="breadcrumb-item">
        <a [routerLink]="['/region/edit-region']">{{"RegionSettings" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/finda/user'" class="breadcrumb-item">
        <a [routerLink]="['/finda/user']">{{"UserManage" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/mygroup/group'" class="breadcrumb-item">
        <a [routerLink]="['/mygroup/group']">{{"FindOnlineGroup" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/myclass/leaders_meeting'" class="breadcrumb-item">
        <a [routerLink]="['/myclass/leaders_meeting']">{{"LMSettings" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/mygroup/edit'" class="breadcrumb-item">
        <a [routerLink]="['/mygroup/edit']">{{"MyGroupSettings" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/attendance-list'" class="breadcrumb-item">
        <a [routerLink]="['/attendance-list']">{{"MyTrainingAttendance" | translate}}</a>
      </li>
    </ol>



    <!--Online Group Member OGM-->
    <ol *ngIf="isGroupMember()" id="breadcrumb" class="breadcrumb">
      <li *ngIf="getCurrentPage()" class="breadcrumb-item">
        <a [routerLink]="['/mygroup']">{{'MyGroup' | translate}}</a>
      </li>
      <li *ngIf="checkCurrentPage('training')" class="breadcrumb-item">
        <a [routerLink]="['/mytraining']">{{'MyTraining' | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/mylesson'" class="breadcrumb-item">
        <a [routerLink]="['/mylesson']">{{'MyLessons' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage().startsWith('/calendar')" class="breadcrumb-item">
        <a [routerLink]="['/calendar']">{{'MyCalendar' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage() == '/calendar/mystudy-schedule'" class="breadcrumb-item">
        <a [routerLink]="['/calendar/mystudy-schedule']">{{'MyStudySchedule' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage() == '/account/profile'" class="breadcrumb-item">
        <a [routerLink]="[ '/account/profile' ]">{{"EditProfile" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/region/edit-region'" class="breadcrumb-item">
        <a [routerLink]="['/region/edit-region']">{{"RegionSettings" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/finda/user'" class="breadcrumb-item">
        <a [routerLink]="['/finda/user']">{{"UserManage" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/mygroup/group'" class="breadcrumb-item">
        <a [routerLink]="['/mygroup/group']">{{"FindOnlineGroup" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/myclass/leaders_meeting'" class="breadcrumb-item">
        <a [routerLink]="['/myclass/leaders_meeting']">{{"LMSettings" | translate}}</a>
      </li>
    </ol>




    <!--Online Member OM-->
    <ol *ngIf="isOnlineMember()" id="breadcrumb" class="breadcrumb">
      <li *ngIf="getCurrentPage()" class="breadcrumb-item">
        <a [routerLink]="['/mygroup']">{{'MyGroup' | translate}}</a>
      </li>
      <li *ngIf="checkCurrentPage('training')" class="breadcrumb-item">
        <a [routerLink]="['/mytraining']">{{'MyTraining' | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/mylesson'" class="breadcrumb-item">
        <a [routerLink]="['/mylesson']">{{'MyLessons' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage().startsWith('/calendar') " class="breadcrumb-item">
        <a [routerLink]="['/calendar']">{{'MyCalendar' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage() == '/calendar/mylessons-schedule'" class="breadcrumb-item">
        <a [routerLink]="['/calendar/mylessons-schedule']">{{'MyLessonSchedule' | translate}} </a>
      </li>
      <li *ngIf="getCurrentPage() == '/account/profile'" class="breadcrumb-item">
        <a [routerLink]="[ '/account/profile' ]">{{"EditProfile" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/region/edit-region'" class="breadcrumb-item">
        <a [routerLink]="['/region/edit-region']">{{"RegionSettings" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/finda/user'" class="breadcrumb-item">
        <a [routerLink]="['/finda/user']">{{"UserManage" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/mygroup/group'" class="breadcrumb-item">
        <a [routerLink]="['/mygroup/group']">{{"FindOnlineGroup" | translate}}</a>
      </li>
      <li *ngIf="getCurrentPage() == '/myclass/leaders_meeting'" class="breadcrumb-item">
        <a [routerLink]="['/myclass/leaders_meeting']">{{"LMSettings" | translate}}</a>
      </li>
    </ol>
  </nav>
</div>


import { NamedId } from '../../shared/models/namedId.model'

export const SiteConstants = {
  tokenKey: 'BSFOnline:access_token',
  userInfoKey: 'BSFOnline:user_info',
  redirectUriKey: 'BSFOnline:redirect_uri',
  externalUrlWhatsAppApi: 'https://api.whatsapp.com/send?phone=',
  externalUrlWhatsAppGroup: 'https://api.whatsapp.com/',
  msaltokenKey: 'msal.accesstoken',
  msalClientInfo: 'msal.client.info',
  msalIdtokenKey: 'msal.idtoken',
  sesionDataKey: 'sessiondata',
  hideTimezoneModalKey:'hidetimezonemodal',
  breadcrumbKey:'breadcrumb',
  msalErrorDescription:'msal.error.description'
}

export const meetingDays: NamedId<number>[] = [{ id: 0, displayName: "Sunday" }, { id: 1, displayName: "Monday" }, { id: 2, displayName: "Tuesday" }, { id: 3, displayName: "Wednesday" }, { id: 4, displayName: "Thursday" }, { id: 5, displayName: "Friday" }, { id: 6, displayName: "Saturday" }];

<select class="form-control" id="meeting-day" [(ngModel)]='value' (change)='change($event)'>
    <option [value]="undefined" selected></option>
    <option [value]="1">{{'Mon' | translate}}</option>
    <option [value]='2'>{{'Tues' | translate}}</option>
    <option [value]='3'>{{'Wed' | translate}}</option>
    <option [value]='4'>{{'Thurs' | translate}}</option>
    <option [value]='5'>{{'Fri' | translate}}</option>
    <option [value]='6'>{{'Sat' | translate}}</option>
    <option [value]='0'>{{'Sun' | translate}}</option>
  </select>

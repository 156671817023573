import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { NamedId } from '../../models/namedId.model';
import { NamedIdWithFlag } from '../../models/namedIdWithFlag.model';

@Component({
  selector: 'app-item-list-editor',
  templateUrl: './item-list-editor.component.html',
  styleUrls: ['./item-list-editor.component.scss']
})
export class ItemListEditorComponent implements OnInit {

  @Input()
  selectedItems: NamedIdWithFlag<number>[];

  @Input()
  availableItems: NamedId<number>[];

  filteredItems: NamedId<number>[];

  @Input()
  itemLabel: string = "Item";

  @Input()
  newItemLabel: string = "New Item";

  @Input()
  addButtonCaption: string = "Add New";

  @Input()
  addNewInputPlaceholder: string = "Select item from the list";
  
  @Input()
  maxNumberOfItems: number = 5;
  
  @Input()
  maxNumberWarningText: string = "You reached maximum number of items.";

  @Input()
  readOnlyItemMessage: string = "This item is read only.";

  public newItem: NamedId<number>;

  public get reachedMaxNumberOfItems() : boolean {
    return this.selectedItems.length >= this.maxNumberOfItems;
  };

  constructor() {   
  }

  ngOnInit(): void {  
    this.filteredItems = [...this.availableItems];  
  }

  public onAddItemClick() {
    if(this.newItem) {      
      this.selectedItems = [...this.selectedItems, <NamedIdWithFlag<number>>{ id: this.newItem.id, displayName: this.newItem.displayName, flag: false }];
      this.availableItems = this.availableItems.filter(x=>x.id !== this.newItem.id);
      this.filteredItems = [...this.availableItems];
      this.newItem = null;      
    }
  }

  public onRemoveItemClick(item: NamedId<number>) {
    this.selectedItems = this.selectedItems.filter(x=>x.id !== item.id);    
    this.availableItems = [...this.availableItems, item];
    this.filteredItems = [...this.availableItems];
  }

  public static mapToNamedIdWithFlag<T>(item: NamedId<T>, flag: boolean ): NamedIdWithFlag<T>  {
    return <NamedIdWithFlag<T>> { id: item.id, displayName: item.displayName, flag: flag }
  }

  handleFilter(value: string) {
    this.filteredItems = this.availableItems.filter(
      (s) => s.displayName.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  

}

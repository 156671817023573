<div class="modal-content text-center">
    <div class="modal-header d-flex justify-content-center">
        <h4>{{'ApproveOGL' | translate}}.</h4>
    </div>
    <div class="modal-body">
      <p class="gray-text">{{'ApproveOGLExp' | translate}}.</p><br>
        <!-- <textarea class="form-control"></textarea> -->
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="blue-btn modal-button" (click)="select()">{{'Confirm' | translate}}</button>
        <button type="button" class="blue-border-btn modal-button" (click)="cancel()"> {{'Cancel' | translate}}</button>
    </div>
</div>

import { ConfigService } from './config.service';
import { DiscussionMeeting } from './../models/discussionMeeting.model';
import { Injectable } from '@angular/core';
import decode from 'jwt-decode';
import { SiteConstants } from '../constants/siteConstants';
import { User } from '../models/user.model';
import { UtilityHelper } from '../helpers/utils';
import { Trainer, GroupLeader, GroupMember, AssistantTrainer, OnlineMember, AreaPersonnel, RegionalDirector } from '../models/Position.constant';
import { Subject, Observable, BehaviorSubject, switchMap, of, tap, catchError } from 'rxjs';
import { CultureService } from './culture.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DialogBoxComponent } from '../widgets/dialog-box/dialog-box.component';
import moment from 'moment';
import { Configuration } from '../models/configuration.model';
import { Breadcrumb } from '../models/breadcrumb.model';
import { Position } from '../models/position.enum';
import { MsalService} from '@azure/msal-angular';
import { AppSettingsService } from 'src/app/shared/services/appSettings.service';
import { AccountService } from './account.service';
import { HidePhoneEmailDialogComponent } from '../widgets/hide-phone-email-dialog/hide-phone-email-dialog.component';

interface SessionData{
  message: string,
  groupToJoin: DiscussionMeeting
}

@Injectable()
export class SessionService {

  private _window: Window = window;
  private _user: User;
  private userLoggedIn = new Subject<boolean>();
  private gettingUser: boolean;
  public config: Configuration;
  public enableZoomLicense: boolean;
  public enableSecurityQuestions: boolean;
  public enableMfa: boolean;
  public accountsBaseUrl: string;

  public isLoadingUser$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _cultuerService: CultureService,
    private translate: TranslateService,
    private modalService: BsModalService,
    private configService:ConfigService,
    private msalAuthService: MsalService,
    private _appsettingsService: AppSettingsService,
    private accountService: AccountService) {
    this.userLoggedIn.next(false);
    this.configService.configuration$.subscribe(config => {
      this.config = config;
      this.enableSecurityQuestions = config.enableSecurityQuestions;
      this.enableMfa = config.enableMfa;
      this.accountsBaseUrl = config.accountsModuleBaseUrl;
    });
    this._appsettingsService.appSettings$.subscribe(settings => {
      this.enableZoomLicense = settings.features.enableZoomLicense;      
    });
  }

  isAuthenticated(): boolean {
    const isAuthenticated = this.msalAuthService.instance.getAllAccounts().length > 0;
    return isAuthenticated;
  }

  isUserTrainer(): boolean {
    const user = this.getUserInfo();
    return user && user.position && user.position == Trainer;
  }

  isAssistantTrainer(): boolean {
    const user = this.getUserInfo();
    return user && user.position && user.position == AssistantTrainer;
  }

  isTrainerOrAssistantTrainer() {
    return this.isUserTrainer() || this.isAssistantTrainer();
  }

  isUserAssistantTrainer() {
    const user = this.getUserInfo();
    return user && user.position && user.position == AssistantTrainer;
  }

  isArealPersonel() {
    const user = this.getUserInfo();
    return user && user.position && user.position == AreaPersonnel;
  }

  isAdmin() {
    const user = this.getUserInfo();
    return user && (user.positionId == Position.HQAdmin || user.positionId == Position.HQAdminVO);
  }

  isAdminFullAccess() {
    const user = this.getUserInfo();
    return user && user.positionId == Position.HQAdmin;
  }

  isAdminViewOnly() {
    const user = this.getUserInfo();
    return user && user.positionId == Position.HQAdminVO;
  }

  isRegionalDirector() {
    const user = this.getUserInfo();
    return user && user.position && user.position == RegionalDirector;
  }

  isUserLeader() {
    const user = this.getUserInfo();
    return user && user.position && user.position == GroupLeader;
  }

  isUserMember() {
    const user = this.getUserInfo();
    return user && user.position && user.position == GroupMember;
  }

  isOnlineMember() {
    const user = this.getUserInfo();
    return user && user.position && user.position == OnlineMember;
  }

  allowCreateOrEditLeadersMeeting() {
    return this.isUserTrainer() || (this.isAssistantTrainer()  &&  this.UserHasClass());
  }

  currentPage() {
    return this._window.location.pathname;
  }

  UserHasGroup(): boolean {
    const user = this.getUserInfo();
    return user && user.hasOnlineGroup;
  }

  UserHasClass(): boolean {
    const user = this.getUserInfo();
    return user && user.hasOnlineClass
  }

  setSessionData(sessiondata: SessionData){
    this._window.localStorage.setItem(SiteConstants.sesionDataKey, JSON.stringify(sessiondata));
  }

  public getSessionData():SessionData{
    return JSON.parse(this._window.localStorage.getItem(SiteConstants.sesionDataKey));
  }

  removeSessionData(){
    this._window.localStorage.removeItem(SiteConstants.sesionDataKey);
  }

  getAccessToken(): string {
    return this._window.localStorage.getItem(SiteConstants.tokenKey);
  }

  getAccessMsalIdToken(): string {
    const cacheLocation = this.config.b2cConfiguration.b2cPolicies.cacheLocation;
    var token:string;
    if(cacheLocation == 'localStorage'){
      token = localStorage.getItem(SiteConstants.msalIdtokenKey);
    }else if (cacheLocation == "sessionStorage"){
      token = sessionStorage.getItem(SiteConstants.msalIdtokenKey);
    }
    return token;
  }

  getMsalErrorDescription(){
    const cacheLocation = this.config.b2cConfiguration.b2cPolicies.cacheLocation;
    var msalErrorDescription:string;
    if(cacheLocation == 'localStorage'){
      msalErrorDescription = localStorage.getItem(SiteConstants.msalErrorDescription);
    }else if (cacheLocation == "sessionStorage"){
      msalErrorDescription = sessionStorage.getItem(SiteConstants.msalErrorDescription);
    }

    return msalErrorDescription;
  }

  hideTimezoneModal() {
    this._window.localStorage.setItem(SiteConstants.hideTimezoneModalKey, 'true');
  }

  shouldHideTimezoneModal(): boolean {
    const hideTimezoneModalKey = this._window.localStorage.getItem(SiteConstants.hideTimezoneModalKey);
    return hideTimezoneModalKey === 'true';
  }

  getBreadcrumb(): Breadcrumb {
    const breadcrumb:Breadcrumb =  JSON.parse(this._window.localStorage.getItem(SiteConstants.breadcrumbKey)) as Breadcrumb;

    return breadcrumb ? breadcrumb: {} as Breadcrumb ;
  }

  setBreadcrumb(breadcrumb: Breadcrumb) {
    this._window.localStorage.setItem(SiteConstants.breadcrumbKey, JSON.stringify(breadcrumb));
  }

  updateUserGroup() {
    const user = this.getUserInfo();
    user.hasOnlineGroup = true;
    this.setUserInfo(user);
  }

  updateUserClass() {
    const user = this.getUserInfo();
    user.hasOnlineClass = true;
    this.setUserInfo(user);
  }

  setUserInfo(user: User) {
    console.log("setUserInfo:", user);
    user.photo = user.photo ?
      UtilityHelper.convertToImage(user.photo) : null
    this._window.localStorage.setItem(
      SiteConstants.userInfoKey,
      JSON.stringify(user)
    );
    this._user = user;
  }

  updateUserInfo(user:User){
    this.setUserInfo(user);

    if(!this.shouldHideTimezoneModal()){
      this.checkTimeZone(user.timeZone).subscribe(() => {
        this.checkOfHiddenEmailPhone();
      });
    }
    else {
      this.checkOfHiddenEmailPhone();
    }    
  }

  private checkOfHiddenEmailPhone() {
    if(this._user.isOptOutPeerEmail || this._user.isOptOutPeerMobileContact) {
      this.accountService.checkOfHiddenEmailPhone().pipe(
        tap((showDialog: boolean) => {
          if(showDialog) {
            this.showHiddenPhoneEmailDialog(this._user.firstName, this.accountsBaseUrl);
          }        
        }),
        switchMap((showDialog: boolean) => {
          if(showDialog) {
            return this.accountService.setHiddenEmailPhoneNotification();
          }
          else {
            return of(null);
          }        
        }),
        catchError(error => {
          // Handle errors here
          console.error('An error occurred:', error);
          return of(null); 
        })
      ).subscribe();    
    }   
        
  }

  private showHiddenPhoneEmailDialog(userName: string, accountsBaseUrl: string) {
    return this.modalService.show(
      HidePhoneEmailDialogComponent, { 
        class: 'modal-dialog-centered', 
        ignoreBackdropClick: true,
        backdrop: true,
        initialState: { 
          userName: userName,
          accountsBaseUrl: accountsBaseUrl 
        }});    
  }

  private checkTimeZone(profileTimeZone: string): Observable<boolean> {

    if (profileTimeZone) {
    
      const localOffset = moment().tz(moment.tz.guess()).utcOffset();
      const profileOffset = moment().tz(profileTimeZone).utcOffset();
      if (localOffset !== profileOffset) {
        const initialState = {
          message: this.translate.instant('DiffTZProfile')
        };
        const bsModalRef = this.modalService.show(DialogBoxComponent, { initialState, ignoreBackdropClick: true });
        return bsModalRef.onHide.pipe(
          tap(x => this.hideTimezoneModal()),
          switchMap(() => of(true)));
      }
    }    
    
    return of(false);
  }

  getUserInfo(): User {
    if (this._user) {
      return this._user;
    }
    const userJson = this._window.localStorage.getItem(
      SiteConstants.userInfoKey
    );

    if (userJson) {
      try {
        const userData = JSON.parse(userJson);
        return this._user = <User>userData;

      } catch {
        return null;
      }
    }

    return null;
  }

  getUserInfoAsync(refreshCache = false): Observable<User> {
    return new Observable<User>((subscriber) => {
      let user = this.getUserInfo();
      if (user && !refreshCache) {
        subscriber.next(user);
        subscriber.complete();
        return;
      }
      this.accountService.getUser().subscribe(
        (user) => {
          this.setUserInfo(user);
          subscriber.next(user);
          subscriber.complete();
          return;
        },
        (err) => {
          if (err.status === 404) {
            subscriber.next(null);
          }
          else {
            subscriber.error(err);
          }
          subscriber.complete();
        });
    });
  }

  getFirstLoginTime() {
    return new Observable<Date | null>(subscriber => {
      this.getUserInfoAsync(true).subscribe(result => {
        subscriber.next(result.firstLoginTime);
        subscriber.complete();
      });
    })
  }

  private isTokenExpired(token: string): boolean {
    try {
      if (decode(token).exp < Date.now() / 1000) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  clearSession() {
    console.log("clearSession");
    this._window.localStorage.removeItem(SiteConstants.tokenKey);
    this._window.localStorage.removeItem(SiteConstants.userInfoKey);
    this._window.localStorage.removeItem(SiteConstants.sesionDataKey);
    this._window.localStorage.removeItem(SiteConstants.hideTimezoneModalKey);
    this._window.localStorage.removeItem(SiteConstants.breadcrumbKey);
    this._user = null;
  }

  loadingUser(isLoading:boolean){
    this.gettingUser = isLoading;
    this.isLoadingUser$.next(isLoading);
  }

  isLoadingUser():boolean{
    return this.gettingUser;
  }
}

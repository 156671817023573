import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { TrainingDetails } from '../models/trainingDetails.model';
import { ConfigService } from 'src/app/shared/services/config.service';
import { LeaderGroup } from '../models/leader-group.model';
import { NamedId } from 'src/app/shared/models/namedId.model';
import { AddLeaderGroupModel } from '../models/add-leader-group.model';
import { LeaderGroupWithLeader } from '../models/leader-group-with-leader';
import { NewLeaderGroupNameModel } from '../models/new-leader-group-name.model';
import {MoveLeaderGroupMembers} from "../models/move-leader-group-members.model";

@Injectable()
export class TrainingService {
    private _trainingEndpoint;

    constructor(
      private _http: HttpClient,
      private configService:ConfigService
    ) {
      configService.configuration$.subscribe(config => {
        this._trainingEndpoint = `${config.apiEndpoint}trainings`;
      });
    }

    getTrainingDetails(): Observable<TrainingDetails> {
        return this._http.get<TrainingDetails>(`${this._trainingEndpoint}`);
    }

    getUserTrainingDetails(userId): Observable<TrainingDetails> {
        return this._http.get<TrainingDetails>(`${this._trainingEndpoint}/${userId}`);
    }

    getLeaderGroupMembers(): Observable<LeaderGroup[]> {
        return this._http.get<LeaderGroup[]>(`${this._trainingEndpoint}/leader-group-members`);
    }

    getUserLeaderGroupMembers(userId: number): Observable<LeaderGroup[]> {
      return this._http.get<LeaderGroup[]>(`${this._trainingEndpoint}/leader-group-members/${userId}`);
    }

    moveLeaderGroupMembers(info: MoveLeaderGroupMembers) {
        return this._http.post(`${this._trainingEndpoint}/move-leader-group-members`, info);
    }

    getAvailableTrainingLeaders() : Observable<NamedId<number>[]> {
      return this._http.get<NamedId<number>[]>(
        `${this._trainingEndpoint}/leader-group/available-training-leaders`);
    }

    addLeaderGroup(addleaderGroupModel: AddLeaderGroupModel) {
      return this._http.post(
        `${this._trainingEndpoint}/leader-group`, addleaderGroupModel);
    }

    updateLeaderGroup(leaderGroup: LeaderGroupWithLeader) {
      return this._http.put(
        `${this._trainingEndpoint}/leader-group`, leaderGroup);

    }

    deleteLeaderGroup(leaderGroupId: number): Observable<any> {
      return this._http.delete(`${this._trainingEndpoint}/leader-group/${leaderGroupId}`);
    }

    getLeaderGroups(): Observable<LeaderGroupWithLeader[]> {
      return this._http.get<LeaderGroupWithLeader[]>(
        `${this._trainingEndpoint}/leader-group`);
    }

    getNewLeaderGroupName() : Observable<NewLeaderGroupNameModel> {
      return this._http.get<NewLeaderGroupNameModel>(
        `${this._trainingEndpoint}/leader-group/new-name`);
    }
}

<div class="notifications-btn" #notifications>
  <button
    class="header-btn white-btn"
    aria-label="Notifications"
    (click)="loadNotificationsDetails()"
  >
    <span
      [matBadge]="count"
      matBadgePosition="after"
      matBadgeSize="small"
      matBadgeColor="secondary"
      [matBadgeHidden]="count === 0"
      class="badge-bell"
    >
      <fa-icon
        [icon]="['far', 'bell']"
        [ngStyle]="{
          color: count > 0 ? 'rgb(252, 175, 23)' : '#000000',
          fontSize: 'x-large'
        }"
      >
      </fa-icon>
    </span>
  </button>
  <div class="dropdown-menu-alert" [ngClass]="{ show: isMenuExpanded }">
    <div class="view" [ngClass]="{ mobile: isMobile() }" *ngIf="selectedAlert">
      <div class="content">
        <h2>{{ selectedAlert?.name }}</h2>
        <p>
          <strong>
            {{ "Date" | translate }}:&nbsp;
            <span class="color-grey">{{
              selectedAlert?.startDate  | date:'mediumDate'
              }}</span>
          </strong>
          <br/><br/>
          <span [innerHtml]="selectedAlert?.description"></span>
        </p>
        <app-notification-links [links]="selectedAlert.links"></app-notification-links>
      </div>
      <div class="buttons">
        <button
          class ="previous-button btn"
          (click)="previousAlert()"
          [disabled]="!hasPrevious">
          {{ "previous" | translate }}
        </button>
        <button class ="next-button btn" (click)="nextAlert()" [disabled]="!hasNext">
          {{ "Next" | translate }}
        </button>
      </div>
    </div>
    <div class="view no-notifications-to-show" *ngIf="!isLoading && !selectedAlert && (!alerts || alerts.length == 0)">
      {{ 'noNotificationToShow' | translate }}
    </div>
    <div class="items" [hidden]="hideNotificationsItems()">
      <h2>{{ "notifications" | translate }}</h2>

      <div *ngIf="isLoading">
          <mat-spinner [diameter]="30"></mat-spinner>
          <div class="text">{{ "Loading" | translate }}...</div>
      </div>
      <div class="no-notifications-to-show" *ngIf="!isLoading && (!alerts || alerts.length == 0)">
        {{ 'noNotificationToShow' | translate }}
      </div>
      <div
        class="menu-item"
        *ngFor="let alert of alerts; let alertIndex = index"
        (click)="selectAlert(alertIndex)"
        [ngClass]="{ active: alertIndex === selectedIndex }"
      >
        <div class="status-icon" [ngClass]="{ hidden: alert.isViewed }"></div>
        <div class="content truncate">
          <div class="date">{{ alert.startDate | date:'mediumDate' }}</div>
          <div [innerHtml]="alert.name" [ngClass]="{ unread: !alert.isViewed }"></div>
        </div>
        <div style="margin-left: auto;">
          <button class="notification-item-menu-btn" [matMenuTriggerFor]="notificationMenu" (click)="$event.stopPropagation();">
            <fa-icon
              [icon]="faCaretDown"
            ></fa-icon>
          </button>
          <mat-menu #notificationMenu="matMenu">
            <button mat-menu-item *ngIf="alert.isViewed"  class="notification-action-button" (click)="updateNotificationStatus(alert, personMaterialStatusEnum.New)">{{'markAsUnread' | translate}}</button>
            <button mat-menu-item *ngIf="!alert.isViewed"  class="notification-action-button" (click)="updateNotificationStatus(alert, personMaterialStatusEnum.Read)">{{'markAsRead' | translate}}</button>
            <button mat-menu-item  class="notification-action-button" (click)="updateNotificationStatus(alert, personMaterialStatusEnum.Delete)">{{'delete' | translate}}</button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>

export const English = 'en';
export const Spanish = 'sp'
export const SimplifiedChinese = 'sch';
export const TraditionChinese = 'tch'

export const Languages = [English, Spanish, SimplifiedChinese, TraditionChinese]

export function getLanguageCulture(language: string | number) {
    switch (language) {
        case "English":
            return English;
        case 1:
            return English;
        case "Español":
            return Spanish;
        case 2:
            return Spanish;
        case "简体中文":
            return SimplifiedChinese;
        case 3:
            return SimplifiedChinese;
        case "繁體中文":
            return TraditionChinese;
        case 4:
            return TraditionChinese;
        default:
            return English;
    }
}

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ImageHelper, ImageCompressionOptions } from '../../helpers/image';
import { AppSettingsService } from '../../services/appSettings.service';
import { GenderEnum } from '../../models/enums/gender.enum';
import { UtilityHelper } from '../../helpers/utils';

@Component({
  selector: 'app-photo-upload',
  templateUrl: './photo-upload.component.html',
  styleUrls: ['./photo-upload.component.css']
})
export class PhotoUploadComponent implements OnInit,OnChanges {
  showImgError:boolean;
  showUploadError: boolean;
  photo: File;
  showPlaceHolder:boolean;
  // reduces image size from 2Mb to 3Kb
  private _imageCompressionOptions: ImageCompressionOptions = {
  maxWidth: 150,
  maxHeight: 150,
  quality: 0.7
};
  imagedisplay;
  dummyImgMale = "../../../assets/images/profile-pic/male_placeholder.png";
  dummyImgFemale = "../../../assets/images/profile-pic/female_placeholder.png";

  constructor(private appsettingsService: AppSettingsService) { }

  @Input() profilePhoto:any;
  @Input() genderId: number;
  @Output()  public onPhotoChange: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    this.hideErrorMessages();
    this.setProfilePhoto();
  }

  ngOnChanges() {
    this.setImageDisplay(this.genderId);
  }

  public hideErrorMessages(){
    this.showImgError = false;
    this.showUploadError = false;
  }

  upload(files: FileList) {
    this.showImgError = false;
    this.showUploadError = false;

    const originalFile: File = files.item(0);
    if (originalFile.size > this.appsettingsService._appSettings.imageProfileMaxSize) {
      this.photo = null;
      this.showImgError = true;
    } else {
      this.showPlaceHolder = false;
      ImageHelper.compress(originalFile, this._imageCompressionOptions)
        .then((compressedBlob: Blob) => {
          if(compressedBlob.size > this.appsettingsService._appSettings.imageProfileMaxCompressedSize) {
              throw new Error("Your photo is too large to be saved");
          }
          this.photo = new File([compressedBlob], originalFile.name,
            { type: originalFile.type });
          this.displayImage(this.photo);
          this.showImgError = false;
        })
        .catch(err => {
          this.showUploadError = true;
          console.log(err);
        });
    }
  }

  displayImage(file) {
    var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.imagedisplay = reader.result;
        this.onPhotoChange.emit({photo:file, photoString:this.imagedisplay});
      }
  }

  private setProfilePhoto() {

    if (this.profilePhoto){
      this.imagedisplay = UtilityHelper.convertToImage(this.profilePhoto);
    }else{
        this.setImageDisplay(this.genderId)
    }
  }

  setImageDisplay(gender) {
    if (!this.photo) {
      if (gender == GenderEnum.Female){
        this.imagedisplay = this.dummyImgFemale;
      }else{
        this.imagedisplay = this.dummyImgMale;
      }
      this.showPlaceHolder = true;
    }else{
      this.showPlaceHolder = false;
    }
  }

}

<div class="link" *ngIf="link" [ngSwitch]="link?.materialFileTypeId">
  <a *ngSwitchCase="MaterialFileType.Link" [href]="link.fileLocationURL" target="_blank">
    {{link.name}}
  </a>

  <a *ngSwitchDefault [routerLink]="[]" (click)="download()" [class.downloading]="link?.isDownloading ?? false"
     [class.download-all]="allMaterials">
    {{link.name | translate}}
  </a>

  <mat-progress-bar [style.visibility]="link.isDownloading ? 'visible' : 'hidden'"
                    mode="buffer"
                    [value]="link.downloadProgress"></mat-progress-bar>
</div>

import { Injectable} from '@angular/core';
import { ConfigService } from './config.service';
import { Configuration } from '../models/configuration.model';
import { MsalInterceptorConfiguration, MsalGuardConfiguration } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, BrowserCacheLocation, InteractionType, RedirectRequest, LogLevel } from '@azure/msal-browser';
import { CultureService } from './culture.service';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}
@Injectable()
export class MsalConfigurationService {

  public isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
  public config: Configuration;
  constructor(
    private configService: ConfigService,
    private _cultuerService: CultureService) {
      this.configService.configuration$.subscribe(config => {
        this.config = config;
      });
  }

  loadMsalClientApplication():IPublicClientApplication  {

    const publicClient =
    new PublicClientApplication({
      auth: {
        clientId: this.config.b2cConfiguration.b2cPolicies.clientId,
        authority: this.config.b2cConfiguration.b2cPolicies.authorities.signUpSignIn.authority,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: this.config.b2cConfiguration.b2cPolicies.postLogoutRedirectUri,
        knownAuthorities: [this.config.b2cConfiguration.b2cPolicies.authorityDomain],
        navigateToLoginRequestUrl: false
      },
      cache: {
        cacheLocation: this.config.b2cConfiguration.b2cPolicies.cacheLocation.toLocaleLowerCase() == 'localstorage'? BrowserCacheLocation.LocalStorage: BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: this.isIE,
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false,
        },
      },
    });

    return publicClient;
  }

  loadMSALInterceptorConfig():MsalInterceptorConfiguration{
    const msalProtectedResourceMap = new Map<string, Array<string>>();
    this.config.b2cConfiguration.apiConfig.forEach( element =>
      msalProtectedResourceMap.set(element.uri, element.scopes)
    );

    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: msalProtectedResourceMap,
    };

  }

  loadMsalConfig():MsalGuardConfiguration{
    const scopes = new Array<string>();
    this.config.b2cConfiguration.apiConfig.forEach(element =>
      scopes.push(...element.scopes)
    );

    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: [...scopes],
        extraQueryParameters: { ui_locales: this._cultuerService.getCurrentLanguage().code }
      }
    };
  }

  tokenRequest():RedirectRequest {
    const scopes = new Array<string>();

    this.config.b2cConfiguration.apiConfig.forEach(element =>
      scopes.push(...element.scopes)
    );
    const tokenRequest:RedirectRequest = {
          scopes: [...scopes],
          extraQueryParameters: { ui_locales: this._cultuerService.getCurrentLanguage().code }
      };
    return tokenRequest;
  }
}


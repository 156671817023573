import { Directive, HostListener, ElementRef } from '@angular/core';
import * as _ from 'lodash';
@Directive({
    selector: '[focusInvalidInput]'
})
export class FocusInvalidInputDirective {
    constructor(private el: ElementRef) { }

    @HostListener('submit')
    onFormSubmit() {
        const that = this;
        const debounce = _.debounce(() => this.setFocus(this), 0);
        return debounce();
    }

    setFocus(that) {
        const invalidControl = that.el.nativeElement.querySelector('.ng-invalid-input');

        if (invalidControl) {
            invalidControl.focus();
        }
    }
}
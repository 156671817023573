import { Injectable } from '@angular/core';
import { SiteConstants } from '../constants/siteConstants';

@Injectable()
export class MessagingService {

  constructor() { }

  startMail(email: string, subject = "BSFOnline"): void {
    window.location.href = `mailto:?bcc=${email}&subject=${subject}`;
  }

  startChat(mobileAreaCode: string, mobileNumber: string): void {
    window.open(SiteConstants.externalUrlWhatsAppApi + `${mobileAreaCode}${mobileNumber}`, "_blank");
  }

  startGroupChat(): void {
    window.open(SiteConstants.externalUrlWhatsAppGroup);
  }
}

<div class="" id="switchgroup" tabindex="-1" aria-labelledby="switchgroup">
  <div class="">
    <div class="modal-content d-flex justify-content-center text-center" *ngIf="forGroup">
      <div class="modal-header flex-column justify-content-center align-items-center d-flex" *ngIf="isGroupMember">
        <h3 class="modal-title">{{"CurrentDiscussion" | translate}}:</h3>
        <h5>{{ activeGroup.groupCode }} - {{ activeGroup.displayName }}</h5>
      </div>
      <div class="modal-body">
        <h3>{{(isGroupMember ? 'WantToSwitch' : 'JoinGroup') | translate}}:</h3>
        <h5>{{ switchTo.groupCode }} - {{ switchTo.meetingNickName }}?</h5>
        <p class="gray-text" >
          {{'GL' | translate}}: {{ switchTo.leader }} &bull;
          {{'MeetingType' | translate}}: {{ (switchTo.meetingType + 'MeetingType') | translate }} &bull;
          {{'Lang' | translate}}: {{ switchTo.spokenLanguage | translate }} &bull;
          {{'AgeRange' | translate}}: {{ switchTo.ageRange | translate }}           
        </p>        
        <div class="d-flex align-items-center justify-content-center">
          <p class="switchgroup-time">{{'GroupDiscuss' | translate}}: {{ switchTo.meetingDayOfWeekConverted }}, {{ switchTo.meetingTimeConverted }} {{ getTimeZoneCode(userTimezone) }}</p>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="blue-btn modal-button" data-dismiss="modal" (click)="switchToMeeting()">{{(isGroupMember ? 'SwitchGroup' : 'JoinGroup') | translate}}</button>
        <button type="button" class="blue-btn modal-button" data-dismiss="modal" (click)="closeMe()">{{'Cancel' | translate}}</button>
      </div>
    </div>
    <div class="modal-content d-flex justify-content-center text-center" *ngIf="forClass">
      <div class="modal-header flex-column justify-content-center align-items-center d-flex" *ngIf="isClassMember">
        <h3 class="modal-title">{{"CurrentLM" | translate}}:</h3>
        <h5>{{ activeClass.meetingCode }} - {{ activeClass.meetingNickname }}</h5>
      </div>
      <div class="modal-body">
        <h3>{{ (isClassMember ? 'WantToSwitch' : 'JoinGroup') | translate }}:</h3>
        <h5>{{ switchTo.groupCode }} - {{ switchTo.meetingNickName }}?</h5>
        <p class="gray-text" >
          {{'GL' | translate}}: {{ switchTo.leader }} &bull;
          {{'MeetingType' | translate}}: {{ switchTo.meetingType }} &bull;
          {{'Lang' | translate}}: {{ switchTo.spokenLanguage }}
        </p>        
        <div class="d-flex align-items-center justify-content-center">
          <p class="switchgroup-time">{{'LeadersMeeting' | translate}}: {{ switchTo.meetingDayOfWeekConverted }}, {{ switchTo.meetingTimeConverted }} {{ getTimeZoneCode(userTimezone) }}</p>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center flex-column flex-sm-row">
        <button type="button" class="blue-btn modal-button noWrap mb-3" data-dismiss="modal" (click)="switchToMeeting()">{{(isClassMember ? 'SwitchClass' : 'JoinClass') | translate}}</button>
        <button type="button" class="blue-btn modal-button mt-3 mt-md-0 mb-3" data-dismiss="modal" (click)="closeMe()">{{'Cancel' | translate}}</button>
      </div>
    </div>
  </div>
</div>

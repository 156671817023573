import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InviteFriend } from '../../models/inviteFriend.model';

@Component({
  selector: 'app-invite-friend',
  templateUrl: './invite-friend.component.html',
  styleUrls: ['./invite-friend.component.css']
})
export class InviteFriendComponent implements OnInit {

  model: InviteFriend = new InviteFriend();

  constructor(
    public dialogRef: MatDialogRef<InviteFriendComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() { }

  closeMe(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    this.dialogRef.close(this.model);
  }

}

import moment from 'moment-timezone';

const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
export class TimeHelper {

    static getLocalTime(timeZone: string, date = new Date()) {
        if (!timeZone) {
            return;
        }
        const m = moment(date).tz(timeZone);
        return m.format("hh:mma");
    }

    static isTimezoneAffectedByDst(timeZone: string) {
        const summerDate = '2001-07-01';
        const winterDate = '2001-01-01';
        return moment.tz(summerDate, timeZone).isDST() || moment.tz(winterDate, timeZone).isDST();
    }

    static getZone(timezone: string) {
        if (!timezone) {
            return;
        }
        return moment(new Date()).tz(timezone).format("z");
    }

    static getUTCDateTime(date: Date) {
        var utcDateTime = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getUTCHours(), date.getUTCMinutes())
        return new Date(utcDateTime);
    }

    static getWeekFirstDate(date: Date = null): Date {
        let today = date || new Date();

        today.setHours(0, 0, 0, 0);

        let firstDayOfWeek = today.valueOf() - ((today.getDay() - 1) * this.microsecondsPerDay);

        return new Date(firstDayOfWeek);
    }

    static get microsecondsPerDay(): number {
        return 24 * 60 * 60 * 1000;
    }

    static getTimeZoneCode(timeZone: string) {
        return moment.tz(timeZone).format('z');
    }

    static nextWeekDayAfterDate(base: any, dayOfWeek: number) {
        const baseDate = moment(base);
        const start = baseDate.weekday();
        if (dayOfWeek <= start) {
            dayOfWeek += 7;
        }
        return baseDate.add(dayOfWeek - start, "day");
      }

    static getDateFromDayOfWeekAndTime(dayOfWeek: number, time: any, timeZone: string) {
        const baseDate = this.nextWeekDayAfterDate(moment(), dayOfWeek);
        const baseDateString = baseDate.format("YYYY-MM-DD");
        return moment.tz(baseDateString + "T" + time, timeZone).tz(timeZone);
    }

    static dateRangesOverlap = (startA: moment.Moment, endA: moment.Moment,
        startB: moment.Moment, endB: moment.Moment) : boolean =>
        startA.isBefore(endB) && endA.isAfter(startB);

    static formatTime(date: Date) : string {
        return `${date.getHours()}:${date.getMinutes()}`;
    }

    static getIsoWeekdayNumber(weekDayString: string): number {
        return weekdays.indexOf(weekDayString) + 1;
    }

    public static timeOfTheDay = {
        morning: { start:"06:00 AM", end: "12:00 PM" },
        afternoon: { start:"12:00 PM", end: "06:00 PM" },
        evening:  { start:"06:00 PM", end: "12:00 AM" },
        night:  { start:"12:00 AM", end: "06:00 AM" }
    }
}

<div  class="searchableDropdown">
  <div (mousedown)="showResults($event)"
       (mouseleave)="hideResults($event)" id="TZDropdown" class="dropdown-content">

       <input class="form-control"  type="text"
        autocomplete="off"
        [value]="currentTimeZone" id="TZInput"
        (keyup)="filterTimeZone($event)"
        (blur)="getEventBlur($event)">

        <div  id="tzResultsList">
          <span *ngFor='let grp of allTimezonesList'>
            <div><b>{{grp.countryName}}</b></div>
            <span id="tzSearchResuts" (click)='selectTimeZone(item.zoneName, grp.countryName, item.displayName.split("_").join(" "))'
              *ngFor='let item of grp.timeZones'>
              {{grp.countryName}} - {{item.displayName.split('_').join(' ')}}
            </span>
          </span>
        </div>
  </div>
</div>

import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FilesService } from '../../services/files.service';
import { HttpEventType } from '@angular/common/http';
import { FileUploadResult, FileUploadState } from '../../models/fileUploadResult.model';
import { UtilityHelper } from '../../helpers/utils';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

  @ViewChild('fileUpload')
  private _fileUploadElement: ElementRef<HTMLInputElement>;

  @Output()
  public onFileSelected: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public acceptedFormats: string[] = [];

  private _filePath: string;
  private _file: File;

  constructor(private _fileUploadService: FilesService) { }

  ngOnInit() {
  }

  triggerUpload() {
    this._fileUploadElement.nativeElement.click();
  }

  onFileChange(files: FileList) {
    if (files.length === 0) {
      return;
    }

    this._file = files.item(0);
    this.onFileSelected.emit(this._file.name);
  }

  upload(fileSaveName:string, filePath:string  ): Promise<FileUploadResult> {
    if (!this._file) {
      let result = new FileUploadResult();
      result.error = "No File Selected";
      result.state = FileUploadState.Failed

      return Promise.resolve(result);
    }
    this._filePath = filePath;
    const fileExtension = UtilityHelper.getFileExtensionFromFilePath(this._file.name);
    const format = this.acceptedFormats.find(x => x.toUpperCase() == `.${fileExtension.toUpperCase()}`);
    if (!format) {
      let result = new FileUploadResult();
      result.error = "The selected file format is unsupported";
      result.state = FileUploadState.Failed

      return Promise.resolve(result);
    }
    fileSaveName += `.${fileExtension}`;
    fileSaveName = fileSaveName.replace(/[/\\?%*:|"<>]/g,"_");

    var formData = new FormData();
    formData.append('file', this._file, fileSaveName);
    formData.append('path', this._filePath);

    return this._fileUploadService.uploadLessonMaterailFile(formData).toPromise().then(event => {
      if (event.type === HttpEventType.UploadProgress) {
        let progress = Math.round(100 * event.loaded / event.total);
      }
      else if (event.type === HttpEventType.Response) {

        let result = new FileUploadResult();
        result.state = FileUploadState.Success
        result.filePath = this._filePath + fileSaveName;

        return Promise.resolve(result);
      }
    }).catch(err => {
      let result = new FileUploadResult();
      result.error = err;
      result.state = FileUploadState.Failed;

      return Promise.resolve(result);
    });
  }



}

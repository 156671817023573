import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-day-picker',
  templateUrl: './day-picker.component.html',
  styleUrls: ['./day-picker.component.css']
})
export class DayPickerComponent implements OnInit {
  
  @Output()
  onChange: EventEmitter<number> = new EventEmitter();
  
  @Input()
  value: number;

  constructor() { }

  ngOnInit() {
  }

  change(event) {
    this.onChange.emit(event.target.value);
  }

  

}

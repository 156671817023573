import { Pipe, PipeTransform } from "@angular/core";
import { UtilityHelper } from "../helpers/utils";



@Pipe({
    name: 'weekday'
})
export class WeekDayPipe implements PipeTransform {

    transform(dayofweek: number) {

        if (dayofweek < 0 || dayofweek > 7) {
            return
        }

        return UtilityHelper.getDayOfWeek(dayofweek)
    }
}
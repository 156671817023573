import { Component, Input, OnInit } from '@angular/core';
import { MaterialFileType } from 'src/app/shared/models/enums/materialFileType.enum';
import { NotificationLinkModel } from 'src/app/shared/models/notification-link.model';

@Component({
  selector: 'app-notification-links',
  templateUrl: './notification-links.component.html',
  styleUrls: ['./notification-links.component.scss']
})
export class NotificationLinksComponent implements OnInit {

  constructor() {
  }

  private _links?: NotificationLinkModel[];

  @Input()
  get links(): NotificationLinkModel[] {
    return this._links;
  }

  set links(value: NotificationLinkModel[]) {
    this._links = value;
  }

  ngOnInit(): void {
  }

  showDownloadAll():boolean{
    return this.links.length > 1;
  }

  getAllMaterialsLink(){
    const allMaterialsLink =
    {
      name: 'DownloadAll',
      materialId: 0,
      materialFileTypeId: MaterialFileType.Zip,
      downloadProgress : 0,
      isDownloading: false
    } as NotificationLinkModel;
    return allMaterialsLink;
  }
}

import {
  AbstractControl,
  AsyncValidatorFn,
  Validator,
  Validators,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';

import { from, isObservable, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export type ValidationResult = {[validator: string]: string | boolean};

export type AsyncValidatorArray = Array<Validator | AsyncValidatorFn>;

export type ValidatorArray = Array<Validator | ValidatorFn>;

const normalizeValidator =
    (validator: Validator | ValidatorFn): ValidatorFn | AsyncValidatorFn => {
  const func = (validator as Validator).validate.bind(validator);
  if (typeof func === 'function') {
    return (c: AbstractControl) => func(c);
  } else {
    return <ValidatorFn | AsyncValidatorFn> validator;
  }
};

export const composeValidators =
    (validators: ValidatorArray): AsyncValidatorFn | ValidatorFn => {
  if (validators == null || validators.length === 0) {
    return null;
  }
  return Validators.compose(validators.map(normalizeValidator));
};

export const validate = (
  validators: ValidatorArray,
  asyncValidators: AsyncValidatorArray
): (control: AbstractControl) => Observable<ValidationResult | null> => {
  return (control: AbstractControl): Observable<ValidationResult | null> => {
    const synchronousValid = (): ValidationResult | null => {
      const errors = composeValidators(validators)(control);
      return errors ? (errors as ValidationResult) : null;
    };

    if (asyncValidators) {
      const asyncValidator = composeValidators(asyncValidators);
      const asyncResult = asyncValidator(control);

      const observableAsyncResult = isObservable(asyncResult)
        ? asyncResult
        : from(Promise.resolve(asyncResult));

      return observableAsyncResult.pipe(
        map((v: ValidationErrors | null) => {
          const secondary = synchronousValid();
          if (secondary || v) {
            return { ...secondary, ...v } as ValidationResult;
          }
          return null;
        })
      );
    }

    if (validators) {
      return of(synchronousValid());
    }

    return of(null);
  };
};

export const message = (validator: ValidationResult, key: string): string => {
  switch (key) {
    case 'required':
      return 'This field is required';
    case 'pattern':
      return 'Value does not match required pattern';
    case 'minlength':
      return 'Value must be N characters';
    case 'maxlength':
      return 'Value must be a maximum of N characters';
  }

  switch (typeof validator[key]) {
    case 'string':
      return <string> validator[key];
    default:
      return `Validation failed: ${key}`;
  }
};
import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css']
})
export class TimePickerComponent implements OnInit, OnChanges {

  @Input() allowClear = true;

  @Output()
  onChange: EventEmitter<Date | null> = new EventEmitter();

  @Input()
  value: Date; // TODO change to date.

  @Input()
  placeHolder: string

  @Input()
  disable: boolean

  constructor() { }

  time: number | string

  ngOnChanges(changes: SimpleChanges): void {
    if (this.value) {
      const hoursInMinutes = (this.value.getHours()) * 60;
      const minutes = this.value.getMinutes();
      const total = hoursInMinutes + minutes;

      this.time = total / 60;
    }

    if (this.placeHolder && !this.value) {
      this.time = "";
    }
  }

  ngOnInit() {
  }

  change($event) {
    if ($event.target.value == "") {
      this.onChange.emit(null);
    }
    const timeInMinutes = $event.target.value * 60;
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    const date = new Date();
    date.setHours(hours)
    date.setMinutes(minutes)
    date.setSeconds(0);
    this.onChange.emit(date);
  }

}

import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import {Observable, of, throwError} from "rxjs";
import {catchError, map} from 'rxjs/operators';
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  
  constructor(    
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
    return next.handle(req).pipe(
      catchError((error) => {
        const err = <HttpErrorResponse>error;
        console.error(err);
        if(err.status === 502) {  
            window.location.href = '/maintenance.html';            
        }
        else {
            return throwError(error);        
        }
        
      })
    )
  }
}
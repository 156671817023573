import { Component, OnInit, OnDestroy, Input, OnChanges, NgZone } from '@angular/core';
import { LocalTimePipe } from '../../pipes/localTime.pipe';
import { UtilityHelper } from '../../helpers/utils';
import { TimeHelper } from '../../helpers/timeHelper';

@Component({
  selector: 'app-local-clock',
  template: `{{localClock}} {{" "}} {{getTimeZoneAbbreviation()}}`
})
export class LocalClockComponent implements OnInit, OnDestroy {
  @Input()
  timeZone: string;

  public localClock: string;
  private timer;

  constructor(private zone: NgZone,
    private _localTimePipe: LocalTimePipe
  ) { }


  ngOnInit() {
    this.timer = UtilityHelper.executeAndSetInterval(() => {
      this.localClock = this._localTimePipe.transform(this.timeZone);
    }, 60000, this.zone);
  }

  getTimeZoneAbbreviation() {
    return TimeHelper.getZone(this.timeZone);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }
}

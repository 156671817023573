import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NamedId } from '../models/namedId.model';
import { LanguageViewModel } from '../models/LanguageViewModel.model';
import { ConfigService } from './config.service';

@Injectable()
export class LanguageService {
    private _languageEndpoint: string;

    constructor(
      private _http: HttpClient,
      private configService:ConfigService
    ) {
      configService.configuration$.subscribe(config => {
        this._languageEndpoint = `${config.apiEndpoint}languages`;
      });
    }

    public getSpokenLanguages(): Observable<NamedId<number>[]> {
        return this._http.get<NamedId<number>[]>(`${this._languageEndpoint}/spoken_languages`);
    }

    public getDisplayLanguages(): Observable<NamedId<number>[]> {
        return this._http.get<NamedId<number>[]>(`${this._languageEndpoint}/display_languages`);
    }

    public getSpokenLanguagesForUpload(): Observable<LanguageViewModel[]> {
      return this._http.get<LanguageViewModel[]>(`${this._languageEndpoint}/upload_spoken_languages`);
  }

  public getDisplayLanguagesForUpload(): Observable<LanguageViewModel[]> {
      return this._http.get<LanguageViewModel[]>(`${this._languageEndpoint}/upload_display_languages`);
  }
}

<div id="container">
  <div id="content">
    <form [formGroup]="form" fxLayout="column" fxLayoutGap="10px">
      <h1 class="test-env-header">{{'testEnvironmentHeader' | translate }}</h1>
      <p class="test-env-content">{{'testEnvironmentParagraph1' | translate }} <a [href]="prodRedirectUrl">{{'testEnvironmentParagraph2' | translate }}</a></p>
      <div fxFlexLayout="row" class="input-container">
        <div class="form-group" fxFlex.xs="100" fxFlex.sm="45" fxFlex.gt-sm="35">
          <label>{{'Password' | translate }}</label>
          <input color="accent" type="password" class="form-control"
            aria-label="Password" formControlName="password">
            <mat-error *ngIf="form.controls.password.hasError('required') && isSubmitted" class="text-error">
              {{'testEnvironmentInvalidPassword' | translate }}
            </mat-error>
            <mat-error *ngIf="form.controls.password.hasError('noMatch') && isSubmitted" class="text-error">
              <div>
                {{'passwordIncorrect' | translate }}
              </div>
              <div>
                {{'passwordLastUpdatedBy' | translate: { 
                  time: formatTime(passwordSetting.lastModificationTime || passwordSetting.creationTime), 
                  name: passwordSetting.lastModifierPersonName || passwordSetting.creatorPersonName } }}
              </div>              
            </mat-error>
          </div>
      </div>
      <div>
        <button class="btn blue-btn" (click)="onPasswordSubmit()">{{'Submit' | translate }}</button>
      </div>
    </form>
  </div>
</div>
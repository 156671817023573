import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { RoutingService } from '../shared/services/routing.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.css'],
})
export class LoadingPageComponent implements OnInit, OnDestroy {
  _unsubscribe: Subject<void> = new Subject<void>();
  loading = true;
  constructor(
    private routingService: RoutingService,
  ) { }

  ngOnInit(): void {
    this.routingService.gotoUserDefaultPage();
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}

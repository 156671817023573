import { Component, OnInit } from '@angular/core';
import { CultureService } from '../../services/culture.service';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-default-footer',
  templateUrl: './default-footer.component.html',
  styleUrls: ['./default-footer.component.css']
})
export class DefaultFooterComponent implements OnInit {
  isTest: boolean = false;
  isCorrectPasswordForTestEnvironment: string | undefined;

  constructor(private _cultureService: CultureService,
    private _router: Router,
    private _configService:ConfigService,
  ) {
    this._configService.configuration$.subscribe(config => {
      this.isTest = config.isTest;
    })
    this.isCorrectPasswordForTestEnvironment = sessionStorage.getItem('isCorrectPasswordForTestEnvironment')

    // for navigation from the test environment page to the main page,
    // because in this case, the component is not rerended, and we cannot get the updated session storage value
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isCorrectPasswordForTestEnvironment = sessionStorage.getItem('isCorrectPasswordForTestEnvironment')
      }
    });  
  }

  ngOnInit() {
  }

  changeLang(culture: string) {
    const currentLocale = this._cultureService.getCurrent();

    // Fix for AB#1092 to reload freshdesk widget on culture change, #GP@2020-12-30
    if(currentLocale !== culture) {
        this._cultureService.setCurrent(culture,true);
        window.location.reload();
    }
  }

  shouldEnterPasswordToAccess() {
    return this.isTest && this.isCorrectPasswordForTestEnvironment != 'true'
  }
}

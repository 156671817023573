import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-approve-candidate-leadership-application-modal',
  templateUrl: './approve-candidate-leadership-application-modal.component.html',
  styleUrls: ['./approve-candidate-leadership-application-modal.component.css']
})
export class ApproveCandidateLeadershipApplicationModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ApproveCandidateLeadershipApplicationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {

  }

  select(): void {
    this.dialogRef.close({action: 'proceed',});
  }

  cancel(): void {
    this.dialogRef.close({action: 'cancel'});
  }

}


import { Injectable } from '@angular/core';

import { Position } from '../models/position.enum';
import { SessionService } from './session.service';

@Injectable()
export class RolePermissionService {

    constructor(private _sessionService: SessionService) {
    }

    isHQAdmin() {
        return this.userPositionId() == Position.HQAdmin;
    }

    isHQAdminViewOnly() {
      return this.userPositionId() == Position.HQAdminVO;
  }

    isRegionalDirectorOrAbove() {
        return this.userPositionId() >= Position.OnlineRegionalDirector;
    }

    isAreaPersonnelOrAbove() {
        return this.isRegionalDirectorOrAbove() || this.userPositionId() == Position.OnlineAreaPersonel;
    }

    isAOtOrAbove() {
        return this.isAreaPersonnelOrAbove() ||
            this.userPositionId() == Position.OnlineTrainer ||
            this.userPositionId() == Position.AssistantOnlineTrainer
    }

    isGroupLeaderOrAbove() {
        return this.isAOtOrAbove() || this.userPositionId() == Position.OnlineGroupLeader
    }

    private userPositionId() {
        return this._sessionService.getUserInfo().positionId
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-leaders-application-withdrawal-modal',
  templateUrl: './leaders-application-withdrawal-modal.component.html',
  styleUrls: ['./leaders-application-withdrawal-modal.component.css']
})
export class LeadersApplicationWithdrawalModalComponent implements OnInit {

  comment?: string;

  constructor(
    public dialogRef: MatDialogRef<LeadersApplicationWithdrawalModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {

  }

  select(): void {
    this.dialogRef.close({action: 'proceed', comment: this.comment});
  }

  cancel(): void {
    this.dialogRef.close({action: 'cancel'});
  }

}

  import { Injectable, Inject } from "@angular/core";
import { HttpBackend, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { FindAGoupFilter } from '../../find-a-group/findagroupFilter.model';
import LeaveGroup from '../models/leaveGroup.model';
import { NamedId } from "src/app/shared/models/namedId.model";
import { DiscussionMeeting } from "src/app/shared/models/discussionMeeting.model";
import { Paged } from "src/app/shared/models/paged.model";
import { ConfigService } from "src/app/shared/services/config.service";


@Injectable()
export class DiscussionMeetingService {
  private _discussionMeetingEndpoint;
  private _userEndpoint;
  private _countryEndPoint;
  private _httpNoToken:HttpClient;

  constructor(
    private _http:HttpClient,
    private configService:ConfigService,
    private handler:HttpBackend
    ) {
      this.configService.configuration$.subscribe(config => {
        this._discussionMeetingEndpoint = `${config.apiEndpoint}discussionMeetings`;
      });
      this._httpNoToken = new HttpClient(this.handler);
  }


  public getDisussionMeetings(filter: FindAGoupFilter, isPublicSearch?:boolean): Observable<Paged<DiscussionMeeting>> {
    if(isPublicSearch){
      return this._httpNoToken.post<Paged<DiscussionMeeting>>(`${this._discussionMeetingEndpoint}/available`, filter);
    }
    return this._http.post<Paged<DiscussionMeeting>>(`${this._discussionMeetingEndpoint}/available`, filter);
  }

  public getAciveGroup(userId: number): Observable<NamedId<number>> {
    return this._http.get<NamedId<number>>(`${this._discussionMeetingEndpoint}/activegroup/${userId}`);
  }

  public switchMe(userId: number, from: number, to: number, requestByUserId: number): Observable<any> {
    var data = { UserId: userId, "FromId": from, "ToId": to, "RequestedById": requestByUserId };
    return this._http.post(`${this._discussionMeetingEndpoint}/switchMe`, data);
  }

  public switchGroup(leaveGroup: LeaveGroup, joinGroupId: number): Observable<any> {
    return this._http.put(`${this._discussionMeetingEndpoint}/switch_group?joinGroupId=${joinGroupId}`, leaveGroup);
  }

  public leaveGroup(leaveGroup: LeaveGroup, personId:number): Observable<any> {
    if(personId){
      return this._http.put(`${this._discussionMeetingEndpoint}/leave_group/${personId}`, leaveGroup);
    }else{
      return this._http.put(`${this._discussionMeetingEndpoint}/leave_group`, leaveGroup);
    }
  }

  public joinGroup(groupId: number): Observable<any> {
    return this._http.put(`${this._discussionMeetingEndpoint}/join_group`, groupId);
  }

  public groupHasCapacity(groupId: number): Observable<boolean> {
    return this._httpNoToken.get<boolean>(`${this._discussionMeetingEndpoint}/group_has_capacity/${groupId}`);    
  }

  public addUserToGroup(groupId, userId) {
    return this._http.put(`${this._discussionMeetingEndpoint}/add_to_group?groupId=${groupId}&userId=${userId}`, null);
  }
}

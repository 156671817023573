import { Component, OnInit, Input, Output, ViewChild, Optional, Inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel, NG_VALIDATORS, NG_ASYNC_VALIDATORS, NgForm } from '@angular/forms';
import { ElementBase } from './element-base';

@Component({
  selector: 'app-input-box',
  templateUrl: './input-box.component.html',
  styleUrls: ['./input-box.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputBoxComponent,
    multi: true,
  }],
})
export class InputBoxComponent extends ElementBase<string> {

  @Input() public label: string;
  @Input() public placeholder: string = "";
  @Input() public type: 'text' | 'email' | 'password' | 'tel' | 'number';
  @Input() public form: NgForm;
  @Input() public readonly = false;
  @Input() public required = false;
  @Input() public id: string;
  @Input() public name: string;
  @Input() public maxlength: string;
  @Input() public directive: string;

  @ViewChild(NgModel) model: NgModel;

  public placeHolderIdentifier = `form-text-${identifier++}`;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }
}

let identifier = 0;


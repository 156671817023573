import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DiscussionMeetingService } from '../common/services/discussion-meeting.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { NamedId } from '../../shared/models/namedId.model';
import { LeadersMeeting } from '../../online-class/common/models/leadersMeetings.model';
import { TimeHelper } from '../../shared/helpers/timeHelper';

@Component({
  selector: 'app-meeting.switch.dialog',
  templateUrl: './meeting.switch.dialog.component.html',
  styleUrls: ['./meeting.switch.dialog.component.css']
})
export class MeetingSwitchDialogComponent implements OnInit {
  forGroup: boolean
  forClass: boolean
  activeGroup: NamedId<number> = {id: -1, displayName: ''};
  activeClass: LeadersMeeting
  userTimezone: string;
  switchTo: any;
  isGroupMember: boolean
  isClassMember: boolean

  constructor(public dialogRef: MatDialogRef<MeetingSwitchDialogComponent>,
              private _meetingService: DiscussionMeetingService,
              private _sessionService: SessionService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (this.data.searchType === 'group') {
      this.forGroup = true;
      this.activeGroup = this.data.activeGroup;
      this.isGroupMember = this.activeGroup ? this.activeGroup.id !== -1 : null;
    }
    if (this.data.searchType === 'class') {
      this.forClass = true;
      this.activeClass = this.data.activeClass;
      this.isClassMember = this.activeClass != null;
    }

    this.switchTo = this.data.selectedMeeting;
    this.userTimezone = this.data.userTimezone;
  }

  closeMe(): void {
    this.dialogRef.close({action: 'cancel'});
  }

  switchToMeeting(): void {
    this.dialogRef.close({action: 'proceed'});
  }

  getTimeZoneCode(timeZone: string) {
    return TimeHelper.getTimeZoneCode(timeZone);
  }
}

import { HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { DownloadModel } from 'src/app/shared/models/download.model';
import { MaterialFileType } from 'src/app/shared/models/enums/materialFileType.enum';
import { NotificationLinkModel } from 'src/app/shared/models/notification-link.model';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-notification-link',
  templateUrl: './notification-link.component.html',
  styleUrls: ['./notification-link.component.scss']
})
export class NotificationLinkComponent implements OnInit {
  MaterialFileType = MaterialFileType

  @Input()
  link?: NotificationLinkModel;

  @Input()
  allMaterials?: NotificationLinkModel[];

  constructor(private notificationService: NotificationService,
              private _snackBar: MatSnackBar,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
  }

  download() {
    if (this.link.isDownloading) {
      return;
    }

    this.link.downloadProgress = 0;
    this.link.isDownloading = true;

    const request = this.allMaterials
      ? this.notificationService.downloadFilesZip(this.allMaterials.map(x => ({materialId: x.materialId, fileLocationURL: x.fileLocationURL}) as DownloadModel))
      : this.notificationService.downloadLinkFile(this.link.materialId, this.link.fileLocationURL);

    request
      .subscribe((event) => {
          if (event.type === HttpEventType.DownloadProgress) {
            this.link.downloadProgress = event.total ? Math.round((100 * event.loaded) / event.total) : 0;
          } else if (event.type === HttpEventType.Response) {
            this.link.downloadProgress = 100;
            if(this.allMaterials){
              this.downloadZip(event as HttpResponse<Blob>);
            }else{
              this.downloadFile(event.body);
            }          }
        },
        (error: HttpErrorResponse) => {
          const message = error.status === 404 ? this.translateService.instant('fileNotFound') : this.translateService.instant('errorOccurred');
          this._snackBar.open(message, 'OK', {duration: 3000});
        },
        () => {
          this.link.isDownloading = false;
        });
  }

  private downloadZip(data: HttpResponse<Blob>) {
    if (data && data.body) {
      const downloadedFile = new Blob([data.body], {type: data.body.type});
      const objectURL = URL.createObjectURL(downloadedFile);

      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      a.download = 'Files.zip';
      a.href = objectURL;
      a.classList?.add('download-link');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  private downloadFile(response){
    if(response && response.url){
      const url = new URL(response.url);
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.style.display = 'none';
      link.download = _.last(_.split(url.pathname, '/'));
      link.href = response.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
}

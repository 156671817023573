import { AppSettingsService } from 'src/app/shared/services/appSettings.service';
import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { ReplaySubject, Observable, fromEvent } from "rxjs";
import { environment } from "src/environments/environment";
import { Configuration } from "../models/configuration.model";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private _configuration: Configuration;
  private configurationSubject: ReplaySubject<Configuration> = new ReplaySubject<Configuration>();
  public configuration$ = this.configurationSubject.asObservable();

  private _http: HttpClient;

  constructor( handler: HttpBackend) {
    this._http = new HttpClient(handler);
  }

  public updateConfiguration(configuration: Configuration) {
    this._configuration = configuration;
    this.configurationSubject.next(this._configuration);
  }

  public load(timestamp: string | null = null) {
    let url = '/assets/config/configuration.json' + (timestamp ? '?' + timestamp : '');

    return new Promise((resolve, reject) => {
      this._http.get(url)
        .subscribe((responseData: any) => {
          if (responseData && responseData.hasOwnProperty("apiEndpoint")) {
            let config = new Configuration(responseData as Configuration);
            this.updateConfiguration(config);
          }
          resolve(true);
        }, error => {
          reject(false)
        })
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-video-box',
  templateUrl: './video-box.component.html'
})

export class VideoBoxComponent implements OnInit{

  @Input() public videoLink: string;
  @Input() public type: string;
  constructor(){
  }
  ngOnInit() {

  }



}

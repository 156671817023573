export interface CountryTimezone {
    countryName: string,
    phoneCode: number,
    timezones: Timezone[]
}

export interface Timezone {
    zoneName: string,
    displayName: string
}

const countries: CountryTimezone[] = [
  {
    countryName: "Andorra",
    phoneCode: 376,
    timezones: [
      {
        zoneName: "Europe/Andorra",
        displayName: "Europe/Andorra (AD) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "United Arab Emirates",
    phoneCode: 971,
    timezones: [
      { zoneName: "Asia/Dubai", displayName: "Asia/Dubai (AE) +04 UTC +04:00" },
    ],
  },
  {
    countryName: "Afghanistan",
    phoneCode: 93,
    timezones: [
      {
        zoneName: "Asia/Kabul",
        displayName: "Asia/Kabul (AF) +0430 UTC +04:30",
      },
    ],
  },
  {
    countryName: "Antigua and Barbuda",
    phoneCode: 1268,
    timezones: [
      {
        zoneName: "America/Antigua",
        displayName: "America/Antigua (AG) AST UTC -04:00",
      },
      {
        zoneName: "America/Port_of_Spain",
        displayName: "America/Port of Spain (AG) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Anguilla",
    phoneCode: 1264,
    timezones: [
      {
        zoneName: "America/Anguilla",
        displayName: "America/Anguilla (AI) AST UTC -04:00",
      },
      {
        zoneName: "America/Port_of_Spain",
        displayName: "America/Port of Spain (AI) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Albania",
    phoneCode: 355,
    timezones: [
      {
        zoneName: "Europe/Tirane",
        displayName: "Europe/Tirane (AL) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Armenia",
    phoneCode: 374,
    timezones: [
      {
        zoneName: "Asia/Yerevan",
        displayName: "Asia/Yerevan (AM) +04 UTC +04:00",
      },
    ],
  },
  {
    countryName: "Angola",
    phoneCode: 244,
    timezones: [
      {
        zoneName: "Africa/Lagos",
        displayName: "Africa/Lagos (AO) WAT UTC +01:00",
      },
      {
        zoneName: "Africa/Luanda",
        displayName: "Africa/Luanda (AO) WAT UTC +01:00",
      },
    ],
  },
  {
    countryName: "Antarctica",
    phoneCode: 672,
    timezones: [
      {
        zoneName: "Antarctica/Casey",
        displayName: "Antarctica/Casey (AQ) +08 UTC +08:00",
      },
      {
        zoneName: "Antarctica/Davis",
        displayName: "Antarctica/Davis (AQ) +07 UTC +07:00",
      },
      {
        zoneName: "Antarctica/DumontDUrville",
        displayName: "Antarctica/DumontDUrville (AQ) +10 UTC +10:00",
      },
      {
        zoneName: "Antarctica/Mawson",
        displayName: "Antarctica/Mawson (AQ) +05 UTC +05:00",
      },
      {
        zoneName: "Antarctica/McMurdo",
        displayName: "Antarctica/McMurdo (AQ) NZDT UTC +13:00",
      },
      {
        zoneName: "Antarctica/Palmer",
        displayName: "Antarctica/Palmer (AQ) -03 UTC -03:00",
      },
      {
        zoneName: "Antarctica/Rothera",
        displayName: "Antarctica/Rothera (AQ) -03 UTC -03:00",
      },
      {
        zoneName: "Antarctica/Syowa",
        displayName: "Antarctica/Syowa (AQ) +03 UTC +03:00",
      },
      {
        zoneName: "Antarctica/Troll",
        displayName: "Antarctica/Troll (AQ) +00 UTC +00:00",
      },
      {
        zoneName: "Antarctica/Vostok",
        displayName: "Antarctica/Vostok (AQ) +06 UTC +06:00",
      },
      {
        zoneName: "Pacific/Auckland",
        displayName: "Pacific/Auckland (AQ) NZDT UTC +13:00",
      },
    ],
  },
  {
    countryName: "Argentina",
    phoneCode: 54,
    timezones: [
      {
        zoneName: "America/Argentina/Buenos_Aires",
        displayName: "America/Argentina/Buenos Aires (AR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Argentina/Catamarca",
        displayName: "America/Argentina/Catamarca (AR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Argentina/Cordoba",
        displayName: "America/Argentina/Cordoba (AR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Argentina/Jujuy",
        displayName: "America/Argentina/Jujuy (AR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Argentina/La_Rioja",
        displayName: "America/Argentina/La Rioja (AR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Argentina/Mendoza",
        displayName: "America/Argentina/Mendoza (AR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Argentina/Rio_Gallegos",
        displayName: "America/Argentina/Rio Gallegos (AR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Argentina/Salta",
        displayName: "America/Argentina/Salta (AR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Argentina/San_Juan",
        displayName: "America/Argentina/San Juan (AR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Argentina/San_Luis",
        displayName: "America/Argentina/San Luis (AR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Argentina/Tucuman",
        displayName: "America/Argentina/Tucuman (AR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Argentina/Ushuaia",
        displayName: "America/Argentina/Ushuaia (AR) -03 UTC -03:00",
      },
    ],
  },
  {
    countryName: "American Samoa",
    phoneCode: 1684,
    timezones: [
      {
        zoneName: "Pacific/Pago_Pago",
        displayName: "Pacific/Pago Pago (AS) SST UTC -11:00",
      },
    ],
  },
  {
    countryName: "Austria",
    phoneCode: 43,
    timezones: [
      {
        zoneName: "Europe/Vienna",
        displayName: "Europe/Vienna (AT) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Australia",
    phoneCode: 61,
    timezones: [
      {
        zoneName: "Antarctica/Macquarie",
        displayName: "Antarctica/Macquarie (AU) +11 UTC +11:00",
      },
      {
        zoneName: "Australia/Adelaide",
        displayName: "Australia/Adelaide (AU) ACDT UTC +10:30",
      },
      {
        zoneName: "Australia/Brisbane",
        displayName: "Australia/Brisbane (AU) AEST UTC +10:00",
      },
      {
        zoneName: "Australia/Broken_Hill",
        displayName: "Australia/Broken Hill (AU) ACDT UTC +10:30",
      },
      {
        zoneName: "Australia/Currie",
        displayName: "Australia/Currie (AU) AEDT UTC +11:00",
      },
      {
        zoneName: "Australia/Darwin",
        displayName: "Australia/Darwin (AU) ACST UTC +09:30",
      },
      {
        zoneName: "Australia/Eucla",
        displayName: "Australia/Eucla (AU) +0845 UTC +08:45",
      },
      {
        zoneName: "Australia/Hobart",
        displayName: "Australia/Hobart (AU) AEDT UTC +11:00",
      },
      {
        zoneName: "Australia/Lindeman",
        displayName: "Australia/Lindeman (AU) AEST UTC +10:00",
      },
      {
        zoneName: "Australia/Lord_Howe",
        displayName: "Australia/Lord Howe (AU) +11 UTC +11:00",
      },
      {
        zoneName: "Australia/Melbourne",
        displayName: "Australia/Melbourne (AU) AEDT UTC +11:00",
      },
      {
        zoneName: "Australia/Perth",
        displayName: "Australia/Perth (AU) AWST UTC +08:00",
      },
      {
        zoneName: "Australia/Sydney",
        displayName: "Australia/Sydney (AU) AEDT UTC +11:00",
      },
    ],
  },
  {
    countryName: "Aruba",
    phoneCode: 297,
    timezones: [
      {
        zoneName: "America/Aruba",
        displayName: "America/Aruba (AW) AST UTC -04:00",
      },
      {
        zoneName: "America/Curacao",
        displayName: "America/Curacao (AW) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Åland Islands",
    phoneCode: 358,
    timezones: [
      {
        zoneName: "Europe/Helsinki",
        displayName: "Europe/Helsinki (AX) EET UTC +02:00",
      },
      {
        zoneName: "Europe/Mariehamn",
        displayName: "Europe/Mariehamn (AX) EET UTC +02:00",
      },
    ],
  },
  {
    countryName: "Azerbaijan",
    phoneCode: 994,
    timezones: [
      { zoneName: "Asia/Baku", displayName: "Asia/Baku (AZ) +04 UTC +04:00" },
    ],
  },
  {
    countryName: "Bosnia and Herzegovina",
    phoneCode: 387,
    timezones: [
      {
        zoneName: "Europe/Belgrade",
        displayName: "Europe/Belgrade (BA) CET UTC +01:00",
      },
      {
        zoneName: "Europe/Sarajevo",
        displayName: "Europe/Sarajevo (BA) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Barbados",
    phoneCode: 1246,
    timezones: [
      {
        zoneName: "America/Barbados",
        displayName: "America/Barbados (BB) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Bangladesh",
    phoneCode: 880,
    timezones: [
      { zoneName: "Asia/Dhaka", displayName: "Asia/Dhaka (BD) +06 UTC +06:00" },
    ],
  },
  {
    countryName: "Belgium",
    phoneCode: 32,
    timezones: [
      {
        zoneName: "Europe/Brussels",
        displayName: "Europe/Brussels (BE) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Burkina Faso",
    phoneCode: 226,
    timezones: [
      {
        zoneName: "Africa/Abidjan",
        displayName: "Africa/Abidjan (BF) GMT UTC +00:00",
      },
      {
        zoneName: "Africa/Ouagadougou",
        displayName: "Africa/Ouagadougou (BF) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "Bulgaria",
    phoneCode: 359,
    timezones: [
      {
        zoneName: "Europe/Sofia",
        displayName: "Europe/Sofia (BG) EET UTC +02:00",
      },
    ],
  },
  {
    countryName: "Bahrain",
    phoneCode: 973,
    timezones: [
      {
        zoneName: "Asia/Bahrain",
        displayName: "Asia/Bahrain (BH) +03 UTC +03:00",
      },
      { zoneName: "Asia/Qatar", displayName: "Asia/Qatar (BH) +03 UTC +03:00" },
    ],
  },
  {
    countryName: "Burundi",
    phoneCode: 257,
    timezones: [
      {
        zoneName: "Africa/Bujumbura",
        displayName: "Africa/Bujumbura (BI) CAT UTC +02:00",
      },
      {
        zoneName: "Africa/Maputo",
        displayName: "Africa/Maputo (BI) CAT UTC +02:00",
      },
    ],
  },
  {
    countryName: "Benin",
    phoneCode: 229,
    timezones: [
      {
        zoneName: "Africa/Lagos",
        displayName: "Africa/Lagos (BJ) WAT UTC +01:00",
      },
      {
        zoneName: "Africa/Porto-Novo",
        displayName: "Africa/Porto-Novo (BJ) WAT UTC +01:00",
      },
    ],
  },
  {
    countryName: "Saint Barthélemy",
    phoneCode: 590,
    timezones: [
      {
        zoneName: "America/Port_of_Spain",
        displayName: "America/Port of Spain (BL) AST UTC -04:00",
      },
      {
        zoneName: "America/St_Barthelemy",
        displayName: "America/St Barthelemy (BL) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Bermuda",
    phoneCode: 1441,
    timezones: [
      {
        zoneName: "Atlantic/Bermuda",
        displayName: "Atlantic/Bermuda (BM) ADT UTC -03:00",
      },
    ],
  },
  {
    countryName: "Brunei Darussalam",
    phoneCode: 673,
    timezones: [
      {
        zoneName: "Asia/Brunei",
        displayName: "Asia/Brunei (BN) +08 UTC +08:00",
      },
    ],
  },
  {
    countryName: "Bolivia, Plurinational State of",
    phoneCode: 591,
    timezones: [
      {
        zoneName: "America/La_Paz",
        displayName: "America/La Paz (BO) -04 UTC -04:00",
      },
    ],
  },
  {
    countryName: "Bonaire, Sint Eustatius and Saba",
    phoneCode: 599,
    timezones: [
      {
        zoneName: "America/Curacao",
        displayName: "America/Curacao (BQ) AST UTC -04:00",
      },
      {
        zoneName: "America/Kralendijk",
        displayName: "America/Kralendijk (BQ) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Brazil",
    phoneCode: 55,
    timezones: [
      {
        zoneName: "America/Araguaina",
        displayName: "America/Araguaina (BR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Bahia",
        displayName: "America/Bahia (BR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Belem",
        displayName: "America/Belem (BR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Boa_Vista",
        displayName: "America/Boa Vista (BR) -04 UTC -04:00",
      },
      {
        zoneName: "America/Campo_Grande",
        displayName: "America/Campo Grande (BR) -04 UTC -04:00",
      },
      {
        zoneName: "America/Cuiaba",
        displayName: "America/Cuiaba (BR) -04 UTC -04:00",
      },
      {
        zoneName: "America/Eirunepe",
        displayName: "America/Eirunepe (BR) -05 UTC -05:00",
      },
      {
        zoneName: "America/Fortaleza",
        displayName: "America/Fortaleza (BR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Maceio",
        displayName: "America/Maceio (BR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Manaus",
        displayName: "America/Manaus (BR) -04 UTC -04:00",
      },
      {
        zoneName: "America/Noronha",
        displayName: "America/Noronha (BR) -02 UTC -02:00",
      },
      {
        zoneName: "America/Porto_Velho",
        displayName: "America/Porto Velho (BR) -04 UTC -04:00",
      },
      {
        zoneName: "America/Recife",
        displayName: "America/Recife (BR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Rio_Branco",
        displayName: "America/Rio Branco (BR) -05 UTC -05:00",
      },
      {
        zoneName: "America/Santarem",
        displayName: "America/Santarem (BR) -03 UTC -03:00",
      },
      {
        zoneName: "America/Sao_Paulo",
        displayName: "America/Sao Paulo (BR) -03 UTC -03:00",
      },
    ],
  },
  {
    countryName: "Bahamas",
    phoneCode: 1242,
    timezones: [
      {
        zoneName: "America/Nassau",
        displayName: "America/Nassau (BS) EDT UTC -04:00",
      },
    ],
  },
  {
    countryName: "Bhutan",
    phoneCode: 975,
    timezones: [
      {
        zoneName: "Asia/Thimphu",
        displayName: "Asia/Thimphu (BT) +06 UTC +06:00",
      },
    ],
  },
  {
    countryName: "Botswana",
    phoneCode: 267,
    timezones: [
      {
        zoneName: "Africa/Gaborone",
        displayName: "Africa/Gaborone (BW) CAT UTC +02:00",
      },
      {
        zoneName: "Africa/Maputo",
        displayName: "Africa/Maputo (BW) CAT UTC +02:00",
      },
    ],
  },
  {
    countryName: "Belarus",
    phoneCode: 375,
    timezones: [
      {
        zoneName: "Europe/Minsk",
        displayName: "Europe/Minsk (BY) +03 UTC +03:00",
      },
    ],
  },
  {
    countryName: "Belize",
    phoneCode: 501,
    timezones: [
      {
        zoneName: "America/Belize",
        displayName: "America/Belize (BZ) CST UTC -06:00",
      },
    ],
  },
  {
    countryName: "Canada",
    phoneCode: 1,
    timezones: [
      {
        zoneName: "America/Atikokan",
        displayName: "America/Atikokan (CA) EST UTC -05:00",
      },
      {
        zoneName: "America/Blanc-Sablon",
        displayName: "America/Blanc-Sablon (CA) AST UTC -04:00",
      },
      {
        zoneName: "America/Cambridge_Bay",
        displayName: "America/Cambridge Bay (CA) MDT UTC -06:00",
      },
      {
        zoneName: "America/Creston",
        displayName: "America/Creston (CA) MST UTC -07:00",
      },
      {
        zoneName: "America/Dawson",
        displayName: "America/Dawson (CA) MST UTC -07:00",
      },
      {
        zoneName: "America/Dawson_Creek",
        displayName: "America/Dawson Creek (CA) MST UTC -07:00",
      },
      {
        zoneName: "America/Edmonton",
        displayName: "America/Edmonton (CA) MDT UTC -06:00",
      },
      {
        zoneName: "America/Fort_Nelson",
        displayName: "America/Fort Nelson (CA) MST UTC -07:00",
      },
      {
        zoneName: "America/Glace_Bay",
        displayName: "America/Glace Bay (CA) ADT UTC -03:00",
      },
      {
        zoneName: "America/Goose_Bay",
        displayName: "America/Goose Bay (CA) ADT UTC -03:00",
      },
      {
        zoneName: "America/Halifax",
        displayName: "America/Halifax (CA) ADT UTC -03:00",
      },
      {
        zoneName: "America/Inuvik",
        displayName: "America/Inuvik (CA) MDT UTC -06:00",
      },
      {
        zoneName: "America/Iqaluit",
        displayName: "America/Iqaluit (CA) EDT UTC -04:00",
      },
      {
        zoneName: "America/Moncton",
        displayName: "America/Moncton (CA) ADT UTC -03:00",
      },
      {
        zoneName: "America/Nipigon",
        displayName: "America/Nipigon (CA) EDT UTC -04:00",
      },
      {
        zoneName: "America/Pangnirtung",
        displayName: "America/Pangnirtung (CA) EDT UTC -04:00",
      },
      {
        zoneName: "America/Rainy_River",
        displayName: "America/Rainy River (CA) CDT UTC -05:00",
      },
      {
        zoneName: "America/Rankin_Inlet",
        displayName: "America/Rankin Inlet (CA) CDT UTC -05:00",
      },
      {
        zoneName: "America/Regina",
        displayName: "America/Regina (CA) CST UTC -06:00",
      },
      {
        zoneName: "America/Resolute",
        displayName: "America/Resolute (CA) CDT UTC -05:00",
      },
      {
        zoneName: "America/St_Johns",
        displayName: "America/St Johns (CA) NDT UTC -02:30",
      },
      {
        zoneName: "America/Swift_Current",
        displayName: "America/Swift Current (CA) CST UTC -06:00",
      },
      {
        zoneName: "America/Thunder_Bay",
        displayName: "America/Thunder Bay (CA) EDT UTC -04:00",
      },
      {
        zoneName: "America/Toronto",
        displayName: "America/Toronto (CA) EDT UTC -04:00",
      },
      {
        zoneName: "America/Vancouver",
        displayName: "America/Vancouver (CA) PDT UTC -07:00",
      },
      {
        zoneName: "America/Whitehorse",
        displayName: "America/Whitehorse (CA) MST UTC -07:00",
      },
      {
        zoneName: "America/Winnipeg",
        displayName: "America/Winnipeg (CA) CDT UTC -05:00",
      },
      {
        zoneName: "America/Yellowknife",
        displayName: "America/Yellowknife (CA) MDT UTC -06:00",
      },
    ],
  },
  {
    countryName: "Cocos (Keeling) Islands",
    phoneCode: 61,
    timezones: [
      {
        zoneName: "Indian/Cocos",
        displayName: "Indian/Cocos (CC) +0630 UTC +06:30",
      },
    ],
  },
  {
    countryName: "Congo, Democratic Republic of the",
    phoneCode: 243,
    timezones: [
      {
        zoneName: "Africa/Kinshasa",
        displayName: "Africa/Kinshasa (CD) WAT UTC +01:00",
      },
      {
        zoneName: "Africa/Lagos",
        displayName: "Africa/Lagos (CD) WAT UTC +01:00",
      },
      {
        zoneName: "Africa/Lubumbashi",
        displayName: "Africa/Lubumbashi (CD) CAT UTC +02:00",
      },
      {
        zoneName: "Africa/Maputo",
        displayName: "Africa/Maputo (CD) CAT UTC +02:00",
      },
    ],
  },
  {
    countryName: "Central African Republic",
    phoneCode: 236,
    timezones: [
      {
        zoneName: "Africa/Bangui",
        displayName: "Africa/Bangui (CF) WAT UTC +01:00",
      },
      {
        zoneName: "Africa/Lagos",
        displayName: "Africa/Lagos (CF) WAT UTC +01:00",
      },
    ],
  },
  {
    countryName: "Congo",
    phoneCode: 242,
    timezones: [
      {
        zoneName: "Africa/Brazzaville",
        displayName: "Africa/Brazzaville (CG) WAT UTC +01:00",
      },
      {
        zoneName: "Africa/Lagos",
        displayName: "Africa/Lagos (CG) WAT UTC +01:00",
      },
    ],
  },
  {
    countryName: "Switzerland",
    phoneCode: 41,
    timezones: [
      {
        zoneName: "Europe/Zurich",
        displayName: "Europe/Zurich (CH) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Côte d'Ivoire",
    phoneCode: 225,
    timezones: [
      {
        zoneName: "Africa/Abidjan",
        displayName: "Africa/Abidjan (CI) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "Cook Islands",
    phoneCode: 682,
    timezones: [
      {
        zoneName: "Pacific/Rarotonga",
        displayName: "Pacific/Rarotonga (CK) -10 UTC -10:00",
      },
    ],
  },
  {
    countryName: "Chile",
    phoneCode: 56,
    timezones: [
      {
        zoneName: "America/Punta_Arenas",
        displayName: "America/Punta Arenas (CL) -03 UTC -03:00",
      },
      {
        zoneName: "America/Santiago",
        displayName: "America/Santiago (CL) -03 UTC -03:00",
      },
      {
        zoneName: "Pacific/Easter",
        displayName: "Pacific/Easter (CL) -05 UTC -05:00",
      },
    ],
  },
  {
    countryName: "Cameroon",
    phoneCode: 237,
    timezones: [
      {
        zoneName: "Africa/Douala",
        displayName: "Africa/Douala (CM) WAT UTC +01:00",
      },
      {
        zoneName: "Africa/Lagos",
        displayName: "Africa/Lagos (CM) WAT UTC +01:00",
      },
    ],
  },
  {
    countryName: "China",
    phoneCode: 86,
    timezones: [
      {
        zoneName: "Asia/Shanghai",
        displayName: "Asia/Shanghai (CN) CST UTC +08:00",
      },
      {
        zoneName: "Asia/Urumqi",
        displayName: "Asia/Urumqi (CN) +06 UTC +06:00",
      },
    ],
  },
  {
    countryName: "Colombia",
    phoneCode: 57,
    timezones: [
      {
        zoneName: "America/Bogota",
        displayName: "America/Bogota (CO) -05 UTC -05:00",
      },
    ],
  },
  {
    countryName: "Costa Rica",
    phoneCode: 506,
    timezones: [
      {
        zoneName: "America/Costa_Rica",
        displayName: "America/Costa Rica (CR) CST UTC -06:00",
      },
    ],
  },
  {
    countryName: "Cuba",
    phoneCode: 53,
    timezones: [
      {
        zoneName: "America/Havana",
        displayName: "America/Havana (CU) CDT UTC -04:00",
      },
    ],
  },
  {
    countryName: "Cabo Verde",
    phoneCode: 238,
    timezones: [
      {
        zoneName: "Atlantic/Cape_Verde",
        displayName: "Atlantic/Cape Verde (CV) -01 UTC -01:00",
      },
    ],
  },
  {
    countryName: "Curaçao",
    phoneCode: 599,
    timezones: [
      {
        zoneName: "America/Curacao",
        displayName: "America/Curacao (CW) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Christmas Island",
    phoneCode: 61,
    timezones: [
      {
        zoneName: "Indian/Christmas",
        displayName: "Indian/Christmas (CX) +07 UTC +07:00",
      },
    ],
  },
  {
    countryName: "Cyprus",
    phoneCode: 357,
    timezones: [
      {
        zoneName: "Asia/Famagusta",
        displayName: "Asia/Famagusta (CY) EET UTC +02:00",
      },
      {
        zoneName: "Asia/Nicosia",
        displayName: "Asia/Nicosia (CY) EET UTC +02:00",
      },
    ],
  },
  {
    countryName: "Czechia",
    phoneCode: 420,
    timezones: [
      {
        zoneName: "Europe/Prague",
        displayName: "Europe/Prague (CZ) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Germany",
    phoneCode: 49,
    timezones: [
      {
        zoneName: "Europe/Berlin",
        displayName: "Europe/Berlin (DE) CET UTC +01:00",
      },
      {
        zoneName: "Europe/Busingen",
        displayName: "Europe/Busingen (DE) CET UTC +01:00",
      },
      {
        zoneName: "Europe/Zurich",
        displayName: "Europe/Zurich (DE) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Djibouti",
    phoneCode: 253,
    timezones: [
      {
        zoneName: "Africa/Djibouti",
        displayName: "Africa/Djibouti (DJ) EAT UTC +03:00",
      },
      {
        zoneName: "Africa/Nairobi",
        displayName: "Africa/Nairobi (DJ) EAT UTC +03:00",
      },
    ],
  },
  {
    countryName: "Denmark",
    phoneCode: 45,
    timezones: [
      {
        zoneName: "Europe/Copenhagen",
        displayName: "Europe/Copenhagen (DK) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Dominica",
    phoneCode: 1767,
    timezones: [
      {
        zoneName: "America/Dominica",
        displayName: "America/Dominica (DM) AST UTC -04:00",
      },
      {
        zoneName: "America/Port_of_Spain",
        displayName: "America/Port of Spain (DM) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Dominican Republic",
    phoneCode: 1849,
    timezones: [
      {
        zoneName: "America/Santo_Domingo",
        displayName: "America/Santo Domingo (DO) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Algeria",
    phoneCode: 213,
    timezones: [
      {
        zoneName: "Africa/Algiers",
        displayName: "Africa/Algiers (DZ) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Ecuador",
    phoneCode: 593,
    timezones: [
      {
        zoneName: "America/Guayaquil",
        displayName: "America/Guayaquil (EC) -05 UTC -05:00",
      },
      {
        zoneName: "Pacific/Galapagos",
        displayName: "Pacific/Galapagos (EC) -06 UTC -06:00",
      },
    ],
  },
  {
    countryName: "Estonia",
    phoneCode: 372,
    timezones: [
      {
        zoneName: "Europe/Tallinn",
        displayName: "Europe/Tallinn (EE) EET UTC +02:00",
      },
    ],
  },
  {
    countryName: "Egypt",
    phoneCode: 20,
    timezones: [
      {
        zoneName: "Africa/Cairo",
        displayName: "Africa/Cairo (EG) EET UTC +02:00",
      },
    ],
  },
  {
    countryName: "Western Sahara",
    phoneCode: 212,
    timezones: [
      {
        zoneName: "Africa/El_Aaiun",
        displayName: "Africa/El Aaiun (EH) +01 UTC +01:00",
      },
    ],
  },
  {
    countryName: "Eritrea",
    phoneCode: 291,
    timezones: [
      {
        zoneName: "Africa/Asmara",
        displayName: "Africa/Asmara (ER) EAT UTC +03:00",
      },
      {
        zoneName: "Africa/Nairobi",
        displayName: "Africa/Nairobi (ER) EAT UTC +03:00",
      },
    ],
  },
  {
    countryName: "Spain",
    phoneCode: 34,
    timezones: [
      {
        zoneName: "Africa/Ceuta",
        displayName: "Africa/Ceuta (ES) CET UTC +01:00",
      },
      {
        zoneName: "Atlantic/Canary",
        displayName: "Atlantic/Canary (ES) WET UTC +00:00",
      },
      {
        zoneName: "Europe/Madrid",
        displayName: "Europe/Madrid (ES) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Ethiopia",
    phoneCode: 251,
    timezones: [
      {
        zoneName: "Africa/Addis_Ababa",
        displayName: "Africa/Addis Ababa (ET) EAT UTC +03:00",
      },
      {
        zoneName: "Africa/Nairobi",
        displayName: "Africa/Nairobi (ET) EAT UTC +03:00",
      },
    ],
  },
  {
    countryName: "Finland",
    phoneCode: 358,
    timezones: [
      {
        zoneName: "Europe/Helsinki",
        displayName: "Europe/Helsinki (FI) EET UTC +02:00",
      },
    ],
  },
  {
    countryName: "Fiji",
    phoneCode: 679,
    timezones: [
      {
        zoneName: "Pacific/Fiji",
        displayName: "Pacific/Fiji (FJ) +12 UTC +12:00",
      },
    ],
  },
  {
    countryName: "Falkland Islands (Malvinas)",
    phoneCode: 500,
    timezones: [
      {
        zoneName: "Atlantic/Stanley",
        displayName: "Atlantic/Stanley (FK) -03 UTC -03:00",
      },
    ],
  },
  {
    countryName: "Micronesia, Federated States of",
    phoneCode: 691,
    timezones: [
      {
        zoneName: "Pacific/Chuuk",
        displayName: "Pacific/Chuuk (FM) +10 UTC +10:00",
      },
      {
        zoneName: "Pacific/Kosrae",
        displayName: "Pacific/Kosrae (FM) +11 UTC +11:00",
      },
      {
        zoneName: "Pacific/Pohnpei",
        displayName: "Pacific/Pohnpei (FM) +11 UTC +11:00",
      },
    ],
  },
  {
    countryName: "Faroe Islands",
    phoneCode: 298,
    timezones: [
      {
        zoneName: "Atlantic/Faroe",
        displayName: "Atlantic/Faroe (FO) WET UTC +00:00",
      },
    ],
  },
  {
    countryName: "France",
    phoneCode: 33,
    timezones: [
      {
        zoneName: "Europe/Paris",
        displayName: "Europe/Paris (FR) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Gabon",
    phoneCode: 241,
    timezones: [
      {
        zoneName: "Africa/Lagos",
        displayName: "Africa/Lagos (GA) WAT UTC +01:00",
      },
      {
        zoneName: "Africa/Libreville",
        displayName: "Africa/Libreville (GA) WAT UTC +01:00",
      },
    ],
  },
  {
    countryName: "United Kingdom of Great Britain and Northern Ireland",
    phoneCode: 44,
    timezones: [
      {
        zoneName: "Europe/London",
        displayName: "Europe/London (GB) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "Grenada",
    phoneCode: 1473,
    timezones: [
      {
        zoneName: "America/Grenada",
        displayName: "America/Grenada (GD) AST UTC -04:00",
      },
      {
        zoneName: "America/Port_of_Spain",
        displayName: "America/Port of Spain (GD) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Georgia",
    phoneCode: 995,
    timezones: [
      {
        zoneName: "Asia/Tbilisi",
        displayName: "Asia/Tbilisi (GE) +04 UTC +04:00",
      },
    ],
  },
  {
    countryName: "French Guiana",
    phoneCode: 594,
    timezones: [
      {
        zoneName: "America/Cayenne",
        displayName: "America/Cayenne (GF) -03 UTC -03:00",
      },
    ],
  },
  {
    countryName: "Guernsey",
    phoneCode: 44,
    timezones: [
      {
        zoneName: "Europe/Guernsey",
        displayName: "Europe/Guernsey (GG) GMT UTC +00:00",
      },
      {
        zoneName: "Europe/London",
        displayName: "Europe/London (GG) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "Ghana",
    phoneCode: 233,
    timezones: [
      {
        zoneName: "Africa/Accra",
        displayName: "Africa/Accra (GH) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "Gibraltar",
    phoneCode: 350,
    timezones: [
      {
        zoneName: "Europe/Gibraltar",
        displayName: "Europe/Gibraltar (GI) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Greenland",
    phoneCode: 299,
    timezones: [
      {
        zoneName: "America/Danmarkshavn",
        displayName: "America/Danmarkshavn (GL) GMT UTC +00:00",
      },
      {
        zoneName: "America/Nuuk",
        displayName: "America/Nuuk (GL) -03 UTC -03:00",
      },
      {
        zoneName: "America/Scoresbysund",
        displayName: "America/Scoresbysund (GL) -01 UTC -01:00",
      },
      {
        zoneName: "America/Thule",
        displayName: "America/Thule (GL) ADT UTC -03:00",
      },
    ],
  },
  {
    countryName: "Gambia",
    phoneCode: 220,
    timezones: [
      {
        zoneName: "Africa/Abidjan",
        displayName: "Africa/Abidjan (GM) GMT UTC +00:00",
      },
      {
        zoneName: "Africa/Banjul",
        displayName: "Africa/Banjul (GM) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "Guinea",
    phoneCode: 224,
    timezones: [
      {
        zoneName: "Africa/Abidjan",
        displayName: "Africa/Abidjan (GN) GMT UTC +00:00",
      },
      {
        zoneName: "Africa/Conakry",
        displayName: "Africa/Conakry (GN) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "Guadeloupe",
    phoneCode: 590,
    timezones: [
      {
        zoneName: "America/Guadeloupe",
        displayName: "America/Guadeloupe (GP) AST UTC -04:00",
      },
      {
        zoneName: "America/Port_of_Spain",
        displayName: "America/Port of Spain (GP) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Equatorial Guinea",
    phoneCode: 240,
    timezones: [
      {
        zoneName: "Africa/Lagos",
        displayName: "Africa/Lagos (GQ) WAT UTC +01:00",
      },
      {
        zoneName: "Africa/Malabo",
        displayName: "Africa/Malabo (GQ) WAT UTC +01:00",
      },
    ],
  },
  {
    countryName: "Greece",
    phoneCode: 30,
    timezones: [
      {
        zoneName: "Europe/Athens",
        displayName: "Europe/Athens (GR) EET UTC +02:00",
      },
    ],
  },
  {
    countryName: "South Georgia and the South Sandwich Islands",
    phoneCode: 500,
    timezones: [
      {
        zoneName: "Atlantic/South_Georgia",
        displayName: "Atlantic/South Georgia (GS) -02 UTC -02:00",
      },
    ],
  },
  {
    countryName: "Guatemala",
    phoneCode: 502,
    timezones: [
      {
        zoneName: "America/Guatemala",
        displayName: "America/Guatemala (GT) CST UTC -06:00",
      },
    ],
  },
  {
    countryName: "Guam",
    phoneCode: 1671,
    timezones: [
      {
        zoneName: "Pacific/Guam",
        displayName: "Pacific/Guam (GU) ChST UTC +10:00",
      },
    ],
  },
  {
    countryName: "Guinea-Bissau",
    phoneCode: 245,
    timezones: [
      {
        zoneName: "Africa/Bissau",
        displayName: "Africa/Bissau (GW) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "Guyana",
    phoneCode: 592,
    timezones: [
      {
        zoneName: "America/Guyana",
        displayName: "America/Guyana (GY) -04 UTC -04:00",
      },
    ],
  },
  {
    countryName: "Hong Kong",
    phoneCode: 852,
    timezones: [
      {
        zoneName: "Asia/Hong_Kong",
        displayName: "Asia/Hong Kong (HK) HKT UTC +08:00",
      },
    ],
  },
  {
    countryName: "Honduras",
    phoneCode: 504,
    timezones: [
      {
        zoneName: "America/Tegucigalpa",
        displayName: "America/Tegucigalpa (HN) CST UTC -06:00",
      },
    ],
  },
  {
    countryName: "Croatia",
    phoneCode: 385,
    timezones: [
      {
        zoneName: "Europe/Belgrade",
        displayName: "Europe/Belgrade (HR) CET UTC +01:00",
      },
      {
        zoneName: "Europe/Zagreb",
        displayName: "Europe/Zagreb (HR) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Haiti",
    phoneCode: 509,
    timezones: [
      {
        zoneName: "America/Port-au-Prince",
        displayName: "America/Port-au-Prince (HT) EDT UTC -04:00",
      },
    ],
  },
  {
    countryName: "Hungary",
    phoneCode: 36,
    timezones: [
      {
        zoneName: "Europe/Budapest",
        displayName: "Europe/Budapest (HU) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Indonesia",
    phoneCode: 62,
    timezones: [
      {
        zoneName: "Asia/Jakarta",
        displayName: "Asia/Jakarta (ID) WIB UTC +07:00",
      },
      {
        zoneName: "Asia/Jayapura",
        displayName: "Asia/Jayapura (ID) WIT UTC +09:00",
      },
      {
        zoneName: "Asia/Makassar",
        displayName: "Asia/Makassar (ID) WITA UTC +08:00",
      },
      {
        zoneName: "Asia/Pontianak",
        displayName: "Asia/Pontianak (ID) WIB UTC +07:00",
      },
    ],
  },
  {
    countryName: "Ireland",
    phoneCode: 353,
    timezones: [
      {
        zoneName: "Europe/Dublin",
        displayName: "Europe/Dublin (IE) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "Israel",
    phoneCode: 972,
    timezones: [
      {
        zoneName: "Asia/Jerusalem",
        displayName: "Asia/Jerusalem (IL) IST UTC +02:00",
      },
    ],
  },
  {
    countryName: "Isle of Man",
    phoneCode: 44,
    timezones: [
      {
        zoneName: "Europe/Isle_of_Man",
        displayName: "Europe/Isle of Man (IM) GMT UTC +00:00",
      },
      {
        zoneName: "Europe/London",
        displayName: "Europe/London (IM) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "India",
    phoneCode: 91,
    timezones: [
      {
        zoneName: "Asia/Kolkata",
        displayName: "Asia/Kolkata (IN) IST UTC +05:30",
      },
    ],
  },
  {
    countryName: "British Indian Ocean Territory",
    phoneCode: 246,
    timezones: [
      {
        zoneName: "Indian/Chagos",
        displayName: "Indian/Chagos (IO) +06 UTC +06:00",
      },
    ],
  },
  {
    countryName: "Iraq",
    phoneCode: 964,
    timezones: [
      {
        zoneName: "Asia/Baghdad",
        displayName: "Asia/Baghdad (IQ) +03 UTC +03:00",
      },
    ],
  },
  {
    countryName: "Iran, Islamic Republic of",
    phoneCode: 98,
    timezones: [
      {
        zoneName: "Asia/Tehran",
        displayName: "Asia/Tehran (IR) +0430 UTC +04:30",
      },
    ],
  },
  {
    countryName: "Iceland",
    phoneCode: 354,
    timezones: [
      {
        zoneName: "Atlantic/Reykjavik",
        displayName: "Atlantic/Reykjavik (IS) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "Italy",
    phoneCode: 39,
    timezones: [
      {
        zoneName: "Europe/Rome",
        displayName: "Europe/Rome (IT) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Jersey",
    phoneCode: 44,
    timezones: [
      {
        zoneName: "Europe/Jersey",
        displayName: "Europe/Jersey (JE) GMT UTC +00:00",
      },
      {
        zoneName: "Europe/London",
        displayName: "Europe/London (JE) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "Jamaica",
    phoneCode: 1876,
    timezones: [
      {
        zoneName: "America/Jamaica",
        displayName: "America/Jamaica (JM) EST UTC -05:00",
      },
    ],
  },
  {
    countryName: "Jordan",
    phoneCode: 962,
    timezones: [
      { zoneName: "Asia/Amman", displayName: "Asia/Amman (JO) EET UTC +02:00" },
    ],
  },
  {
    countryName: "Japan",
    phoneCode: 81,
    timezones: [
      { zoneName: "Asia/Tokyo", displayName: "Asia/Tokyo (JP) JST UTC +09:00" },
    ],
  },
  {
    countryName: "Kenya",
    phoneCode: 254,
    timezones: [
      {
        zoneName: "Africa/Nairobi",
        displayName: "Africa/Nairobi (KE) EAT UTC +03:00",
      },
    ],
  },
  {
    countryName: "Kyrgyzstan",
    phoneCode: 996,
    timezones: [
      {
        zoneName: "Asia/Bishkek",
        displayName: "Asia/Bishkek (KG) +06 UTC +06:00",
      },
    ],
  },
  {
    countryName: "Cambodia",
    phoneCode: 855,
    timezones: [
      {
        zoneName: "Asia/Bangkok",
        displayName: "Asia/Bangkok (KH) +07 UTC +07:00",
      },
      {
        zoneName: "Asia/Phnom_Penh",
        displayName: "Asia/Phnom Penh (KH) +07 UTC +07:00",
      },
    ],
  },
  {
    countryName: "Kiribati",
    phoneCode: 686,
    timezones: [
      {
        zoneName: "Pacific/Enderbury",
        displayName: "Pacific/Enderbury (KI) +13 UTC +13:00",
      },
      {
        zoneName: "Pacific/Kiritimati",
        displayName: "Pacific/Kiritimati (KI) +14 UTC +14:00",
      },
      {
        zoneName: "Pacific/Tarawa",
        displayName: "Pacific/Tarawa (KI) +12 UTC +12:00",
      },
    ],
  },
  {
    countryName: "Comoros",
    phoneCode: 269,
    timezones: [
      {
        zoneName: "Africa/Nairobi",
        displayName: "Africa/Nairobi (KM) EAT UTC +03:00",
      },
      {
        zoneName: "Indian/Comoro",
        displayName: "Indian/Comoro (KM) EAT UTC +03:00",
      },
    ],
  },
  {
    countryName: "Saint Kitts and Nevis",
    phoneCode: 1869,
    timezones: [
      {
        zoneName: "America/Port_of_Spain",
        displayName: "America/Port of Spain (KN) AST UTC -04:00",
      },
      {
        zoneName: "America/St_Kitts",
        displayName: "America/St Kitts (KN) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Korea, Democratic People's Republic of",
    phoneCode: 850,
    timezones: [
      {
        zoneName: "Asia/Pyongyang",
        displayName: "Asia/Pyongyang (KP) KST UTC +09:00",
      },
    ],
  },
  {
    countryName: "Korea, Republic of",
    phoneCode: 82,
    timezones: [
      { zoneName: "Asia/Seoul", displayName: "Asia/Seoul (KR) KST UTC +09:00" },
    ],
  },
  {
    countryName: "Kuwait",
    phoneCode: 965,
    timezones: [
      {
        zoneName: "Asia/Kuwait",
        displayName: "Asia/Kuwait (KW) +03 UTC +03:00",
      },
      {
        zoneName: "Asia/Riyadh",
        displayName: "Asia/Riyadh (KW) +03 UTC +03:00",
      },
    ],
  },
  {
    countryName: "Cayman Islands",
    phoneCode: 1345,
    timezones: [
      {
        zoneName: "America/Cayman",
        displayName: "America/Cayman (KY) EST UTC -05:00",
      },
      {
        zoneName: "America/Panama",
        displayName: "America/Panama (KY) EST UTC -05:00",
      },
    ],
  },
  {
    countryName: "Kazakhstan",
    phoneCode: 77,
    timezones: [
      {
        zoneName: "Asia/Almaty",
        displayName: "Asia/Almaty (KZ) +06 UTC +06:00",
      },
      { zoneName: "Asia/Aqtau", displayName: "Asia/Aqtau (KZ) +05 UTC +05:00" },
      {
        zoneName: "Asia/Aqtobe",
        displayName: "Asia/Aqtobe (KZ) +05 UTC +05:00",
      },
      {
        zoneName: "Asia/Atyrau",
        displayName: "Asia/Atyrau (KZ) +05 UTC +05:00",
      },
      { zoneName: "Asia/Oral", displayName: "Asia/Oral (KZ) +05 UTC +05:00" },
      {
        zoneName: "Asia/Qostanay",
        displayName: "Asia/Qostanay (KZ) +06 UTC +06:00",
      },
      {
        zoneName: "Asia/Qyzylorda",
        displayName: "Asia/Qyzylorda (KZ) +05 UTC +05:00",
      },
    ],
  },
  {
    countryName: "Lao People's Democratic Republic",
    phoneCode: 856,
    timezones: [
      {
        zoneName: "Asia/Bangkok",
        displayName: "Asia/Bangkok (LA) +07 UTC +07:00",
      },
      {
        zoneName: "Asia/Vientiane",
        displayName: "Asia/Vientiane (LA) +07 UTC +07:00",
      },
    ],
  },
  {
    countryName: "Lebanon",
    phoneCode: 961,
    timezones: [
      {
        zoneName: "Asia/Beirut",
        displayName: "Asia/Beirut (LB) EET UTC +02:00",
      },
    ],
  },
  {
    countryName: "Saint Lucia",
    phoneCode: 1758,
    timezones: [
      {
        zoneName: "America/Port_of_Spain",
        displayName: "America/Port of Spain (LC) AST UTC -04:00",
      },
      {
        zoneName: "America/St_Lucia",
        displayName: "America/St Lucia (LC) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Liechtenstein",
    phoneCode: 423,
    timezones: [
      {
        zoneName: "Europe/Vaduz",
        displayName: "Europe/Vaduz (LI) CET UTC +01:00",
      },
      {
        zoneName: "Europe/Zurich",
        displayName: "Europe/Zurich (LI) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Sri Lanka",
    phoneCode: 94,
    timezones: [
      {
        zoneName: "Asia/Colombo",
        displayName: "Asia/Colombo (LK) +0530 UTC +05:30",
      },
    ],
  },
  {
    countryName: "Liberia",
    phoneCode: 231,
    timezones: [
      {
        zoneName: "Africa/Monrovia",
        displayName: "Africa/Monrovia (LR) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "Lesotho",
    phoneCode: 266,
    timezones: [
      {
        zoneName: "Africa/Johannesburg",
        displayName: "Africa/Johannesburg (LS) SAST UTC +02:00",
      },
      {
        zoneName: "Africa/Maseru",
        displayName: "Africa/Maseru (LS) SAST UTC +02:00",
      },
    ],
  },
  {
    countryName: "Lithuania",
    phoneCode: 370,
    timezones: [
      {
        zoneName: "Europe/Vilnius",
        displayName: "Europe/Vilnius (LT) EET UTC +02:00",
      },
    ],
  },
  {
    countryName: "Luxembourg",
    phoneCode: 352,
    timezones: [
      {
        zoneName: "Europe/Luxembourg",
        displayName: "Europe/Luxembourg (LU) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Latvia",
    phoneCode: 371,
    timezones: [
      {
        zoneName: "Europe/Riga",
        displayName: "Europe/Riga (LV) EET UTC +02:00",
      },
    ],
  },
  {
    countryName: "Libya",
    phoneCode: 218,
    timezones: [
      {
        zoneName: "Africa/Tripoli",
        displayName: "Africa/Tripoli (LY) EET UTC +02:00",
      },
    ],
  },
  {
    countryName: "Morocco",
    phoneCode: 212,
    timezones: [
      {
        zoneName: "Africa/Casablanca",
        displayName: "Africa/Casablanca (MA) +01 UTC +01:00",
      },
    ],
  },
  {
    countryName: "Monaco",
    phoneCode: 377,
    timezones: [
      {
        zoneName: "Europe/Monaco",
        displayName: "Europe/Monaco (MC) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Moldova, Republic of",
    phoneCode: 373,
    timezones: [
      {
        zoneName: "Europe/Chisinau",
        displayName: "Europe/Chisinau (MD) EET UTC +02:00",
      },
    ],
  },
  {
    countryName: "Montenegro",
    phoneCode: 382,
    timezones: [
      {
        zoneName: "Europe/Belgrade",
        displayName: "Europe/Belgrade (ME) CET UTC +01:00",
      },
      {
        zoneName: "Europe/Podgorica",
        displayName: "Europe/Podgorica (ME) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Saint Martin, (French part)",
    phoneCode: 590,
    timezones: [
      {
        zoneName: "America/Marigot",
        displayName: "America/Marigot (MF) AST UTC -04:00",
      },
      {
        zoneName: "America/Port_of_Spain",
        displayName: "America/Port of Spain (MF) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Madagascar",
    phoneCode: 261,
    timezones: [
      {
        zoneName: "Africa/Nairobi",
        displayName: "Africa/Nairobi (MG) EAT UTC +03:00",
      },
      {
        zoneName: "Indian/Antananarivo",
        displayName: "Indian/Antananarivo (MG) EAT UTC +03:00",
      },
    ],
  },
  {
    countryName: "Marshall Islands",
    phoneCode: 692,
    timezones: [
      {
        zoneName: "Pacific/Kwajalein",
        displayName: "Pacific/Kwajalein (MH) +12 UTC +12:00",
      },
      {
        zoneName: "Pacific/Majuro",
        displayName: "Pacific/Majuro (MH) +12 UTC +12:00",
      },
    ],
  },
  {
    countryName: "North Macedonia",
    phoneCode: 389,
    timezones: [
      {
        zoneName: "Europe/Belgrade",
        displayName: "Europe/Belgrade (MK) CET UTC +01:00",
      },
      {
        zoneName: "Europe/Skopje",
        displayName: "Europe/Skopje (MK) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Mali",
    phoneCode: 223,
    timezones: [
      {
        zoneName: "Africa/Abidjan",
        displayName: "Africa/Abidjan (ML) GMT UTC +00:00",
      },
      {
        zoneName: "Africa/Bamako",
        displayName: "Africa/Bamako (ML) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "Myanmar",
    phoneCode: 95,
    timezones: [
      {
        zoneName: "Asia/Yangon",
        displayName: "Asia/Yangon (MM) +0630 UTC +06:30",
      },
    ],
  },
  {
    countryName: "Mongolia",
    phoneCode: 976,
    timezones: [
      {
        zoneName: "Asia/Choibalsan",
        displayName: "Asia/Choibalsan (MN) +08 UTC +08:00",
      },
      { zoneName: "Asia/Hovd", displayName: "Asia/Hovd (MN) +07 UTC +07:00" },
      {
        zoneName: "Asia/Ulaanbaatar",
        displayName: "Asia/Ulaanbaatar (MN) +08 UTC +08:00",
      },
    ],
  },
  {
    countryName: "Macao",
    phoneCode: 853,
    timezones: [
      { zoneName: "Asia/Macau", displayName: "Asia/Macau (MO) CST UTC +08:00" },
    ],
  },
  {
    countryName: "Northern Mariana Islands",
    phoneCode: 1670,
    timezones: [
      {
        zoneName: "Pacific/Guam",
        displayName: "Pacific/Guam (MP) ChST UTC +10:00",
      },
      {
        zoneName: "Pacific/Saipan",
        displayName: "Pacific/Saipan (MP) ChST UTC +10:00",
      },
    ],
  },
  {
    countryName: "Martinique",
    phoneCode: 596,
    timezones: [
      {
        zoneName: "America/Martinique",
        displayName: "America/Martinique (MQ) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Mauritania",
    phoneCode: 222,
    timezones: [
      {
        zoneName: "Africa/Abidjan",
        displayName: "Africa/Abidjan (MR) GMT UTC +00:00",
      },
      {
        zoneName: "Africa/Nouakchott",
        displayName: "Africa/Nouakchott (MR) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "Montserrat",
    phoneCode: 1664,
    timezones: [
      {
        zoneName: "America/Montserrat",
        displayName: "America/Montserrat (MS) AST UTC -04:00",
      },
      {
        zoneName: "America/Port_of_Spain",
        displayName: "America/Port of Spain (MS) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Malta",
    phoneCode: 356,
    timezones: [
      {
        zoneName: "Europe/Malta",
        displayName: "Europe/Malta (MT) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Mauritius",
    phoneCode: 230,
    timezones: [
      {
        zoneName: "Indian/Mauritius",
        displayName: "Indian/Mauritius (MU) +04 UTC +04:00",
      },
    ],
  },
  {
    countryName: "Maldives",
    phoneCode: 960,
    timezones: [
      {
        zoneName: "Indian/Maldives",
        displayName: "Indian/Maldives (MV) +05 UTC +05:00",
      },
    ],
  },
  {
    countryName: "Malawi",
    phoneCode: 265,
    timezones: [
      {
        zoneName: "Africa/Blantyre",
        displayName: "Africa/Blantyre (MW) CAT UTC +02:00",
      },
      {
        zoneName: "Africa/Maputo",
        displayName: "Africa/Maputo (MW) CAT UTC +02:00",
      },
    ],
  },
  {
    countryName: "Mexico",
    phoneCode: 52,
    timezones: [
      {
        zoneName: "America/Bahia_Banderas",
        displayName: "America/Bahia Banderas (MX) CST UTC -06:00",
      },
      {
        zoneName: "America/Cancun",
        displayName: "America/Cancun (MX) EST UTC -05:00",
      },
      {
        zoneName: "America/Chihuahua",
        displayName: "America/Chihuahua (MX) MST UTC -07:00",
      },
      {
        zoneName: "America/Hermosillo",
        displayName: "America/Hermosillo (MX) MST UTC -07:00",
      },
      {
        zoneName: "America/Matamoros",
        displayName: "America/Matamoros (MX) CDT UTC -05:00",
      },
      {
        zoneName: "America/Mazatlan",
        displayName: "America/Mazatlan (MX) MST UTC -07:00",
      },
      {
        zoneName: "America/Merida",
        displayName: "America/Merida (MX) CST UTC -06:00",
      },
      {
        zoneName: "America/Mexico_City",
        displayName: "America/Mexico City (MX) CST UTC -06:00",
      },
      {
        zoneName: "America/Monterrey",
        displayName: "America/Monterrey (MX) CST UTC -06:00",
      },
      {
        zoneName: "America/Ojinaga",
        displayName: "America/Ojinaga (MX) MDT UTC -06:00",
      },
      {
        zoneName: "America/Tijuana",
        displayName: "America/Tijuana (MX) PDT UTC -07:00",
      },
    ],
  },
  {
    countryName: "Malaysia",
    phoneCode: 60,
    timezones: [
      {
        zoneName: "Asia/Kuala_Lumpur",
        displayName: "Asia/Kuala Lumpur (MY) +08 UTC +08:00",
      },
      {
        zoneName: "Asia/Kuching",
        displayName: "Asia/Kuching (MY) +08 UTC +08:00",
      },
    ],
  },
  {
    countryName: "Mozambique",
    phoneCode: 258,
    timezones: [
      {
        zoneName: "Africa/Maputo",
        displayName: "Africa/Maputo (MZ) CAT UTC +02:00",
      },
    ],
  },
  {
    countryName: "Namibia",
    phoneCode: 264,
    timezones: [
      {
        zoneName: "Africa/Windhoek",
        displayName: "Africa/Windhoek (NA) CAT UTC +02:00",
      },
    ],
  },
  {
    countryName: "New Caledonia",
    phoneCode: 687,
    timezones: [
      {
        zoneName: "Pacific/Noumea",
        displayName: "Pacific/Noumea (NC) +11 UTC +11:00",
      },
    ],
  },
  {
    countryName: "Niger",
    phoneCode: 227,
    timezones: [
      {
        zoneName: "Africa/Lagos",
        displayName: "Africa/Lagos (NE) WAT UTC +01:00",
      },
      {
        zoneName: "Africa/Niamey",
        displayName: "Africa/Niamey (NE) WAT UTC +01:00",
      },
    ],
  },
  {
    countryName: "Norfolk Island",
    phoneCode: 672,
    timezones: [
      {
        zoneName: "Pacific/Norfolk",
        displayName: "Pacific/Norfolk (NF) +12 UTC +12:00",
      },
    ],
  },
  {
    countryName: "Nigeria",
    phoneCode: 234,
    timezones: [
      {
        zoneName: "Africa/Lagos",
        displayName: "Africa/Lagos (NG) WAT UTC +01:00",
      },
    ],
  },
  {
    countryName: "Nicaragua",
    phoneCode: 505,
    timezones: [
      {
        zoneName: "America/Managua",
        displayName: "America/Managua (NI) CST UTC -06:00",
      },
    ],
  },
  {
    countryName: "Netherlands",
    phoneCode: 31,
    timezones: [
      {
        zoneName: "Europe/Amsterdam",
        displayName: "Europe/Amsterdam (NL) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Norway",
    phoneCode: 47,
    timezones: [
      {
        zoneName: "Europe/Oslo",
        displayName: "Europe/Oslo (NO) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Nepal",
    phoneCode: 977,
    timezones: [
      {
        zoneName: "Asia/Kathmandu",
        displayName: "Asia/Kathmandu (NP) +0545 UTC +05:45",
      },
    ],
  },
  {
    countryName: "Nauru",
    phoneCode: 674,
    timezones: [
      {
        zoneName: "Pacific/Nauru",
        displayName: "Pacific/Nauru (NR) +12 UTC +12:00",
      },
    ],
  },
  {
    countryName: "Niue",
    phoneCode: 683,
    timezones: [
      {
        zoneName: "Pacific/Niue",
        displayName: "Pacific/Niue (NU) -11 UTC -11:00",
      },
    ],
  },
  {
    countryName: "New Zealand",
    phoneCode: 64,
    timezones: [
      {
        zoneName: "Pacific/Auckland",
        displayName: "Pacific/Auckland (NZ) NZDT UTC +13:00",
      },
      {
        zoneName: "Pacific/Chatham",
        displayName: "Pacific/Chatham (NZ) +1345 UTC +13:45",
      },
    ],
  },
  {
    countryName: "Oman",
    phoneCode: 968,
    timezones: [
      { zoneName: "Asia/Dubai", displayName: "Asia/Dubai (OM) +04 UTC +04:00" },
      {
        zoneName: "Asia/Muscat",
        displayName: "Asia/Muscat (OM) +04 UTC +04:00",
      },
    ],
  },
  {
    countryName: "Panama",
    phoneCode: 507,
    timezones: [
      {
        zoneName: "America/Panama",
        displayName: "America/Panama (PA) EST UTC -05:00",
      },
    ],
  },
  {
    countryName: "Peru",
    phoneCode: 51,
    timezones: [
      {
        zoneName: "America/Lima",
        displayName: "America/Lima (PE) -05 UTC -05:00",
      },
    ],
  },
  {
    countryName: "French Polynesia",
    phoneCode: 689,
    timezones: [
      {
        zoneName: "Pacific/Gambier",
        displayName: "Pacific/Gambier (PF) -09 UTC -09:00",
      },
      {
        zoneName: "Pacific/Marquesas",
        displayName: "Pacific/Marquesas (PF) -0930 UTC -09:30",
      },
      {
        zoneName: "Pacific/Tahiti",
        displayName: "Pacific/Tahiti (PF) -10 UTC -10:00",
      },
    ],
  },
  {
    countryName: "Papua New Guinea",
    phoneCode: 675,
    timezones: [
      {
        zoneName: "Pacific/Bougainville",
        displayName: "Pacific/Bougainville (PG) +11 UTC +11:00",
      },
      {
        zoneName: "Pacific/Port_Moresby",
        displayName: "Pacific/Port Moresby (PG) +10 UTC +10:00",
      },
    ],
  },
  {
    countryName: "Philippines",
    phoneCode: 63,
    timezones: [
      {
        zoneName: "Asia/Manila",
        displayName: "Asia/Manila (PH) PST UTC +08:00",
      },
    ],
  },
  {
    countryName: "Pakistan",
    phoneCode: 92,
    timezones: [
      {
        zoneName: "Asia/Karachi",
        displayName: "Asia/Karachi (PK) PKT UTC +05:00",
      },
    ],
  },
  {
    countryName: "Poland",
    phoneCode: 48,
    timezones: [
      {
        zoneName: "Europe/Warsaw",
        displayName: "Europe/Warsaw (PL) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Saint Pierre and Miquelon",
    phoneCode: 508,
    timezones: [
      {
        zoneName: "America/Miquelon",
        displayName: "America/Miquelon (PM) -02 UTC -02:00",
      },
    ],
  },
  {
    countryName: "Pitcairn",
    phoneCode: 872,
    timezones: [
      {
        zoneName: "Pacific/Pitcairn",
        displayName: "Pacific/Pitcairn (PN) -08 UTC -08:00",
      },
    ],
  },
  {
    countryName: "Puerto Rico",
    phoneCode: 1939,
    timezones: [
      {
        zoneName: "America/Puerto_Rico",
        displayName: "America/Puerto Rico (PR) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Palestine, State of",
    phoneCode: 970,
    timezones: [
      { zoneName: "Asia/Gaza", displayName: "Asia/Gaza (PS) EET UTC +02:00" },
      {
        zoneName: "Asia/Hebron",
        displayName: "Asia/Hebron (PS) EET UTC +02:00",
      },
    ],
  },
  {
    countryName: "Portugal",
    phoneCode: 351,
    timezones: [
      {
        zoneName: "Atlantic/Azores",
        displayName: "Atlantic/Azores (PT) -01 UTC -01:00",
      },
      {
        zoneName: "Atlantic/Madeira",
        displayName: "Atlantic/Madeira (PT) WET UTC +00:00",
      },
      {
        zoneName: "Europe/Lisbon",
        displayName: "Europe/Lisbon (PT) WET UTC +00:00",
      },
    ],
  },
  {
    countryName: "Palau",
    phoneCode: 680,
    timezones: [
      {
        zoneName: "Pacific/Palau",
        displayName: "Pacific/Palau (PW) +09 UTC +09:00",
      },
    ],
  },
  {
    countryName: "Paraguay",
    phoneCode: 595,
    timezones: [
      {
        zoneName: "America/Asuncion",
        displayName: "America/Asuncion (PY) -03 UTC -03:00",
      },
    ],
  },
  {
    countryName: "Qatar",
    phoneCode: 974,
    timezones: [
      { zoneName: "Asia/Qatar", displayName: "Asia/Qatar (QA) +03 UTC +03:00" },
    ],
  },
  {
    countryName: "Réunion",
    phoneCode: 262,
    timezones: [
      {
        zoneName: "Indian/Reunion",
        displayName: "Indian/Reunion (RE) +04 UTC +04:00",
      },
    ],
  },
  {
    countryName: "Romania",
    phoneCode: 40,
    timezones: [
      {
        zoneName: "Europe/Bucharest",
        displayName: "Europe/Bucharest (RO) EET UTC +02:00",
      },
    ],
  },
  {
    countryName: "Serbia",
    phoneCode: 381,
    timezones: [
      {
        zoneName: "Europe/Belgrade",
        displayName: "Europe/Belgrade (RS) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Russian Federation",
    phoneCode: 7,
    timezones: [
      {
        zoneName: "Asia/Anadyr",
        displayName: "Asia/Anadyr (RU) +12 UTC +12:00",
      },
      {
        zoneName: "Asia/Barnaul",
        displayName: "Asia/Barnaul (RU) +07 UTC +07:00",
      },
      { zoneName: "Asia/Chita", displayName: "Asia/Chita (RU) +09 UTC +09:00" },
      {
        zoneName: "Asia/Irkutsk",
        displayName: "Asia/Irkutsk (RU) +08 UTC +08:00",
      },
      {
        zoneName: "Asia/Kamchatka",
        displayName: "Asia/Kamchatka (RU) +12 UTC +12:00",
      },
      {
        zoneName: "Asia/Khandyga",
        displayName: "Asia/Khandyga (RU) +09 UTC +09:00",
      },
      {
        zoneName: "Asia/Krasnoyarsk",
        displayName: "Asia/Krasnoyarsk (RU) +07 UTC +07:00",
      },
      {
        zoneName: "Asia/Magadan",
        displayName: "Asia/Magadan (RU) +11 UTC +11:00",
      },
      {
        zoneName: "Asia/Novokuznetsk",
        displayName: "Asia/Novokuznetsk (RU) +07 UTC +07:00",
      },
      {
        zoneName: "Asia/Novosibirsk",
        displayName: "Asia/Novosibirsk (RU) +07 UTC +07:00",
      },
      { zoneName: "Asia/Omsk", displayName: "Asia/Omsk (RU) +06 UTC +06:00" },
      {
        zoneName: "Asia/Sakhalin",
        displayName: "Asia/Sakhalin (RU) +11 UTC +11:00",
      },
      {
        zoneName: "Asia/Srednekolymsk",
        displayName: "Asia/Srednekolymsk (RU) +11 UTC +11:00",
      },
      { zoneName: "Asia/Tomsk", displayName: "Asia/Tomsk (RU) +07 UTC +07:00" },
      {
        zoneName: "Asia/Ust-Nera",
        displayName: "Asia/Ust-Nera (RU) +10 UTC +10:00",
      },
      {
        zoneName: "Asia/Vladivostok",
        displayName: "Asia/Vladivostok (RU) +10 UTC +10:00",
      },
      {
        zoneName: "Asia/Yakutsk",
        displayName: "Asia/Yakutsk (RU) +09 UTC +09:00",
      },
      {
        zoneName: "Asia/Yekaterinburg",
        displayName: "Asia/Yekaterinburg (RU) +05 UTC +05:00",
      },
      {
        zoneName: "Europe/Astrakhan",
        displayName: "Europe/Astrakhan (RU) +04 UTC +04:00",
      },
      {
        zoneName: "Europe/Kaliningrad",
        displayName: "Europe/Kaliningrad (RU) EET UTC +02:00",
      },
      {
        zoneName: "Europe/Kirov",
        displayName: "Europe/Kirov (RU) +03 UTC +03:00",
      },
      {
        zoneName: "Europe/Moscow",
        displayName: "Europe/Moscow (RU) MSK UTC +03:00",
      },
      {
        zoneName: "Europe/Samara",
        displayName: "Europe/Samara (RU) +04 UTC +04:00",
      },
      {
        zoneName: "Europe/Saratov",
        displayName: "Europe/Saratov (RU) +04 UTC +04:00",
      },
      {
        zoneName: "Europe/Simferopol",
        displayName: "Europe/Simferopol (RU) MSK UTC +03:00",
      },
      {
        zoneName: "Europe/Ulyanovsk",
        displayName: "Europe/Ulyanovsk (RU) +04 UTC +04:00",
      },
      {
        zoneName: "Europe/Volgograd",
        displayName: "Europe/Volgograd (RU) +04 UTC +04:00",
      },
    ],
  },
  {
    countryName: "Rwanda",
    phoneCode: 250,
    timezones: [
      {
        zoneName: "Africa/Kigali",
        displayName: "Africa/Kigali (RW) CAT UTC +02:00",
      },
      {
        zoneName: "Africa/Maputo",
        displayName: "Africa/Maputo (RW) CAT UTC +02:00",
      },
    ],
  },
  {
    countryName: "Saudi Arabia",
    phoneCode: 966,
    timezones: [
      {
        zoneName: "Asia/Riyadh",
        displayName: "Asia/Riyadh (SA) +03 UTC +03:00",
      },
    ],
  },
  {
    countryName: "Solomon Islands",
    phoneCode: 677,
    timezones: [
      {
        zoneName: "Pacific/Guadalcanal",
        displayName: "Pacific/Guadalcanal (SB) +11 UTC +11:00",
      },
    ],
  },
  {
    countryName: "Seychelles",
    phoneCode: 248,
    timezones: [
      {
        zoneName: "Indian/Mahe",
        displayName: "Indian/Mahe (SC) +04 UTC +04:00",
      },
    ],
  },
  {
    countryName: "Sudan",
    phoneCode: 249,
    timezones: [
      {
        zoneName: "Africa/Khartoum",
        displayName: "Africa/Khartoum (SD) CAT UTC +02:00",
      },
    ],
  },
  {
    countryName: "Sweden",
    phoneCode: 46,
    timezones: [
      {
        zoneName: "Europe/Stockholm",
        displayName: "Europe/Stockholm (SE) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Singapore",
    phoneCode: 65,
    timezones: [
      {
        zoneName: "Asia/Singapore",
        displayName: "Asia/Singapore (SG) +08 UTC +08:00",
      },
    ],
  },
  {
    countryName: "Saint Helena, Ascension and Tristan da Cunha",
    phoneCode: 290,
    timezones: [
      {
        zoneName: "Africa/Abidjan",
        displayName: "Africa/Abidjan (SH) GMT UTC +00:00",
      },
      {
        zoneName: "Atlantic/St_Helena",
        displayName: "Atlantic/St Helena (SH) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "Slovenia",
    phoneCode: 386,
    timezones: [
      {
        zoneName: "Europe/Belgrade",
        displayName: "Europe/Belgrade (SI) CET UTC +01:00",
      },
      {
        zoneName: "Europe/Ljubljana",
        displayName: "Europe/Ljubljana (SI) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Svalbard and Jan Mayen",
    phoneCode: 47,
    timezones: [
      {
        zoneName: "Arctic/Longyearbyen",
        displayName: "Arctic/Longyearbyen (SJ) CET UTC +01:00",
      },
      {
        zoneName: "Europe/Oslo",
        displayName: "Europe/Oslo (SJ) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Slovakia",
    phoneCode: 421,
    timezones: [
      {
        zoneName: "Europe/Bratislava",
        displayName: "Europe/Bratislava (SK) CET UTC +01:00",
      },
      {
        zoneName: "Europe/Prague",
        displayName: "Europe/Prague (SK) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Sierra Leone",
    phoneCode: 232,
    timezones: [
      {
        zoneName: "Africa/Abidjan",
        displayName: "Africa/Abidjan (SL) GMT UTC +00:00",
      },
      {
        zoneName: "Africa/Freetown",
        displayName: "Africa/Freetown (SL) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "San Marino",
    phoneCode: 378,
    timezones: [
      {
        zoneName: "Europe/Rome",
        displayName: "Europe/Rome (SM) CET UTC +01:00",
      },
      {
        zoneName: "Europe/San_Marino",
        displayName: "Europe/San Marino (SM) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Senegal",
    phoneCode: 221,
    timezones: [
      {
        zoneName: "Africa/Abidjan",
        displayName: "Africa/Abidjan (SN) GMT UTC +00:00",
      },
      {
        zoneName: "Africa/Dakar",
        displayName: "Africa/Dakar (SN) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "Somalia",
    phoneCode: 252,
    timezones: [
      {
        zoneName: "Africa/Mogadishu",
        displayName: "Africa/Mogadishu (SO) EAT UTC +03:00",
      },
      {
        zoneName: "Africa/Nairobi",
        displayName: "Africa/Nairobi (SO) EAT UTC +03:00",
      },
    ],
  },
  {
    countryName: "Suriname",
    phoneCode: 597,
    timezones: [
      {
        zoneName: "America/Paramaribo",
        displayName: "America/Paramaribo (SR) -03 UTC -03:00",
      },
    ],
  },
  {
    countryName: "South Sudan",
    phoneCode: 211,
    timezones: [
      {
        zoneName: "Africa/Juba",
        displayName: "Africa/Juba (SS) EAT UTC +03:00",
      },
    ],
  },
  {
    countryName: "Sao Tome and Principe",
    phoneCode: 239,
    timezones: [
      {
        zoneName: "Africa/Sao_Tome",
        displayName: "Africa/Sao Tome (ST) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "El Salvador",
    phoneCode: 503,
    timezones: [
      {
        zoneName: "America/El_Salvador",
        displayName: "America/El Salvador (SV) CST UTC -06:00",
      },
    ],
  },
  {
    countryName: "Sint Maarten, (Dutch part)",
    phoneCode: 1721,
    timezones: [
      {
        zoneName: "America/Curacao",
        displayName: "America/Curacao (SX) AST UTC -04:00",
      },
      {
        zoneName: "America/Lower_Princes",
        displayName: "America/Lower Princes (SX) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Syrian Arab Republic",
    phoneCode: 963,
    timezones: [
      {
        zoneName: "Asia/Damascus",
        displayName: "Asia/Damascus (SY) EET UTC +02:00",
      },
    ],
  },
  {
    countryName: "Eswatini",
    phoneCode: 268,
    timezones: [
      {
        zoneName: "Africa/Johannesburg",
        displayName: "Africa/Johannesburg (SZ) SAST UTC +02:00",
      },
      {
        zoneName: "Africa/Mbabane",
        displayName: "Africa/Mbabane (SZ) SAST UTC +02:00",
      },
    ],
  },
  {
    countryName: "Turks and Caicos Islands",
    phoneCode: 1649,
    timezones: [
      {
        zoneName: "America/Grand_Turk",
        displayName: "America/Grand Turk (TC) EDT UTC -04:00",
      },
    ],
  },
  {
    countryName: "Chad",
    phoneCode: 235,
    timezones: [
      {
        zoneName: "Africa/Ndjamena",
        displayName: "Africa/Ndjamena (TD) WAT UTC +01:00",
      },
    ],
  },
  {
    countryName: "French Southern Territories",
    phoneCode: 262,
    timezones: [
      {
        zoneName: "Indian/Kerguelen",
        displayName: "Indian/Kerguelen (TF) +05 UTC +05:00",
      },
      {
        zoneName: "Indian/Reunion",
        displayName: "Indian/Reunion (TF) +04 UTC +04:00",
      },
    ],
  },
  {
    countryName: "Togo",
    phoneCode: 228,
    timezones: [
      {
        zoneName: "Africa/Abidjan",
        displayName: "Africa/Abidjan (TG) GMT UTC +00:00",
      },
      {
        zoneName: "Africa/Lome",
        displayName: "Africa/Lome (TG) GMT UTC +00:00",
      },
    ],
  },
  {
    countryName: "Thailand",
    phoneCode: 66,
    timezones: [
      {
        zoneName: "Asia/Bangkok",
        displayName: "Asia/Bangkok (TH) +07 UTC +07:00",
      },
    ],
  },
  {
    countryName: "Tajikistan",
    phoneCode: 992,
    timezones: [
      {
        zoneName: "Asia/Dushanbe",
        displayName: "Asia/Dushanbe (TJ) +05 UTC +05:00",
      },
    ],
  },
  {
    countryName: "Tokelau",
    phoneCode: 690,
    timezones: [
      {
        zoneName: "Pacific/Fakaofo",
        displayName: "Pacific/Fakaofo (TK) +13 UTC +13:00",
      },
    ],
  },
  {
    countryName: "Timor-Leste",
    phoneCode: 670,
    timezones: [
      { zoneName: "Asia/Dili", displayName: "Asia/Dili (TL) +09 UTC +09:00" },
    ],
  },
  {
    countryName: "Turkmenistan",
    phoneCode: 993,
    timezones: [
      {
        zoneName: "Asia/Ashgabat",
        displayName: "Asia/Ashgabat (TM) +05 UTC +05:00",
      },
    ],
  },
  {
    countryName: "Tunisia",
    phoneCode: 216,
    timezones: [
      {
        zoneName: "Africa/Tunis",
        displayName: "Africa/Tunis (TN) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Tonga",
    phoneCode: 676,
    timezones: [
      {
        zoneName: "Pacific/Tongatapu",
        displayName: "Pacific/Tongatapu (TO) +13 UTC +13:00",
      },
    ],
  },
  {
    countryName: "Turkey",
    phoneCode: 90,
    timezones: [
      {
        zoneName: "Europe/Istanbul",
        displayName: "Europe/Istanbul (TR) +03 UTC +03:00",
      },
    ],
  },
  {
    countryName: "Trinidad and Tobago",
    phoneCode: 1868,
    timezones: [
      {
        zoneName: "America/Port_of_Spain",
        displayName: "America/Port of Spain (TT) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Tuvalu",
    phoneCode: 688,
    timezones: [
      {
        zoneName: "Pacific/Funafuti",
        displayName: "Pacific/Funafuti (TV) +12 UTC +12:00",
      },
    ],
  },
  {
    countryName: "Taiwan",
    phoneCode: 886,
    timezones: [
      {
        zoneName: "Asia/Taipei",
        displayName: "Asia/Taipei (TW) CST UTC +08:00",
      },
    ],
  },
  {
    countryName: "Tanzania, United Republic of",
    phoneCode: 255,
    timezones: [
      {
        zoneName: "Africa/Dar_es_Salaam",
        displayName: "Africa/Dar es Salaam (TZ) EAT UTC +03:00",
      },
      {
        zoneName: "Africa/Nairobi",
        displayName: "Africa/Nairobi (TZ) EAT UTC +03:00",
      },
    ],
  },
  {
    countryName: "Ukraine",
    phoneCode: 380,
    timezones: [
      {
        zoneName: "Europe/Kiev",
        displayName: "Europe/Kiev (UA) EET UTC +02:00",
      },
      {
        zoneName: "Europe/Simferopol",
        displayName: "Europe/Simferopol (UA) MSK UTC +03:00",
      },
      {
        zoneName: "Europe/Uzhgorod",
        displayName: "Europe/Uzhgorod (UA) EET UTC +02:00",
      },
      {
        zoneName: "Europe/Zaporozhye",
        displayName: "Europe/Zaporozhye (UA) EET UTC +02:00",
      },
    ],
  },
  {
    countryName: "Uganda",
    phoneCode: 256,
    timezones: [
      {
        zoneName: "Africa/Kampala",
        displayName: "Africa/Kampala (UG) EAT UTC +03:00",
      },
      {
        zoneName: "Africa/Nairobi",
        displayName: "Africa/Nairobi (UG) EAT UTC +03:00",
      },
    ],
  },
  {
    countryName: "United States Minor Outlying Islands",
    phoneCode: 1,
    timezones: [
      {
        zoneName: "Pacific/Honolulu",
        displayName: "Pacific/Honolulu (UM) HST UTC -10:00",
      },
      {
        zoneName: "Pacific/Midway",
        displayName: "Pacific/Midway (UM) SST UTC -11:00",
      },
      {
        zoneName: "Pacific/Pago_Pago",
        displayName: "Pacific/Pago Pago (UM) SST UTC -11:00",
      },
      {
        zoneName: "Pacific/Wake",
        displayName: "Pacific/Wake (UM) +12 UTC +12:00",
      },
    ],
  },
  {
    countryName: "United States of America",
    phoneCode: 1,
    timezones: [
      {
        zoneName: "America/Adak",
        displayName: "America/Adak (US) HDT UTC -09:00",
      },
      {
        zoneName: "America/Anchorage",
        displayName: "America/Anchorage (US) AKDT UTC -08:00",
      },
      {
        zoneName: "America/Boise",
        displayName: "America/Boise (US) MDT UTC -06:00",
      },
      {
        zoneName: "America/Chicago",
        displayName: "America/Chicago (US) CDT UTC -05:00",
      },
      {
        zoneName: "America/Denver",
        displayName: "America/Denver (US) MDT UTC -06:00",
      },
      {
        zoneName: "America/Detroit",
        displayName: "America/Detroit (US) EDT UTC -04:00",
      },
      {
        zoneName: "America/Indiana/Indianapolis",
        displayName: "America/Indiana/Indianapolis (US) EDT UTC -04:00",
      },
      {
        zoneName: "America/Indiana/Knox",
        displayName: "America/Indiana/Knox (US) CDT UTC -05:00",
      },
      {
        zoneName: "America/Indiana/Marengo",
        displayName: "America/Indiana/Marengo (US) EDT UTC -04:00",
      },
      {
        zoneName: "America/Indiana/Petersburg",
        displayName: "America/Indiana/Petersburg (US) EDT UTC -04:00",
      },
      {
        zoneName: "America/Indiana/Tell_City",
        displayName: "America/Indiana/Tell City (US) CDT UTC -05:00",
      },
      {
        zoneName: "America/Indiana/Vevay",
        displayName: "America/Indiana/Vevay (US) EDT UTC -04:00",
      },
      {
        zoneName: "America/Indiana/Vincennes",
        displayName: "America/Indiana/Vincennes (US) EDT UTC -04:00",
      },
      {
        zoneName: "America/Indiana/Winamac",
        displayName: "America/Indiana/Winamac (US) EDT UTC -04:00",
      },
      {
        zoneName: "America/Juneau",
        displayName: "America/Juneau (US) AKDT UTC -08:00",
      },
      {
        zoneName: "America/Kentucky/Louisville",
        displayName: "America/Kentucky/Louisville (US) EDT UTC -04:00",
      },
      {
        zoneName: "America/Kentucky/Monticello",
        displayName: "America/Kentucky/Monticello (US) EDT UTC -04:00",
      },
      {
        zoneName: "America/Los_Angeles",
        displayName: "America/Los Angeles (US) PDT UTC -07:00",
      },
      {
        zoneName: "America/Menominee",
        displayName: "America/Menominee (US) CDT UTC -05:00",
      },
      {
        zoneName: "America/Metlakatla",
        displayName: "America/Metlakatla (US) AKDT UTC -08:00",
      },
      {
        zoneName: "America/New_York",
        displayName: "America/New York (US) EDT UTC -04:00",
      },
      {
        zoneName: "America/Nome",
        displayName: "America/Nome (US) AKDT UTC -08:00",
      },
      {
        zoneName: "America/North_Dakota/Beulah",
        displayName: "America/North Dakota/Beulah (US) CDT UTC -05:00",
      },
      {
        zoneName: "America/North_Dakota/Center",
        displayName: "America/North Dakota/Center (US) CDT UTC -05:00",
      },
      {
        zoneName: "America/North_Dakota/New_Salem",
        displayName: "America/North Dakota/New Salem (US) CDT UTC -05:00",
      },
      {
        zoneName: "America/Phoenix",
        displayName: "America/Phoenix (US) MST UTC -07:00",
      },
      {
        zoneName: "America/Sitka",
        displayName: "America/Sitka (US) AKDT UTC -08:00",
      },
      {
        zoneName: "America/Yakutat",
        displayName: "America/Yakutat (US) AKDT UTC -08:00",
      },
      {
        zoneName: "Pacific/Honolulu",
        displayName: "Pacific/Honolulu (US) HST UTC -10:00",
      },
    ],
  },
  {
    countryName: "Uruguay",
    phoneCode: 598,
    timezones: [
      {
        zoneName: "America/Montevideo",
        displayName: "America/Montevideo (UY) -03 UTC -03:00",
      },
    ],
  },
  {
    countryName: "Uzbekistan",
    phoneCode: 998,
    timezones: [
      {
        zoneName: "Asia/Samarkand",
        displayName: "Asia/Samarkand (UZ) +05 UTC +05:00",
      },
      {
        zoneName: "Asia/Tashkent",
        displayName: "Asia/Tashkent (UZ) +05 UTC +05:00",
      },
    ],
  },
  {
    countryName: "Holy See",
    phoneCode: 379,
    timezones: [
      {
        zoneName: "Europe/Rome",
        displayName: "Europe/Rome (VA) CET UTC +01:00",
      },
      {
        zoneName: "Europe/Vatican",
        displayName: "Europe/Vatican (VA) CET UTC +01:00",
      },
    ],
  },
  {
    countryName: "Saint Vincent and the Grenadines",
    phoneCode: 1784,
    timezones: [
      {
        zoneName: "America/Port_of_Spain",
        displayName: "America/Port of Spain (VC) AST UTC -04:00",
      },
      {
        zoneName: "America/St_Vincent",
        displayName: "America/St Vincent (VC) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Venezuela, Bolivarian Republic of",
    phoneCode: 58,
    timezones: [
      {
        zoneName: "America/Caracas",
        displayName: "America/Caracas (VE) -04 UTC -04:00",
      },
    ],
  },
  {
    countryName: "Virgin Islands, British",
    phoneCode: 1284,
    timezones: [
      {
        zoneName: "America/Port_of_Spain",
        displayName: "America/Port of Spain (VG) AST UTC -04:00",
      },
      {
        zoneName: "America/Tortola",
        displayName: "America/Tortola (VG) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Virgin Islands, U.S.",
    phoneCode: 1340,
    timezones: [
      {
        zoneName: "America/Port_of_Spain",
        displayName: "America/Port of Spain (VI) AST UTC -04:00",
      },
      {
        zoneName: "America/St_Thomas",
        displayName: "America/St Thomas (VI) AST UTC -04:00",
      },
    ],
  },
  {
    countryName: "Viet Nam",
    phoneCode: 84,
    timezones: [
      {
        zoneName: "Asia/Bangkok",
        displayName: "Asia/Bangkok (VN) +07 UTC +07:00",
      },
      {
        zoneName: "Asia/Ho_Chi_Minh",
        displayName: "Asia/Ho Chi Minh (VN) +07 UTC +07:00",
      },
    ],
  },
  {
    countryName: "Vanuatu",
    phoneCode: 678,
    timezones: [
      {
        zoneName: "Pacific/Efate",
        displayName: "Pacific/Efate (VU) +11 UTC +11:00",
      },
    ],
  },
  {
    countryName: "Wallis and Futuna",
    phoneCode: 681,
    timezones: [
      {
        zoneName: "Pacific/Wallis",
        displayName: "Pacific/Wallis (WF) +12 UTC +12:00",
      },
    ],
  },
  {
    countryName: "Samoa",
    phoneCode: 685,
    timezones: [
      {
        zoneName: "Pacific/Apia",
        displayName: "Pacific/Apia (WS) +14 UTC +14:00",
      },
    ],
  },
  {
    countryName: "Yemen",
    phoneCode: 967,
    timezones: [
      { zoneName: "Asia/Aden", displayName: "Asia/Aden (YE) +03 UTC +03:00" },
      {
        zoneName: "Asia/Riyadh",
        displayName: "Asia/Riyadh (YE) +03 UTC +03:00",
      },
    ],
  },
  {
    countryName: "Mayotte",
    phoneCode: 262,
    timezones: [
      {
        zoneName: "Africa/Nairobi",
        displayName: "Africa/Nairobi (YT) EAT UTC +03:00",
      },
      {
        zoneName: "Indian/Mayotte",
        displayName: "Indian/Mayotte (YT) EAT UTC +03:00",
      },
    ],
  },
  {
    countryName: "South Africa",
    phoneCode: 27,
    timezones: [
      {
        zoneName: "Africa/Johannesburg",
        displayName: "Africa/Johannesburg (ZA) SAST UTC +02:00",
      },
    ],
  },
  {
    countryName: "Zambia",
    phoneCode: 260,
    timezones: [
      {
        zoneName: "Africa/Lusaka",
        displayName: "Africa/Lusaka (ZM) CAT UTC +02:00",
      },
      {
        zoneName: "Africa/Maputo",
        displayName: "Africa/Maputo (ZM) CAT UTC +02:00",
      },
    ],
  },
  {
    countryName: "Zimbabwe",
    phoneCode: 263,
    timezones: [
      {
        zoneName: "Africa/Harare",
        displayName: "Africa/Harare (ZW) CAT UTC +02:00",
      },
      {
        zoneName: "Africa/Maputo",
        displayName: "Africa/Maputo (ZW) CAT UTC +02:00",
      },
    ],
  },
];
export default countries;

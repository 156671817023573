import { Breadcrumb } from 'src/app/shared/models/breadcrumb.model';
import { Component, OnInit, Input } from '@angular/core';
import { SessionService } from 'src/app/shared/services/session.service';
import { SiteAction } from 'src/app/shared/models/siteAction.enum';
import { RegionService } from 'src/app/region/common/services/region.service';
import { AreaDetails } from 'src/app/region/common/models/areaDetails.model';
import { Router, ActivatedRoute } from '@angular/router';
import { TrainingService } from 'src/app/trainings/common/services/training.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  areaLeaderName: string;
  areaId;
  currentPage;
  trainerId;
  groupMemberId;
  constructor(
    private _sessionService: SessionService,
    private _router:Router,
    private _trainingService: TrainingService,
    private _regionService:RegionService
    ) {

  }

  public hideManageGroup = false;

  isAuthenticated() {
    return this._sessionService.isAuthenticated();
  }

  isLeader() {
    return this._sessionService.isUserLeader();
  }

  isTrainer() {
    return this._sessionService.isUserTrainer();
  }

  isAssistantTrainer() {
    return this._sessionService.isUserAssistantTrainer();
  }

  isAreaPersonnel() {
    return this._sessionService.isArealPersonel();
  }

  isRegionalDirector() {
    return this._sessionService.isRegionalDirector();
  }

  isAdmin() {
    return this._sessionService.isAdmin();
  }

  userPostion() {
    return this._sessionService.getUserInfo().position;
  }

  isGroupMember() {
    return this._sessionService.isUserMember();
  }

  isOnlineMember() {
    return this._sessionService.isOnlineMember();
  }

  getCurrentPage() {
    this.currentPage = this._sessionService.currentPage();
    return this.currentPage;
  }

  checkCurrentPage(page) {
    const checkURL = this.getCurrentPage().includes(page);
    return checkURL;
  }

  getMyAreaDetails() {
    if (this.isAuthenticated()) {
      if(this.checkCurrentPage("mygroup") && this.groupMemberId){
        this._trainingService.getUserTrainingDetails(this.groupMemberId).subscribe(training => {
          if(training){
            this._regionService.getUserAreaDetails(training.trainer.id).subscribe(area => {
              if(area){
                this.areaLeaderName = area.leaders[0].fullName;
                this.areaId = area.leaders[0].id;
              }
            });
          }
        });
      }else if (this.checkCurrentPage("mytraining") && this.trainerId) {
        this._regionService.getUserAreaDetails(this.trainerId).subscribe(area => {
          if(area){
            this.areaLeaderName = area.leaders[0].fullName;
            this.areaId = area.leaders[0].id;
          }
        });
      }
    }
  }

  gotoTraining(){
    if (this.isAuthenticated()) {
      if(this.checkCurrentPage("mygroup")){
        this._router.navigateByUrl(`/mytraining/${this.groupMemberId}`);
      }else{
        this._router.navigateByUrl(`/mytraining/${this.trainerId}`);
      }
    }
  }

  gotoGroup(){
    this._router.navigateByUrl(`/mygroup/${this.groupMemberId}`);
  }

  ngOnInit() {
    const breadcrumb:Breadcrumb = this._sessionService.getBreadcrumb();
    this.trainerId = breadcrumb.lastVisitedTrainingLeaderId;
    this.groupMemberId = breadcrumb.lastVisitedGroupMemberId;

    if((this.checkCurrentPage('area') || this.checkCurrentPage('training') ||  this.checkCurrentPage('mygroup'))
        && (this.isAdmin() || this.isRegionalDirector())){
      this.getMyAreaDetails();
    }else{
      this.areaLeaderName = breadcrumb.areaLeaderName;
      this.areaId = breadcrumb.lastVisitedAreaLeaderId;
    }
  }

}

import { Component, OnInit, Input } from '@angular/core';
import GroupMember from 'src/app/online-group/common/models/groupMember';

@Component({
  selector: 'app-group-member-item',
  templateUrl: './group-member-item.component.html',
  styleUrls: ['./group-member-item.component.css']
})
export class GroupMemberItemComponent implements OnInit {

  @Input()
  member: GroupMember

  constructor() { }

  ngOnInit() {
  }

}

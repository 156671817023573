<div fxLayout="column" fxLayoutGap="2rem" class="confirm-dialog">
  <div mat-dialog-title class="header">
    {{ data.header || 'Confirmation' | translate }}
  </div>

  <div mat-dialog-content class="content">
    {{ data.confirmation | translate }}
    <div [innerHTML]="data.confirmationHtml"></div>
  </div>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="center" class="buttons-container">
    <button class="btn main-blue-bg save-button"  mat-stroked-button (click)="confirm()" mat-dialog-close>
      {{ data.okText || 'OK' | translate }}
    </button>
    <button class="btn blue-btn cancel-button" mat-stroked-button mat-dialog-close>
      {{ data.cancelText || 'Cancel' | translate }}
    </button>
  </mat-dialog-actions>
</div>

import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { RegionDetails } from "../models/regionDetails.model";
import { AreaDetails } from "../models/areaDetails.model";
import { SaveArea } from "../models/saveArea.model";
import { SaveRegion } from "../models/saveRegion.model";
import { ConfigService } from "src/app/shared/services/config.service";


@Injectable()
export class RegionService {
    private _regionEndPoint: string;

    constructor(
      private _http: HttpClient,
      private configService:ConfigService
    ) {
      configService.configuration$.subscribe(config => {
        this._regionEndPoint = `${config.apiEndpoint}regions`;
      });
    }

    getMyRegionDetails() {
        return this._http.get<RegionDetails>(`${this._regionEndPoint}/details`);
    }

    getAreaDetails() {
        return this._http.get<AreaDetails>(`${this._regionEndPoint}/area/details`);
    }

    getUserAreaDetails(userId) {
        return this._http.get<AreaDetails>(`${this._regionEndPoint}/area/${userId}/details`);
    }

    generateAreaCode() {
        return this._http.get(`${this._regionEndPoint}/area/_generate-code`, { responseType: 'text' })
    }

    generateRegionCode() {
        return this._http.get(`${this._regionEndPoint}/_generate-code`, { responseType: 'text' })
    }

    saveArea(model: SaveArea) {
        return this._http.post<number>(`${this._regionEndPoint}/area`, model)
    }

    saveRegion(model: SaveRegion) {
        return this._http.post<number>(`${this._regionEndPoint}`, model)
    }


    getArea() {
        return this._http.get<SaveArea>(`${this._regionEndPoint}/area`);
    }

    getRegion() {
        return this._http.get<SaveRegion>(`${this._regionEndPoint}`);
    }

    updateArea(model: SaveArea) {
        return this._http.put(`${this._regionEndPoint}/area/${model.id}`, model)
    }

    updateRegion(model: SaveRegion) {
        return this._http.put(`${this._regionEndPoint}/${model.id}`, model)
    }


}

import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-bsf-store',
  templateUrl: './bsf-store.component.html',
  styleUrls: ['./bsf-store.component.css']
})
export class BsfStoreComponent implements OnInit {

  public showLink: boolean;

  constructor(private _sessionService: SessionService) { 
  }

  @Input()
  showLessonMaterials: boolean;

  ngOnInit() {
    this.showLink = !this._sessionService.isOnlineMember();
  }

}

import { RoutingService } from './../services/routing.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, combineLatest} from 'rxjs';
import { SessionService } from '../services/session.service';
import { ConfigService } from '../services/config.service';
import { HasSecurityQuestionAnswerModel } from '../models/has-security-question-answer.model';
import { AccountService } from '../services/account.service';
import { AuthService } from '../services/auth.service';
import { MsalService } from '@azure/msal-angular';

// Note: Activation guards can run before component root:
// https://stackoverflow.com/questions/73666340/why-angular-guard-is-running-before-ngoninit?rq=1

@Injectable()
export class WelcomeAreaGuardService  {
    securityQuestionsUrl: string;
    mfaOptionsUrl: string;
    mfaPhoneUrl : string;
    hasSkippedSecurityQA: string = 'has-skipped-sqa';
    enableSecurityQuestions: boolean;
    enableMfa: boolean;
    isMfaAuthenticatorEnabled: boolean;
    isTest: boolean;
    
    constructor(
        private _sessionService: SessionService,
        private _configService: ConfigService,
        private _accountService: AccountService,
        private _msalAuthService: MsalService,
        private _router: Router,
        ) {
        this._configService.configuration$.subscribe(config => {
            this.securityQuestionsUrl = config.securityQuestionsUrl;
            this.mfaOptionsUrl = config.mfaOptionsUrl;
            this.mfaPhoneUrl = config.mfaPhoneUrl;
            this.enableSecurityQuestions = config.enableSecurityQuestions;
            this.enableMfa = config.enableMfa;
            this.isMfaAuthenticatorEnabled = config.isMfaAuthenticatorEnabled;
            this.isTest = config.isTest;
        });        
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const correctPassword = sessionStorage.getItem('isCorrectPasswordForTestEnvironment')
        if(this.isTest && correctPassword == null) {
          this._router.navigate(['/test-environment'])
          return false;
        }
        // cannot look up user from session, Activation guards run before component root, and sessionService may not be setup yet
        let path = route.url.toString();
        const userInfo = this._sessionService.getUserInfo();
        // if not logged in yet, don't interfere as there are existing re-directions that will happen
        if (!userInfo) {
            return true;
        }

        // if we're logging out or openning the 'account/create', don't interfere
        if (['/account/logout', '/account/create'].includes(state.url)) {
            return true;
        }

        return new Observable<boolean>((observer)=> {
            combineLatest([
                this._accountService.isMfaRequired(),
                this._accountService.hasSecurityQuestionAnswers(),
                this._sessionService.getFirstLoginTime()
            ]).subscribe(([mfaStatus, answers, firstLoginTime]) => {
                const showMfa = mfaStatus.isRequired && !mfaStatus.isConfigured;

                // 1. security questions
                
                if (this.enableSecurityQuestions) {
                    const hasSkipFlag = this.validateSkippedSecurityQuestionsFlag(route.queryParams);
                    if (this.shouldShowSecurityQuestions(answers, hasSkipFlag)) {
                        observer.next(false);
                        observer.complete();
                        // we only re-direct if we must
                        document.location.href = this.securityQuestionsUrl;
                        return;
                    }
                }
                // 2. MFA
                if (this.enableMfa) {

                    // if not authenticated, we can't tell anything about them so don't interfere
                    const isAuthenticated = this._msalAuthService.instance.getAllAccounts().length > 0;
                    if (!isAuthenticated) {
                        observer.next(true);
                        observer.complete();
                        return;
                    }

                    let mfaSetupFirstPageName = '';
                    let mfaSetupFirstPageUrl = '';

                    if (this.isMfaAuthenticatorEnabled)
                    {
                        mfaSetupFirstPageName = 'mfa-options';
                        mfaSetupFirstPageUrl = this.mfaOptionsUrl;
                    }
                    else
                    {
                        mfaSetupFirstPageName = 'mfa-phone';
                        mfaSetupFirstPageUrl = this.mfaPhoneUrl;
                    }

                    // we don't let them choose mfa-options if they don't need it
                    if ((!showMfa) && (path.includes(mfaSetupFirstPageName))) {
                        observer.next(false);
                        observer.complete();
                        return;
                    }

                    // we force them to mfa-options if they need it
                    if ((showMfa) && (firstLoginTime) && (!path.includes(mfaSetupFirstPageName))) {
                        observer.next(true);
                        observer.complete();
                        document.location.href = mfaSetupFirstPageUrl;
                        return;
                    }                    

                    observer.next(true);
                    observer.complete();
                    return;
                }

                // Note: We cannot re-direct to home.  This can interfere with other guards.
                // We are only being asked whether or not to allow access
                // allow everything
                observer.next(true);
                observer.complete();
                return;
            });
        });
    }

    private formatSkipQuestionFlagValue(flagRawValue: string) : boolean {
        return (flagRawValue || '').trim().toLocaleLowerCase() == "true";
    }

    private validateSkippedSecurityQuestionsFlag(queryParams: any): boolean {        
        const storageFlagValue = this.formatSkipQuestionFlagValue(sessionStorage.getItem(this.hasSkippedSecurityQA));
        if(storageFlagValue) {
            return true;
        }
        const queryParamFlagValue = this.formatSkipQuestionFlagValue(queryParams[this.hasSkippedSecurityQA]);
        if(queryParamFlagValue) {
            sessionStorage.setItem(this.hasSkippedSecurityQA, "true");
            return true;
        }
        return false;
    }

    private shouldShowSecurityQuestions(model: HasSecurityQuestionAnswerModel,
        hasSkippedSecurityQA: boolean): boolean {

        if (!model.hasQuestionAnswers && (
            (model.skipDaysLeft == 0) ||
            ((model.skipDaysLeft > 0) && !hasSkippedSecurityQA)
        )) {
            return true;
        }

        return false;
    }
}

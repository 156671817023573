import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import GroupMember from '../common/models/groupMember';

@Component({
  selector: 'app-group-member-about',
  templateUrl: './group-member-about.component.html',
  styleUrls: ['./group-member-about.component.css']
})
export class GroupMemberAboutComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<GroupMemberAboutComponent>,
              @Inject(MAT_DIALOG_DATA) public member: GroupMember) {
  }

  ngOnInit() {
  }

  closeMe(): void {
    this.dialogRef.close();
  }


}

import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { UserLogin } from "../models/userLogin.model";
import { Observable, from } from "rxjs";
import { AuthResponse } from "../models/authResponse.model";
import { Country } from "../models/country.model";
import { Gender } from "../models/gender.model";
import { UserRegistration } from "../models/userRegistration.model";
import { UserProfile } from "../models/userProfile.model";
import { NamedId } from "../models/namedId.model";
import { findAUser } from '../../shared/models/findAUser.model';
import { FindAUserFilter } from '../../user-management/find-a-user/findauserFilter.model';
import { Paged } from "src/app/shared/models/paged.model";
import { ConfigService } from "./config.service";
import { User } from "../models/user.model";
import { HasSecurityQuestionAnswerModel } from "../models/has-security-question-answer.model";
import { MfaConfigurationModel } from "../models/mfa-configuration.model";
import { trimEnd } from "lodash";

@Injectable()
export class AccountService {
    private _accountEndpoint;
    private _userEndpoint;
    private _countryEndPoint;
    private _lessonsEndpoint;
    private _personApiEndpoint;
    private _httpNoToken: HttpClient;
    private _bsfoApiEndpoint = '';

    constructor(
        private _http: HttpClient,
        private configService: ConfigService,
        private handler:HttpBackend
        //public cookieService: BsfCookieService
    ) {
      configService.configuration$.subscribe(config => {
        this._bsfoApiEndpoint = config.apiEndpoint;
        this._accountEndpoint = `${config.apiEndpoint}account`;
        this._userEndpoint = `${config.apiEndpoint}users`;
        this._countryEndPoint = `${config.apiEndpoint}countries`;
        this._lessonsEndpoint = `${config.apiEndpoint}lessons`;
        this._personApiEndpoint = `${trimEnd(config.apiEndpoint, '/')}/account/api/person`;
      });
      this._httpNoToken = new HttpClient(handler);
    }

    public login(user: UserLogin): Observable<AuthResponse> {
        return this._http.post<AuthResponse>(`${this._accountEndpoint}/sign-in`, user);
    }

    getUser(): Observable<User> {
      return this._http.get<User>(`${this._accountEndpoint}/me`);
    }    

    public getCountries(): Observable<Country[]> {
        return this._http.get<Country[]>(this._countryEndPoint)
    }

    public getGenders(isPublicSearch?:boolean): Observable<Gender[]> {
      if(isPublicSearch){
        return this._httpNoToken.get<Gender[]>(`${this._userEndpoint}/genders`);
      }
        return this._http.get<Gender[]>(`${this._userEndpoint}/genders`);
    }
    
    public isFeatureStarted(featureName: string): Observable<boolean> {
        return this._http.get<boolean>(`${this._bsfoApiEndpoint}appsettings/is-feature-started/${featureName}`);
     }

    public getPositions(): Observable<NamedId<number>[]> {
        return this._http.get<NamedId<number>[]>(`${this._userEndpoint}/positions`);
    }

    public register(user: UserRegistration): Observable<User> {
        var form = new FormData();
        var keys = Object.keys(user);

        keys.forEach(key => {
            if(user[key] !== null && user[key] !== undefined) {
              form.append(key, user[key])
            }
            
        });

        return this._http.post<User>(`${this._accountEndpoint}/register`, form);
    }

    public updateExistingPersonOnWelcome(updateInfo: UserRegistration): Observable<User> 
    {
        return this._http.post<User>(`${this._accountEndpoint}/update-on-welcome`, {...updateInfo});
    }

    public sendResetPasswordRequest(email: string) {
        return this._http.post(`${this._accountEndpoint}/_requestpasswordchange`, { email })
    }

    public changePassword(email, password, accessKey) {
        return this._http.put(`${this._accountEndpoint}/_changepassword`, { email, password, accessKey })
    }

    public hasSecurityQuestionAnswers(): Observable<HasSecurityQuestionAnswerModel> {
        return this._http.get<HasSecurityQuestionAnswerModel>(`${this._accountEndpoint}/me/security-question/answers/exists`);
    }

    public isMfaRequired(): Observable<MfaConfigurationModel> {
        return this._http.get<MfaConfigurationModel>(`${this._accountEndpoint}/me/mfa/is-required`);
    }    

    public getUserProfile(): Observable<UserProfile> {
        return this._http.get<UserProfile>(`${this._userEndpoint}/profile`)
    }

    public getUserProfileById(id: number): Observable<UserProfile> {
        return this._http.get<UserProfile>(`${this._userEndpoint}/${id}/profile`)
    }

    public updateUserProfile(profile: UserProfile, photo: File) {
        var form = new FormData();
        profile.photo = null;
        var keys = Object.keys(profile);

        keys.forEach(key => {
            if (profile[key] instanceof Date) {
                form.append(key, profile[key].toISOString())
                return
            }
            form.append(key, profile[key])
        });

        form.append("profilePhoto", photo)

        return this._http.put(`${this._userEndpoint}/profile`, form);
    }

    // public checkUserProfile() {
    //     return this._http.get(`${this._userEndpoint}/profile_check`);
    // }

    public getMaritalStatus(): Observable<NamedId<number>[]> {
        return this._http.get<NamedId<number>[]>(`${this._userEndpoint}/marital_status`)
    }

    public getDenominations(): Observable<NamedId<number>[]> {
        return this._http.get<NamedId<number>[]>(`${this._userEndpoint}/church_denominations`);
    }

    public getBibleKnowleges(): Observable<NamedId<number>[]> {
        return this._http.get<NamedId<number>[]>(`${this._userEndpoint}/bible_knowlegde_levels`);
    }

    public getStudies(): Observable<NamedId<number>[]> {
        return this._http.get<NamedId<number>[]>(`${this._lessonsEndpoint}/studies`);
    }

    public validateCaptcha(token) {
        return this._http.post(`${this._accountEndpoint}/_validatecaptcha`, { token })
    }

    public findAUsers(gridFilter: FindAUserFilter): Observable<Paged<findAUser[]>> {
        return this._http.post<Paged<findAUser[]>>(`${this._userEndpoint}/find`, gridFilter);
    }

    public isInGroup(): Observable<boolean> {
        return this._http.get<boolean>(`${this._userEndpoint}/in_group`);
    }

    public getAllLeavingReasons(): Observable<NamedId<number>[]> {
        return this._http.get<NamedId<number>[]>(`${this._userEndpoint}/leave_reasons`);
    }

    public getPersonInfo(): Observable<User> {
        return this._http.get<User>(`${this._accountEndpoint}/me`);
    }

    public checkOfHiddenEmailPhone(): Observable<boolean> {
        return this._http.get<boolean>(`${this._accountEndpoint}/me/check-hidden-email-phone`);
    }

    public setHiddenEmailPhoneNotification(): Observable<any> {
        return this._http.post<User>(`${this._accountEndpoint}/me/check-hidden-email-phone`, null);
    }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };      

    onLogin(showMFA: boolean): Observable<any> {
        return new Observable<any>(subscriber => {
            var request = { showMFA: showMFA };
            let body = JSON.stringify(request);
            let url = `${this._accountEndpoint}/on-login`;
            this._http.post(url, body, this.httpOptions).subscribe(result => {
                subscriber.next(result);
                subscriber.complete();
            });
        });
    }      
}

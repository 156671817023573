import { PipeTransform, Pipe } from "@angular/core";
import { TimeHelper } from "../helpers/timeHelper";
import { Injectable } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
    name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

    transform(date: Date, timezone: string, format: string) {

        if (!date || !format) {
            return
        }

        let md = moment(date);

        return (timezone ? md.tz(timezone) : md ).format(format);
    }
}

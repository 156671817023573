import { Component, OnInit, OnDestroy } from '@angular/core';
import { FooterService } from 'src/app/shared/services/footer.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-comingsoon',
  templateUrl: './comingsoon.component.html',
  styleUrls: ['./comingsoon.component.css']
})
export class ComingsoonComponent implements OnInit, OnDestroy {

  constructor(
    private _footerService: FooterService,
    private _titleService: Title
  ) { }

  ngOnInit() {
    this._titleService.setTitle("Bible Study Fellowship Online | Coming Soon");
    this._footerService.hideDefault();
  }

  ngOnDestroy() {
    this._footerService.displayDefault();
  }

}

<div class="wrapper">
  <div *ngIf="!isAuthenticated()" >
    <div class="row index-header d-flex">
      <div class="col-12 col-md-6">
        <h1 class="mx-5">{{ 'GrowYourFaith' | translate }}</h1>
      </div>
    </div>
    <div class="row" style="margin:60px 30px">
      <div class="col-12 col-md-6 d-flex flex-column justify-content-around pb-4 pb-md-0 deeper-way-to-study-container">
        <h2><b>{{ 'DeeperWayToStudy' | translate }}</b></h2>
        <p>{{ 'FreeStudyWithTrainedGroup' | translate }}</p>
      </div>
  
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12 col-lg-6 row justify-content-between">
            <div class="col-3 row justify-content-center">
              <img src="../../../assets/images/banners/four-fold-approach-1.png" class="four-fold-img">
            </div>
            <div class="col-9">
              <b>{{ 'AnswerQuestion' | translate }}</b>
              <p>{{ 'AnswerQuestionDesc' | translate }}</p>
            </div>
          </div>
  
          <div class="col-12 col-lg-6 row justify-content-between">
            <div class="col-3 row justify-content-center">
              <img src="../../../assets/images/banners/four-fold-approach-2.png" class="four-fold-img">
            </div>
            <div class="col-9">
              <b>{{ 'DiscussTogether' | translate }}</b>
              <p>{{ 'DiscussTogetherDesc' | translate }}</p>
            </div>
          </div>
        </div>
  
        <div class="row">
          <div class="col-12 col-lg-6 row justify-content-between">
            <div class="col-3 row justify-content-center">
              <img src="../../../assets/images/banners/four-fold-approach-3.png" class="four-fold-img">
            </div>
            <div class="col-9">
              <b>{{ 'ListenToTeaching' | translate }}</b>
              <p>{{ 'ListenToTeachingDesc' | translate }}</p>
            </div>
          </div>
          <div class="col-12 col-lg-6 row justify-content-between">
            <div class="col-3 row justify-content-center">
              <img src="../../../assets/images/banners/four-fold-approach-4.png" class="four-fold-img">
            </div>
            <div class="col-9">
              <b>{{ 'ExploreCommunityNotes' | translate }}</b>
              <p>{{ 'ExploreCommunityNotesDesc' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- uncomment when these sections should be visible-->
    <div class="row px-5 study-container">
      <div class="col-12 col-md-7 col-lg-5 col-xl-4 row align-items-center">
        <img src="../../../assets/images/banners/revelation-image.png" class="img-fluid">
      </div>
      <div class="col-12 col-md-5 col-lg-7 col-xl-8 p-5 d-flex flex-column justify-content-center study-desc-container">
        <h3 class="mb-5">{{ 'Revelation1' | translate }}</h3>
        <p style="font-size: 1.5rem;">{{ 'Revelation2' | translate }}</p>
      </div>
    </div>

    <div class="row d-flex justify-content-center py-4">
      <button (click)="findGroup()" class="blue-btn btn btn-lg" [routerLink]="['/mygroup/group']" routerLinkActive="active"
        id="findGroup">{{'FindOnlineGroup' | translate}}</button>
    </div>
  </div>
</div>

import { B2cConfiguration } from "./B2cConfiguration.model";

export class Configuration {
 
  apiEndpoint:string;
  accountsModuleBaseUrl:string;
  pollsModuleBaseUrl:string;
  resourceLibraryModuleBaseUrl:string;
  mybsfBaseUrl:string;
  enableAccountModule:boolean;
  enableMybsf:boolean;
  additionalResourceUrl:string;
  maintenancePageContentUrl: string;
  enableResourceLibrary:boolean;
  showConsoleLog:boolean;
  enableSecurityQuestions:boolean;
  enableMfa:boolean;
  isMfaAuthenticatorEnabled : boolean;
  b2cConfiguration: B2cConfiguration;  
  isTest: boolean;
  prodRedirectUrl: string;
  testEnvironmentPassword: string;
  resourceUploaderApiEndpoint: string;
  links: {
    international: string
  };

  constructor(config: Configuration) {
    this.apiEndpoint = config.apiEndpoint;
    this.accountsModuleBaseUrl = config.accountsModuleBaseUrl;
    this.pollsModuleBaseUrl = config.pollsModuleBaseUrl;
    this.resourceLibraryModuleBaseUrl = config.resourceLibraryModuleBaseUrl;
    this.mybsfBaseUrl = config.mybsfBaseUrl;
    this.enableAccountModule = config.enableAccountModule;
    this.enableMybsf = config.enableMybsf;
    this.additionalResourceUrl = config.additionalResourceUrl;
    this.maintenancePageContentUrl = config.maintenancePageContentUrl;
    this.enableResourceLibrary = config.enableResourceLibrary;
    this.showConsoleLog = config.showConsoleLog;
    this.enableSecurityQuestions = config.enableSecurityQuestions;
    this.enableMfa = config.enableMfa;
    this.isMfaAuthenticatorEnabled = config.isMfaAuthenticatorEnabled ?? false;
    this.b2cConfiguration = config.b2cConfiguration;
    this.isTest = config.isTest;
    this.testEnvironmentPassword = config.testEnvironmentPassword;
    this.prodRedirectUrl = config.prodRedirectUrl;
    this.resourceUploaderApiEndpoint = config.resourceUploaderApiEndpoint;
    this.links = config.links;
  }
  
  public get securityQuestionsUrl() : string {
    return `${this.getShellRootUrl(this.accountsModuleBaseUrl)}welcome/security-questions`
  }

  public get mfaOptionsUrl() : string {
    return `${this.getShellRootUrl(this.accountsModuleBaseUrl)}welcome/mfa-options`
  }  

  public get mfaPhoneUrl() : string {
    return `${this.getShellRootUrl(this.accountsModuleBaseUrl)}welcome/mfa-phone`
  }  

  private getShellRootUrl(url: string) {
    return url.replace(/accounts\//, '')
  }

  
}



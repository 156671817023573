
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { GroupLeader, Admin } from '../models/Position.constant';
import { RoutingService } from '../services/routing.service';
import { RolePermissionService } from '../services/rolePermission.service';

@Injectable()
export class AreaGuardService  {

    constructor(private _sessionService: SessionService,
        private _rolePermissions: RolePermissionService,
        private _routingService: RoutingService,
        private authService:AuthService) {

    }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
      const isAuthenticated = this._sessionService.isAuthenticated();
      if (!isAuthenticated) {
        this.authService.b2cLogin();
      }

      if (!(this._rolePermissions.isAreaPersonnelOrAbove())) {
          this._routingService.gotoHome()
      }

      return isAuthenticated && this._rolePermissions.isAreaPersonnelOrAbove();
    }
}

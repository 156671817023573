import { CreateAccountGuardService } from './authGuards/createAccountGuard.service';
import { ZoomService } from 'src/app/shared/services/zoom.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountService } from './services/account.service';
import { SessionService } from './services/session.service';
import { DataTranslationService } from './services/dataTranslation.service';
import { AuthService } from './services/auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { FooterService } from './services/footer.service';
import { DefaultFooterComponent } from './widgets/default-footer/default-footer.component';
import { RouterModule } from '@angular/router';
import { AltFooterComponent } from './widgets/alt-footer/alt-footer.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './helpers/token.interceptor';
import { ApiVersionInterceptor } from './helpers/apiVersion.interceptor';
import { LocalTimePipe } from './pipes/localTime.pipe';
import { CountryFlagPipe } from './pipes/countryFlag.pipe';
import { AuthGuardService } from './authGuards/authGuard.service';
import { MustMatchDirective } from './directives/mustMatch.directive';
import { DayPickerComponent } from './widgets/day-picker/day-picker.component';
import { TimePickerComponent } from './widgets/time-picker/time-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GroupGuardService } from './authGuards/groupGuard.service';
import { InputBoxComponent } from './widgets/input-box/input-box.component';
import { ValidationComponent } from './widgets/input-box/validation.component';
import { InputSelectComponent } from './widgets/input-box/select.component';
import { LanguageService } from './services/language.service';
import { LocalClockComponent } from './widgets/local-clock/local-clock.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { MessageDispatchService } from './services/messageDispatch.service';
import { AppSettingsService } from './services/appSettings.service';
import { ImagePlaceholderComponent } from './widgets/image-placeholder/image-placeholder.component';
import { UserImageComponent } from './widgets/user-image/user-image.component';
import { EqualValidator } from './directives/equalValidator.directive';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DialogBoxComponent } from './widgets/dialog-box/dialog-box.component';
import { CultureService } from './services/culture.service';
import { CountryFlagComponent } from './widgets/country-flag/country-flag.component';
import { MessagingService } from './services/messaging-service.service';
import { PhoneMaskDirective } from './directives/phoneMask.directive';
import { DigitOnlyDirective } from './directives/digitOnly.direcive';
import { RoutingService } from './services/routing.service';
import { DatePickerComponent } from './widgets/date-picker/date-picker.component';
import { LessonService } from './services/lesson.service';
import { AlertMessageService } from './services/AlertMessage.service';
import { FileUploadComponent } from './widgets/file-upload/file-upload.component';
import { FilesService } from './services/files.service';
import { GroupMemberItemComponent } from './widgets/group-member-item/group-member-item.component';
import { FormatDatePipe } from './pipes/formatDate.pipe';
import { FormatLongDatePipe } from './pipes/formatLongDate.pipe';
import { UserService } from './services/users.service';
import { PagingComponent } from './widgets/paging/paging.component';
import { BreadcrumbComponent } from '../layout/breadcrumb/breadcrumb.component';
import { RegionService } from 'src/app/region/common/services/region.service';
import { FocusInvalidInputDirective } from './directives/focusInvalidInput.directive';
import { SpinnerComponent } from './widgets/spinner/spinner.component';
import { SpinnerService } from './widgets/spinner/spinner.service';
import { TrainingService } from '../trainings/common/services/training.service';
import { AudioBoxComponent } from './widgets/audio-box/audio-box.component';
import { VideoBoxComponent } from './widgets/video-box/video-box.component';
import { WeekDayPipe } from './pipes/weekDay.pipe';
import { BoolToTextPipe } from './pipes/boolToText.pipe';
import { UserPositionPipe } from './pipes/userPosition.pipe';
import { ArraySortPipe } from './pipes/orderBy.pipe';
import { SystemMaintenanceService } from './services/systemMaintenance.service';
import { TimezonePickerComponent } from './widgets/timezone-picker/timezone-picker.component';
import { HelpService } from './services/help.service';
import { IdleTimeoutService } from './services/idleTimeout.service';
import { TimePickerModule, DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { SpecialCharacterDirective } from './directives/specialCharacter.directive';
import { AdminGuardService } from './authGuards/adminGuard.service';
import { RolePermissionService } from './services/rolePermission.service';
import { TrainingGuardService } from './authGuards/trainingGuard.service';
import { RegionGuardService } from './authGuards/regionGuard.service';
import { AreaGuardService } from './authGuards/areaGuard.service';
import { RecommendationGuardService } from './authGuards/recommendationGuard.service';
import { UserManagementGuardService } from './authGuards/userManagementGuard.service';
import { TrainingGuard2Service } from './authGuards/trainingGuard2.service';
import { PhotoUploadComponent } from './widgets/photo-upload/photo-upload.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { LeadershipApplicationGuardService } from './authGuards/leadershipApplicationGuard.service';
import { OLMGuardService } from './authGuards/OLMGuard.service';
import { GteValidatorDirective } from './directives/gteValidator.diective';
import { MsalConfigurationService } from './services/msalConfiguration.service';
import { CountryLookupService } from './services/country-lookup.service';
import { BsfStoreComponent } from './widgets/bsf-store/bsf-store.component';
import { EventsScheduleGuardService } from './authGuards/eventsScheduleGuard.service';
import { StudyScheduleGuardService } from './authGuards/studyScheduleGuard.service';
import { MaintenancePageService } from './services/maintenancePage.service';
import { TranslateLessonService } from './services/translateLesson.service';
import { LoadingInterceptor } from './helpers/loading-interceptor';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { CopyLinkModalComponent } from './widgets/copy-link-modal/copy-link-modal.component';
import { TrimLeadingZeroDirective } from './directives/trimLeadingZero.directive';
import { WelcomeAreaGuardService } from './authGuards/welcomeAreaGuard.service';
import { LoadingPageComponent } from '../loading-page/loading-page.component';
import { ItemListEditorComponent } from './widgets/item-list-editor/item-list-editor.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {ConfirmDialogComponent} from "./widgets/confirm-dialog/confirm-dialog.component";
import { MatDialogModule} from "@angular/material/dialog";
import { BsfTrimInputDirective } from './directives/bsf-trim-input.directive';
import { IntlModule } from "@progress/kendo-angular-intl";

import "@progress/kendo-angular-intl/locales/zh-Hans/all";
import "@progress/kendo-angular-intl/locales/zh-Hant/all";
import "@progress/kendo-angular-intl/locales/es/all";
import { DaterangepickerComponent } from './widgets/ng2-daterangepicker/ng2-daterangepicker.component';
import { HidePhoneEmailDialogComponent } from './widgets/hide-phone-email-dialog/hide-phone-email-dialog.component';

@NgModule({
  declarations: [DefaultFooterComponent,
    AltFooterComponent,
    LocalTimePipe,
    CountryFlagPipe,
    MustMatchDirective,
    DayPickerComponent,
    TimePickerComponent, InputBoxComponent,
    ValidationComponent,
    InputSelectComponent,
    LocalClockComponent,
    ImagePlaceholderComponent,
    UserImageComponent,
    EqualValidator,
    DialogBoxComponent,
    CountryFlagComponent,
    PhoneMaskDirective,
    DigitOnlyDirective,
    GteValidatorDirective,
    DatePickerComponent,
    FileUploadComponent,
    GroupMemberItemComponent,
    BreadcrumbComponent,
    FormatDatePipe,
    FormatLongDatePipe,
    PagingComponent,
    FocusInvalidInputDirective,
    SpinnerComponent,
    AudioBoxComponent,
    VideoBoxComponent,
    WeekDayPipe,
    BoolToTextPipe,
    UserPositionPipe,
    ArraySortPipe,
    TimezonePickerComponent,
    SpecialCharacterDirective,
    PhotoUploadComponent,
    BsfStoreComponent,
    CopyLinkModalComponent,
    TrimLeadingZeroDirective,
    LoadingPageComponent,
    ItemListEditorComponent,
    ConfirmDialogComponent,
    BsfTrimInputDirective,
    DaterangepickerComponent,
    HidePhoneEmailDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    ModalModule.forRoot(),

    TimePickerModule,
    AlertModule.forRoot(),
    ClipboardModule,
    DropDownsModule,
    MatSnackBarModule,
    MatDialogModule,
    ReactiveFormsModule
  ],
  exports: [
    DefaultFooterComponent,
    AltFooterComponent,
    LocalTimePipe,
    CountryFlagPipe,
    MustMatchDirective,
    TimePickerComponent,
    DayPickerComponent,
    InputBoxComponent,
    InputSelectComponent,
    GridModule,
    DropDownsModule,
    InputSelectComponent,
    LocalClockComponent,
    ImagePlaceholderComponent,
    UserImageComponent,
    EqualValidator,
    DialogBoxComponent,
    CountryFlagComponent,
    PhoneMaskDirective,
    DigitOnlyDirective,
    GteValidatorDirective,
    DatePickerComponent,
    FileUploadComponent,
    GroupMemberItemComponent,
    FormatDatePipe,
    FormatLongDatePipe,
    PagingComponent,
    BreadcrumbComponent,
    FocusInvalidInputDirective,
    SpinnerComponent,
    AudioBoxComponent,
    VideoBoxComponent,
    WeekDayPipe,
    BoolToTextPipe,
    UserPositionPipe,
    ArraySortPipe,
    TimezonePickerComponent,
    TimePickerModule,
    TimePickerComponent,
    DateInputsModule,
    SpecialCharacterDirective,
    PhotoUploadComponent,
    BsfStoreComponent,
    ClipboardModule,
    TrimLeadingZeroDirective,
    ItemListEditorComponent,
    MatSnackBarModule,
    ReactiveFormsModule,
    BsfTrimInputDirective,
    IntlModule,
    DaterangepickerComponent
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        //{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ApiVersionInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true  },
        AccountService,
        AuthService,
        SessionService,
        FooterService,
        DataTranslationService,
        AuthGuardService,
        GroupGuardService,
        AdminGuardService,
        RegionGuardService,
        AreaGuardService,
        TrainingGuardService,
        RecommendationGuardService,
        OLMGuardService,
        TrainingGuard2Service,
        UserManagementGuardService,
        LeadershipApplicationGuardService,
        LanguageService,
        MessageDispatchService,
        AppSettingsService,
        CultureService,
        MessagingService,
        RoutingService,
        LessonService,
        AlertMessageService,
        FilesService,
        UserService,
        RegionService,
        SpinnerService,
        TrainingService,
        SystemMaintenanceService,
        IdleTimeoutService,
        HelpService,
        ZoomService,
        RolePermissionService,
        MsalConfigurationService,
        CountryLookupService,
        CreateAccountGuardService,
        EventsScheduleGuardService,
        StudyScheduleGuardService,
        MaintenancePageService,
        TranslateLessonService,
        WelcomeAreaGuardService
      ],
    };
  }

}

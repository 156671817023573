import { PipeTransform, Pipe } from "@angular/core";
import { TimeHelper } from "../helpers/timeHelper";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'localtime'
})
export class LocalTimePipe implements PipeTransform {

  transform(timezone: string, date = new Date()) {
    if (!timezone) {
      return
    }

    return TimeHelper.getLocalTime(timezone, date);
  }
}

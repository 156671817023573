import { AuthService } from 'src/app/shared/services/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionService } from 'src/app/shared/services/session.service';
import { FooterService } from 'src/app/shared/services/footer.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  constructor(
    private _sessionService: SessionService,
    private _footerService: FooterService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private authService:AuthService
  ) {
  }

  ngOnDestroy(): void {
    this._footerService.hideDefault();
  }

  isAuthenticated() {
    return this._sessionService.isAuthenticated();
  }

  userFullname() {
    const user = this._sessionService.getUserInfo();
    return `${user.firstName} ${user.lastName}`;
  }

  ngOnInit() {
    if(this.isAuthenticated()){
      console.log("home redirect");
      this.authService.redirectUserToDefaulPage();
    }
    this._footerService.displayDefault();
  }

  findGroup() {
    this.googleAnalyticsService.eventEmitter("find_a_group_btn_home", "button", "click", "find_a_group_btn_home");
  }

  createAccount() {
    this.googleAnalyticsService.eventEmitter("create_account_btn_home", "button", "click", "create_account_btn_home");
  }

  login() {
    this.googleAnalyticsService.eventEmitter("login_btn_home", "button", "click", "login_btn_home");
  }

  b2clogin(){
    this.googleAnalyticsService.eventEmitter("login_btn_home", "button", "click", "login_btn_home");
    this.authService.b2cLogin();
  }

}

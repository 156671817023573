import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatDialogModule } from "@angular/material/dialog";
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppComponent } from './layout/app.component';
import { NavMenuComponent } from './layout/nav-menu/nav-menu.component';
import { HomeComponent } from './layout/home/home.component';
import { SharedModule } from './shared/shared.module';
import { GroupMemberAboutComponent } from './online-group/group-member/group-member-about.component';
import { MeetingSwitchDialogComponent } from './online-group/find-a-group/meeting.switch.dialog.component';
import { RegroupUserDialogComponent } from './online-group/common/widgets/regroup-user-dialog/regroup.user.dialog.component';
import { DiscussionMeetingService } from './online-group/common/services/discussion-meeting.service';
import { AppRoutingModule } from './app-routing.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FooterComponent } from './layout/footer/footer.component';
import { ComingsoonComponent } from './layout/comingsoon/comingsoon.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { RemoveMemberComponent } from './online-group/common/widgets/remove-member/remove-member.component';
import { InviteFriendComponent } from './online-group/common/widgets/invite-friend/invite-friend.component';
import { AddUserToGroupComponent } from './user-management/common/widgets/add-user-to-group/add-user-to-group.component';
import { ConfirmRecommendationComponent } from './shared/widgets/confirm-recommendation/confirm-recommendation.component';
import { RequestLeadersMeetingModalComponent } from './shared/widgets/request-leaders-meeting-modal/request-leaders-meeting-modal.component';
import { LeadersApplicationWithdrawalModalComponent } from './shared/widgets/leaders-application-withdrawal-modal/leaders-application-withdrawal-modal.component';
import { DeclineCandidateLeadershipApplicationModalComponent } from './shared/widgets/decline-candidate-leadership-application-modal/decline-candidate-leadership-application-modal.component';
import { ApproveCandidateLeadershipApplicationModalComponent } from './shared/widgets/approve-candidate-leadership-application-modal/approve-candidate-leadership-application-modal.component';
import { InterviewCommentReminderModalComponent } from './shared/widgets/interview-comment-reminder-modal/interview-comment-reminder-modal.component';
import { OLMSwitchNotificationModalComponent } from './shared/widgets/olmswitch-notification-modal/olmswitch-notification-modal.component';
import { SystemAnnouncementComponent } from './layout/system-announcement/system-announcement.component';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { LeaderlessGroupNotificationComponent } from './shared/widgets/leaderless-group-notification/leaderless-group-notification.component';
import { ConfigService } from './shared/services/config.service';
import { MsalConfigurationService } from './shared/services/msalConfiguration.service';
import { registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es";
import localeZh from "@angular/common/locales/zh";
import { HttpErrorInterceptor } from './shared/helpers/httpError.interceptor';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import { IPublicClientApplication } from '@azure/msal-browser';
import { NotificationLinkComponent } from './layout/notification-link/notification-link.component';
import { NotificationLinksComponent } from './layout/notification-links/notification-links.component';
import { NotificationsComponent } from './layout/notifications/notifications.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBell as farBell } from '@fortawesome/free-regular-svg-icons';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIdleModule } from '@ng-idle/core';
import { MatMenuModule } from '@angular/material/menu'
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DaterangepickerConfig } from './shared/widgets/ng2-daterangepicker/ng2-daterangepicker.service';
import { TestEnvironmentComponent } from './test-environment/test-environment.component';
import enTranslations from '../assets/i18n/en.json';
import esTranslations from '../assets/i18n/sp.json';
import zhHansTranslations from '../assets/i18n/sch.json';
import zhHantTranslations from '../assets/i18n/tch.json';
import { Observable, of } from 'rxjs';


registerLocaleData(localeEs);
registerLocaleData(localeZh);

export interface TranslationMap {
  en: any;
  sp?: any;
  sch?: any;
  tch?: any;

  [code: string]: any;
}

export class StaticTranslationLoader implements TranslateLoader {
  constructor(private translationsMap: TranslationMap) {
  }

  getTranslation(langIsoCode: string): Observable<any> {
    return of(this.translationsMap[langIsoCode]);
  }
}


const staticTranslationLoader = new StaticTranslationLoader({
  en: enTranslations,
  sp: esTranslations,
  sch: zhHansTranslations,
  tch: zhHantTranslations
});

const TranslateModuleForRoot = TranslateModule.forRoot({
  loader: {provide: TranslateLoader, useValue: staticTranslationLoader},
  isolate: true
});

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(msalConfigService: MsalConfigurationService): IPublicClientApplication {
  return msalConfigService.loadMsalClientApplication();
}

export function MSALInterceptorConfigFactory(msalConfigService: MsalConfigurationService): MsalInterceptorConfiguration {
  return msalConfigService.loadMSALInterceptorConfig();
}

export function MSALGuardConfigFactory(msalConfigService: MsalConfigurationService): MsalGuardConfiguration {
  return msalConfigService.loadMsalConfig();
}

@NgModule({ declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        FooterComponent,
        ComingsoonComponent,
        GroupMemberAboutComponent,
        MeetingSwitchDialogComponent,
        RemoveMemberComponent,
        RegroupUserDialogComponent,
        InviteFriendComponent,
        AddUserToGroupComponent,
        ConfirmRecommendationComponent,
        RequestLeadersMeetingModalComponent,
        LeadersApplicationWithdrawalModalComponent,
        ApproveCandidateLeadershipApplicationModalComponent,
        DeclineCandidateLeadershipApplicationModalComponent,
        InterviewCommentReminderModalComponent,
        OLMSwitchNotificationModalComponent,
        SystemAnnouncementComponent,
        LeaderlessGroupNotificationComponent,
        NotificationsComponent,
        NotificationLinkComponent,
        NotificationLinksComponent,
        TestEnvironmentComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        FormsModule,
        SharedModule.forRoot(),
        AppRoutingModule,
        MatDialogModule,
        TranslateModuleForRoot,
        BrowserAnimationsModule,
        GridModule,
        DropDownsModule,
        MsalModule,
        MatBadgeModule,
        MatIconModule,
        MatProgressSpinnerModule,
        FontAwesomeModule,
        NgIdleModule.forRoot(),
        MatMenuModule,
        ClipboardModule], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: ConfigFactory,
            deps: [ConfigService],
            multi: true
        },
        GoogleAnalyticsService,
        DiscussionMeetingService,
        { provide: DaterangepickerConfig, useValue: { settings: {} } },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
            deps: [MsalConfigurationService]
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
            deps: [MsalConfigurationService]
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
            deps: [MsalConfigurationService]
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      farBell
    );
  }
}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function ConfigFactory(config: ConfigService) {
  return () => config.load((new Date()).getTime().toString());
}

import { Component, Input, ElementRef, ViewChild, OnInit, EventEmitter, Output } from '@angular/core';
import { LessonMaterial } from 'src/app/lessons/common/models/lessonMaterial.model';
import { IdleTimeoutService } from '../../services/idleTimeout.service';
@Component({
  selector: 'app-audio-box',
  templateUrl: './audio-box.component.html'
})

export class AudioBoxComponent implements OnInit{
  @ViewChild("audiosection")
  public audiosecion: ElementRef;

  @Input() public audioLink: string;
  @Input() public type: string;
  @Input() public audio: LessonMaterial;
  @Output("loadLessonMaterialFile") loadLessonMaterialFile: EventEmitter<any> = new EventEmitter();

  constructor(
    private sessionTimeoutService: IdleTimeoutService){

  }
  ngOnInit() {

  }

  audioStarted() {
      this.sessionTimeoutService.stop();
  }

  audioEnded(): void {
    this.sessionTimeoutService.start();
  }

  audioPaused(): void {
    this.sessionTimeoutService.start();
  }

  isiOS(){
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  loadFile(lessonMaterial:LessonMaterial){
    this.loadLessonMaterialFile.emit(lessonMaterial);
  }

}

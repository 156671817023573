import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { DisplayLanguage } from "../models/enums/displayLanguage.enum";
import { HelpService } from "src/app/shared/services/help.service";

import moment from 'moment';
import { MaintenancePageService } from "./maintenancePage.service";
@Injectable()
export class CultureService {

    public languages = [
        {
          id: 'en',
          code: 'en',
          title: 'English',
          flag: 'us',
        },
        {
          id: 'sch',
          code: 'zh-Hans',
          title: '简体中文',
          flag: 'cn',
        },
        {
          id: 'tch',
          code: 'zh-Hant',
          title: '繁體中文',
          flag: 'tc',
        },
        {
          id: 'sp',
          code: 'es',
          title: 'Español',
          flag: 'sp',
        },
      ];

    constructor(
        private _translateService: TranslateService,
        private _helpService: HelpService,
        private _maintenancePageService: MaintenancePageService) {
    }

    getCurrent() {
        return localStorage.getItem('locale');
    }

    setMomentjsLocale(culture: string) {

        let language =  this.languages.find(x=>x.id == culture);
        language = language || this.languages[0];
        let languageCode = language.code;

        if(language.id === 'sch' ) {
            languageCode = 'zh-cn';
        }
        else if (language.id === 'tch') {
            languageCode = 'zh-hk';
        }


        moment.locale(languageCode);
    }

    setCurrent(culture: string, loadHelpWidget:boolean) {
      console.log("current culture:",culture);
      localStorage.setItem('locale', culture);
      this._translateService.use(culture);
      if(loadHelpWidget){
        this._helpService.load(culture);
      }
      this._maintenancePageService.storeLocalizedPageContent(culture);

      let language =  this.languages.find(x=>x.id == culture);
      language = language || this.languages[0];

      this.setMomentjsLocale(language.id);
    }

    getDisplayLanguageId(culture: string): number {
        switch (culture) {
            case 'en':
                return DisplayLanguage.English;
            case 'sp':
                return DisplayLanguage.Spanish;
            case 'sch':
                return DisplayLanguage.SimpleChinese;
            case 'tch':
                return DisplayLanguage.TradChinese;
            default:
                return DisplayLanguage.English;
        }
    }

    getCurrentLanguage() {
        const culture = this.getCurrent();
        let code =  this.languages.find(x=>x.id == culture)
        return code || this.languages[0];
    }
}

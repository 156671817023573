import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[trimLeadingZero]'
})
export class TrimLeadingZeroDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;

    this._el.nativeElement.value = initalValue.replace(/\b0+/g, '');
    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
